import {Injectable,OnInit} from '@angular/core';
import firebase from 'firebase/compat/app';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

// add_payment_info	ecommerce	 
// add_to_cart	ecommerce	 
// add_to_wishlist	ecommerce	 
// begin_checkout	ecommerce	 
// checkout_progress	ecommerce	 
// generate_lead	engagement	 
// login	engagement	method
// purchase	ecommerce	 
// refund	ecommerce	 
// remove_from_cart	ecommerce	 
// search	engagement	search_term
// select_content	engagement	content_type
// set_checkout_option	ecommerce	 
// share	engagement	method
// sign_up	engagement	method
// view_item	engagement	 
// view_item_list	engagement	 
// view_promotion	engagement	 
// view_search_results	engagement	search_term

export class AnalyticsService implements OnInit {

  constructor(public analytics:AngularFireAnalytics) {
  
  }

  ngOnInit(): void {
    if (firebase.apps.length === 0) {
      firebase.initializeApp(environment.firebaseAuth);
      this.analytics = this.analytics;
    }
  }

  isInit() {
    return firebase.apps.length > 0;
  }

  logEvent(event: string, params: any) {
    if (this.isInit()) {
      this.analytics.logEvent(event, params);
    } else {
      this.ngOnInit();
    }

  }

  setUserId(id) {
    if (this.isInit()) {
      this.analytics.setUserId(id);
    } else {
      this.ngOnInit();
    }
  }

  setUserProperty(property) {
    if (this.isInit()) {
      this.analytics.setUserProperties(property);
    } else {
      this.ngOnInit();
    }
  }

  setCurrentScreen(screenName) {
    if (this.isInit()) {
      this.analytics.setCurrentScreen(screenName);
    } else {
      this.ngOnInit();
    }
  }
}