import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthService, DatabaseService, FormService, InputBase, MaterializeService } from '@ollieestudio/fire-lib';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ConfirmService } from 'src/app/services/confirm.service';
import { WebsiteService } from 'src/app/services/di/website.service';
import { Status } from 'src/app/services/enum/status.enum';
import { Premiado } from 'src/app/services/interfaces/premiado';
import { Transaction } from 'src/app/services/interfaces/transaction';

@Component({
  selector: 'app-voucher-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class VoucherFormComponent implements OnInit {
  @Output() redeem:EventEmitter<Transaction> = new EventEmitter();

  formVoucher:FormGroup;
  maskVoucher:any =  [/[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, ' ', /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/,/[A-Za-z0-9]/, ' ', /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/,/[A-Za-z0-9]/, ' ',/[A-Za-z0-9]/,/[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/];;
  erros:string[] = [];
  private fields:InputBase[] = [
    {key:"valor", required:true},
    {key:"codigo", required:true},
    {key:"datavoucher", required:false},
  ]
  transaction: Transaction;
  
  constructor(public ws:WebsiteService, private material:MaterializeService, private formserv:FormService, public database:DatabaseService<Transaction>) {
    this.formVoucher = this.formserv.toFormGroup(this.fields)
   }

  ngOnInit() {
  }

  resetTransaction(){
    this.erros = [];
    this.formVoucher.reset()
  }

  processRecarga(){
    this.redeem.emit(this.transaction);
  }

  checkCodigo($event){
    let codigo = this.formVoucher.controls['codigo'].value.split(' ').join('');
    if(codigo.length === 16){
      this.database.get(codigo, 'TRANSACTIONS', 'default').subscribe(res =>{
        let transac = res.data() as unknown as Transaction;
        if(!transac){
          this.erros.push('Código inválido.')
        }else{
          if(transac.status === Status['sucesso']){
          this.erros.push('Voucher já utilizado.')
        }else if(transac.status === Status['cancelado']){
          this.erros.push('Voucher já utilizado.')
        }else{
          this.formVoucher.controls['valor'].setValue(transac.valorin);
          this.formVoucher.controls['datavoucher'].setValue(transac.datacriacao);
          this.material.delay(500);
          this.material.updateTextFields();
          this.transaction = transac;
        }
      }
      })
    }
  }
}
