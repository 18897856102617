import { Injectable } from '@angular/core';
import { Transaction } from '../../services/interfaces/transaction';
import { DatabaseService } from '@ollieestudio/fire-lib';
import { QueryFn } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class VoucherService {
 
 
  constructor(private database:DatabaseService<Transaction>){}


  getVouchersCliente(idcliente: any, status?:string): Transaction[] | PromiseLike<Transaction[]> {
    let query:QueryFn;
    if(status){
      query = ref => ref.where("tipo", "==", 'online_voucher').where("cliente", "==", idcliente).where("status", "==", status).orderBy('timestamp', 'desc');
    }else{
      query = ref => ref.where("tipo", "==", 'online_voucher').where("cliente", "==", idcliente).orderBy('timestamp', 'desc');
    }
    return new Promise<any>((resolve, reject) => {
      this.database.queryValues('TRANSACTIONS', query).subscribe(
        result => {
          resolve(result.map((voucher) => {
            return {...voucher, valorin:parseFloat(voucher.valorin), 
              premiado:voucher.privatedata.documento,
              owner:voucher.privatedata.owner,
              codigo: voucher.id.match(/.{1,4}/g).join(' ')
            }
          }));
        },
        error => {
          reject(error);
        }
      );
    });
  }

 public getVouchersPremiado(id: string): Transaction[] | PromiseLike<Transaction[]> {
    let query:QueryFn;
      query = ref => ref.where("tipo", "==", 'online_voucher').where("privatedata.owner", "==", id).orderBy('timestamp', 'desc');
    return new Promise<any>((resolve, reject) => {
      this.database.queryValues('TRANSACTIONS', query).subscribe(
        result => {
          resolve(result.map((voucher) => {
            return {...voucher, valorin:parseFloat(voucher.valorin), 
              premiado:voucher.privatedata.documento,
              owner:voucher.privatedata.owner,
              codigo: voucher.id.match(/.{1,4}/g).join(' ')
            }
          }));
        },
        error => {
          reject(error);
        }
      );
    });
  }


  updateVoucher(voucher: Transaction) {
    return new Promise<any>((resolve, reject) => {
      this.database.update(voucher, 'TRANSACTIONS').then(
        result => {
          resolve(result);
        },
        error => {
          reject(error);
        }
      )
      })
    }
}
