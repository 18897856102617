<div id="modalregulamento" class="modal webstore">
  <div class="modal-content row center-align modalregulamento">
    <h4 [style.font-family]="ws.website.font.family"  class="col s12  upper" [style.color]="ws.website?.color1"><i class="material-icons">gavel</i>&nbsp;REGULAMENTO </h4>
    <video *ngIf="videostart" id="lgpdvideo" class="lgpd center" src="https://firebasestorage.googleapis.com/v0/b/smartpontos-develop.appspot.com/o/lgpd_mov.mp4?alt=media&token=ae717543-cd9e-498f-b6b7-d44a7d6f29d2" poster="img/vframe.jpg"  controls muted autoplay></video>
    <div id="regulamentoContainer"></div>
  </div>

  <div class="modal-footer row">
    <a class="btn col s12 m8 top-5 right" *ngIf="!auth.User.hasRegulamento" [class.disabled]="!videofinished"  [style.backgroundColor]="ws.website?.color1" (click)="acceptRegulamento()">ACEITO OS TERMOS E REGULAMENTO </a>
    <a class="btn col s12 m5 top-5 right modal-close" *ngIf="auth.User.hasRegulamento"  [style.backgroundColor]="ws.website?.color1" >FECHAR </a>
  </div>
</div>