<div id="topmobile" class="toppad">
  &nbsp;
</div>
<div class="container top-5">
  <div class="row" *ngIf="service">
    
    <h3 [style.font-family]="ws.website.font.family"  class="col s12 m11 white-text upper"><i class="material-icons">{{service.icon}}</i>&nbsp;{{service.title}}
    </h3>
  </div>
  <div class="row">
    
    <div class="col s12 m11 instrucoes">
      <h5 [style.font-family]="ws.website.font.family"  class="white-text">INSTRUÇÕES</h5>
      <ul class="browser-default">
        <li><span class="white-text">As recargas serão efetivadas entre 15 minutos e 3 horas da solicitação. </span>
        </li>
        <li><span class="white-text">Em caso de erro sua operadora deverá ser consultada. </span></li>
        <li><span class="white-text">Será debitado de seus pontos o valor da recarga mais a taxa de serviço. </span></li>
        <li *ngIf="service"><span class="white-text bold">Taxa de Serviço: {{service.tax*ws.campanha.conversion}} {{ws.campanha.moeda}} </span></li>
      </ul>
    </div>
  </div>
  <app-mobile-favorites #favorites ></app-mobile-favorites>
  
  <div class="row">
    <a class="waves-effect waves-light btn col m4 s12 center" id="bt-new" [style.backgroundColor]="ws.website?.color1" (click)="enablePhoneForm()"
       *ngIf="showForm && !favorites.selectedPhone">nova recarga</a>
  </div>

  <div id="formNovaRecargaContainer" class="padding-5">
    <app-mobile-form id="phoneform" [service]="service" [hidden]="showForm"></app-mobile-form>
  </div>



</div>

<app-confirmpanel source="transaction" visible="showConfirmForm"></app-confirmpanel>