import { Injectable, OnInit, EventEmitter } from '@angular/core';
import { Website } from '../interfaces/website';
import { Campanha } from '../interfaces/campanha';
import { Service } from '../interfaces/service';
import { Card } from '../interfaces/card';
import { DatabaseService } from '@ollieestudio/fire-lib';
import { Enterprise } from '../interfaces/enterprise';
import * as WebFont from 'webfontloader';
import { QueryFn } from '@angular/fire/compat/firestore';
import { environment } from 'src/environments/environment';
import * as firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})
export class WebsiteService implements OnInit{
  
  public deferredPrompt:any;
  public websiteEmitter:EventEmitter<Website> = new EventEmitter();
  public catalogEmitter:EventEmitter<any> = new EventEmitter();
  public popupEmitter:EventEmitter<any> = new EventEmitter();
  public website:Website;
  public campanha:Campanha  = {} as Campanha;
  public services: Service[] = [];
  public showAddToHomeScreen:boolean = false;
  public popups: any[];
  cards:Card[];
  operadoras: any[];
  deptos: any[];
  produtos: any[];
  hostname:string = "demo_emaiswallet_com"
  enterprise: any;
  userAgent: string;
  
  constructor(public database:DatabaseService<Website>) {

    this.getLocalhost();

    window.addEventListener('beforeinstallprompt', function (e) { 
      // Prevent Chrome 67 and earlier from automatically showing the prompt e.preventDefault(); 
      // Stash the event so it can be triggered later. 
      this.deferredPrompt = e;
      this.showAddToHomeScreen = true;
    });
   }
 
  ngOnInit(): void {
    this.isMac();
  }
  
  getLocalhost(){
    if( !window.location.hostname.includes('localhost')){
      if(window.location.pathname.includes("sandbox")){
        this.hostname =  window.location.pathname.split('/')[2];
        this.hostname = this.hostname.split('.').join('_');
      }else{
         this.hostname = window.location.hostname.split('.').join('_');
      }
    }
  
    if(localStorage.getItem(this.hostname)){
      this.website = JSON.parse(localStorage.getItem(this.hostname));
      if(!this.website.font){
        this.website.font = {'family': 'Barlow Condensed'};
      }else{
        WebFont.load({
          google: {
            families: [this.website.font.family]
          }
        })
      } 
     }
  }

  init(){
    this.getLocalhost();
    let sub = this.database.get(this.hostname, "WEBSITE", "default").subscribe(website =>{
      sub.unsubscribe();
      this.website  = website.data() as Website;
      this.appendCss();
      
      if(!this.website.font){
        this.website.font = {'family': 'Barlow Condensed'};
      }else{
        WebFont.load({
          google: {
            families: [this.website.font.family]
          }
        })
      } 

      this.website.sections.forEach(element => {
        element.id = element.title.replace(' ','');
      });

      this.website.sections.sort((a,b) => a.order - b.order)

      localStorage.setItem(this.hostname, JSON.stringify(this.website));
      this.setServices();
      
      let sub2 = this.database.get(this.website.campanha, "CAMPANHAS", "default").subscribe(campanha =>{
        sub2.unsubscribe();
        this.campanha = campanha.data() as Campanha;
        if(this.campanha.tenantId){
           firebase.default.auth().tenantId = this.campanha.tenantId;
        }
        this.getEnterprise();
      });

      let query: QueryFn = ref => ref.where('tipo', '==', 'popup').where('ativo', '==', true).where('campanha', 'in', ['all', this.website.campanha]);
    
      this.database.queryValues('CRM', query).subscribe(result => {
        this.popups = result;
        this.popupEmitter.next(this.popups)
      })
      
     })

    //  this.getDeptos();  
  }

  appendCss(){
    let text =  this.website.customcss ;
    var css = document.createElement('style');
        css.appendChild(document.createTextNode(text));
    document.getElementsByTagName("head")[0].appendChild(css);
 }

  setTaxes() {
    this.services.forEach(service =>{
      if(this.campanha.services[service.type]?.tax != null){
        service.tax = this.campanha.services[service.type]?.tax;
        service.limite = this.campanha.services[service.type]?.limite;
      }else{
        service.tax = this.enterprise.services[service.type]?.tax;
        service.limite = this.enterprise.services[service.type]?.limite;
      }
    })

    this.websiteEmitter.next(this.website);
  }
  
  public isMac() {
    // Materialize.toast(navigator.platform, 3000);
    this.userAgent = window.navigator.userAgent.toLowerCase();
    const test = /iphone|ipad|ipod/.test(this.userAgent);
    return test;
  }

  getDeptos() {
    let subs = this.database.listValues("CATALOGO/MAGAZINE/DEPTOS").subscribe(deptos =>{
      subs.unsubscribe();
      this.deptos = deptos;
      this.deptos.forEach(depto =>{
        depto.categorias = [];
      }) 
      this.catalogEmitter.next(true);
    })
  }

  getEnterprise(){
    let sub3 = this.database.get(this.website.enterprise, "ENTERPRISE", "default").subscribe(result =>{
      sub3.unsubscribe();
      let enterprise = result.data() as Enterprise;
      this.cards = enterprise.Settings.cards;
      this.enterprise = enterprise;
      this.setTaxes();
    });
  }

  setServices() {
    this.services = this.website.services;
    this.services.sort((a,b) => a.order - b.order);
    this.websiteEmitter.next(this.website);
  }

  checkDemo():boolean{
    return (!environment.production || window.location.hostname.includes('localhost') || window.location.pathname.includes("sandbox"));
   }
}
