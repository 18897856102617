
<div class="row" *ngIf="lojas">
  <h6 class="white-text small col s12 m1">LOJAS</h6>
  <div class="col s3 ico-loja" *ngFor="let loja of lojas">
    <img src="{{loja.logo}}" alt="loja.nome" [ngClass]="{'unselected': selectedloja != null && selectedloja.id != loja.id }" (click)="getLoja(loja)" class="responsive-img">
  </div>
</div>
<div class="row" *ngIf="deptos$ | async">
  <div class="col s12 m3">
    <a id="deptoButton" class="btn white-text col s12"  [style.backgroundColor]="ws.website?.color1"  (click)="hideMenu = !hideMenu">
      departamentos
    </a>
  </div>
  <div class="input-field searchbox col s12 m8">
    <input id="search" class="validate white-text col s12" [(ngModel)]="searchStr"  (keyup)="hideMenu = true;"/>
    <i id="dosearch" (click)="buscaProdutos()" class="material-icons white-text right" >search</i>
  </div>
</div>

<div id="deptos" class="row" [hidden]="hideMenu">
  <ul class="collapsible col s12">
    <li *ngFor="let depto of deptos$ | async | orderBy:'name' " [hidden]="!depto?.sub_categories?.length">
      <div class="btn-depto"  (click)="getProdutos(depto)">
       {{depto.name }}
      </div>
    </li>
  </ul>
</div>


<div class="row" >
  <div class="col s6 m4" >
    <ul class="pagination col s12" *ngIf="(items$ | async)?.length >= 99">
      <li [hidden]="currentPage == 1"><a (click)="getProdutos(currentDepto, currentPage-1)"><i class="material-icons">chevron_left</i></a></li>
      <li class="white-text"><i class="material-icons">description</i> {{currentPage}}</li>
      <li class="waves-effect"><a (click)="getProdutos(currentDepto, currentPage+1)"><i class="material-icons">chevron_right</i></a></li>
    </ul>
  </div>
  <div class="col s6 m4">
    <p class="white-text small"><span >Total de Produtos: </span> <span id="totalfiltered">{{(items$ | async | filter:searchStr)?.length}}</span></p>
  </div>
  <select class="browser-default col s6 push-s6 m4" (change)="changeOrder($event)" >
    <option disabled selected>ordenar</option>
    <option value="pontos">menor valor</option>
    <option value="-pontos">maior valor</option>
    <option value="title">nome</option>
    <!-- <option value="categoria">categoria</option> -->
  </select>
</div>
<div class="row catalog" *ngIf="!(items$ | async)">
  <app-spinner ></app-spinner>
</div>

<div class="row" >
  <div [hidden]="!item?.displayvalor" class="col s12 m4 card white valign-wrapper product" *ngFor="let item of items$ | async | filter:searchStr | orderBy: currentOrder"
  (click)="selectProduct(item)">
  <img src="{{selectedloja.logo}}" class="iconparceiro" />
    <div class="col s4 m6 valign">
      <img src="{{item?.medias?.[0] | imageUrl: 200:200}}" class="responsive-img " loading="lazy" />
    </div>
    <div class="col s8 m6 valign ">
      <h6 class="left col s12 grey-text text-darken-3"><i *ngIf="item?.bundles?.length" class="material-icons">check</i>{{item?.title | shorten:40}}</h6>
      <h4 [style.font-family]="ws.website.font.family"  *ngIf="item?.displayvalor != 'ESGOTADO'" class="black-text col m7 s6 right-aligned displayvalor">{{item?.displayvalor}}<span style="font-size:0.85rem; margin-left:8px; vertical-align:super">{{campanha.moeda}}</span></h4>
      <h5 [style.font-family]="ws.website.font.family"  *ngIf="item?.displayvalor == 'ESGOTADO'" class="black-text col m7 s6 right-aligned displayvalor">{{item?.displayvalor}}</h5>
    </div>
  </div>
</div>

<div class="row" *ngIf="(items$ | async | filter:searchStr)?.length >= 99">
  <ul class="pagination col m12 s12 right-align">
    <li [hidden]="currentPage == 1"><a  (click)="getProdutos(currentDepto, currentPage-1)"><i class="material-icons">chevron_left</i></a></li>
    <li class="white-text"><i class="material-icons">description</i> {{currentPage}}</li>
    <li class="waves-effect"><a  (click)="getProdutos(currentDepto, currentPage+1)"><i class="material-icons">chevron_right</i></a></li>
  </ul>
</div>