import { AngularFireModule } from '@angular/fire/compat/';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirePerformanceModule } from '@angular/fire/compat/performance';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {NgPipesModule} from 'ngx-pipes';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { InstitucionalComponent } from './views/institucional/institucional.component';
import { LoginComponent } from './views/institucional/login/login.component';
import { RegisterComponent } from './views/institucional/register/register.component';
import { BanklineComponent } from './views/bankline/bankline.component';
import { CardsComponent } from './views/cards/cards.component';
import { DadosComponent } from './views/dados/dados.component';
import { ExtratoComponent } from './views/extrato/extrato.component';
import { HelpComponent } from './views/help/help.component';
import { HomeComponent } from './views/home/home.component';
import { MenuComponent } from './views/home/menu/menu.component';
import { PaymentComponent } from './views/payment/payment.component';
import { VoucherComponent } from './views/voucher/voucher.component';
import { RegulamentoComponent } from './views/regulamento/regulamento.component';
import { RestoreComponent } from './views/institucional/restore/restore.component';
import { StartComponent } from './views/start/start.component';
import { GiftcardComponent } from './views/giftcard/giftcard.component';
import { ShopComponent } from './views/shop/shop.component';
import { FooterComponent } from './views/institucional/footer/footer.component';
import { HeaderComponent } from './views/institucional/sections/header/header.component';
import { IconsetComponent } from './views/institucional/sections/iconset/iconset.component';
import { CommonComponent } from './views/institucional/sections/common/common.component';
import { BannersComponent } from './views/start/banners/banners.component';
import { ConfirmpanelComponent } from './views/shared/confirmpanel/confirmpanel.component';
import { BankFavoritesComponent } from './views/bankline/favorites/favorites.component';
import { MobileFavoritesComponent } from './views/mobile/favorites/favorites.component';
import { GiftFavoritesComponent } from './views/giftcard/favorites/favorites.component';
import { CardsFavoritesComponent } from './views/cards/favorites/favorites.component';
import { NewaccountComponent } from './views/bankline/newaccount/newaccount.component';
import { BankFormComponent } from './views/bankline/form/form.component';
import { ScanComponent } from './views/payment/scan/scan.component';
import { GiftCatalogComponent } from './views/giftcard/catalog/catalog.component';
import { CheckoutComponent } from './views/giftcard/checkout/checkout.component';
import { DetailComponent } from './views/giftcard/detail/detail.component';
import { GiftCartComponent } from './views/giftcard/cart/cart.component';
import { SearchComponent } from './views/shop/search/search.component';
import { CategoriesComponent } from './views/shop/categories/categories.component';
import { ChartComponent } from './views/extrato/chart/chart.component';
import { ListComponent } from './views/extrato/list/list.component';
import { FilterComponent } from './views/extrato/filter/filter.component';
import { VideoComponent } from './views/help/video/video.component';
import { ChatbotComponent } from './views/help/chatbot/chatbot.component';
import { FaqComponent } from './views/help/faq/faq.component';
import { MobileComponent } from './views/mobile/mobile.component';
import { SectionsComponent } from './views/institucional/sections/sections.component';
import { SpinnerComponent } from './views/shared/spinner/spinner.component';
import { WalletpanelComponent } from './views/home/walletpanel/walletpanel.component';
import { MessageboxComponent } from './views/home/messagebox/messagebox.component';
import { MessagebuttonComponent } from './views/home/messagebutton/messagebutton.component';
import { AddressformComponent } from './views/dados/addressform/addressform.component';
import { ChatbuttonComponent } from './views/help/chatbutton/chatbutton.component';
import { InputvalorComponent } from './views/shared/inputvalor/inputvalor.component';
import { TransacpanelComponent } from './views/extrato/transacpanel/transacpanel.component';
import { MenuInstitucionalComponent } from './views/institucional/menu/menu.component';
import { TextMaskModule } from 'angular2-text-mask';
import { CardsFormComponent } from './views/cards/form/form.component';
import { DadosFormComponent } from './views/dados/form/form.component';
import { PaymentFormComponent } from './views/payment/form/form.component';
import { CameraComponent } from './views/shared/camera/camera.component';
import { VoucherFormComponent } from './views/voucher/form/form.component';
import { VoucherScanComponent } from './views/voucher/scan/scan.component';
import { NospacePipe } from './nospace.pipe';
import { MobileFormComponent } from './views/mobile/form/form.component';
import { FilterPipe } from './pipes/filter.pipe';
import { FormService, AuthService, DatabaseService, ConfigService, CookieService, CepService, MessagingService} from '@ollieestudio/fire-lib';
import { NgxViacepService } from '@brunoc/ngx-viacep';
import { HttpClient, HttpHandler, HttpClientModule } from '@angular/common/http';
import { firebaseConfig } from './services/firebase-config.service';
import { DatePipe } from '@angular/common';
import { BarcodeScannerLivestreamModule } from 'ngx-barcode-scanner';
import { ShopCheckoutComponent } from './views/shop/checkout/checkout.component';
import { ShopCartComponent } from './views/shop/cart/cart.component';
import { ShopCatalogComponent } from './views/shop/catalog/catalog.component';
import { ToarrayPipe } from './pipes/toarray.pipe';
import { HifenToSpacePipe } from './pipes/hifen-to-space.pipe';
import { DetailShopComponent } from './views/shop/detail/detail.component';
import { UpdatesComponent } from './views/start/updates/updates.component';
import { GetpointsComponent } from './views/getpoints/getpoints.component';
import { GetpointsformComponent } from './views/getpoints/getpointsform/getpointsform.component';
import { NgChartsModule } from 'ng2-charts';
import { ProspectComponent } from './views/institucional/prospect/prospect.component';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { PopupComponent } from './views/shared/popup/popup.component';
import { MercadopagoButtonComponent } from './views/shared/mercadopago-button/mercadopago-button.component';
import { JsontableComponent } from './views/shared/jsontable/jsontable.component';
import { PasswordPolicyComponent } from './views/institucional/register/password-policy/password-policy.component';
import { AppUpdateService } from './services/di/app-update.service';
import { HelpdeskComponent } from './views/help/helpdesk/helpdesk.component';
import { PrintService } from './services/print.service';
import { SalesComponent } from './views/sales/sales.component';
import { FormSalesComponent } from './views/sales/form/form.component';
import { ListSalesComponent } from './views/sales/list/list.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NegativeToZeroPipe } from './pipes/negative-to-zero.pipe';
import { TotalDisplayComponent } from './views/shared/total-display/total-display.component';
import { QrcodepayComponent } from './views/qrcodepay/qrcodepay.component';
import { QrcodepayFormComponent } from './views/qrcodepay/qrcodepay-form/qrcodepay-form.component';
import { NgxScannerQrcodeModule, LOAD_WASM } from 'ngx-scanner-qrcode';
import { ImageUrlPipe } from './pipes/image-url.pipe';
import { PrintvouchersComponent } from './views/voucher/printvouchers/printvouchers.component';
import { ListaVouchersComponent } from './views/voucher/lista-vouchers/lista-vouchers.component';

LOAD_WASM().subscribe((res: any) => console.log('LOAD_WASM', res));

@NgModule({
  declarations: [
    MobileFormComponent,
    VoucherScanComponent,
    VoucherFormComponent,
    PaymentFormComponent,
    VideoComponent,
    CardsFormComponent,
    CardsFavoritesComponent,
    DadosFormComponent,
    AppComponent,
    InstitucionalComponent,
    LoginComponent,
    RegisterComponent,
    BanklineComponent,
    CardsComponent,
    DadosComponent,
    ExtratoComponent,
    HelpComponent,
    HomeComponent,
    MenuComponent,
    PaymentComponent,
    VoucherComponent,
    RegulamentoComponent,
    RestoreComponent,
    StartComponent,
    GiftcardComponent,
    ShopComponent,
    ShopCheckoutComponent,
    ShopCartComponent,
    ShopCatalogComponent,
    FooterComponent,
    HeaderComponent,
    IconsetComponent,
    CommonComponent,
    BannersComponent,
    ConfirmpanelComponent,
    BankFavoritesComponent,
    CardsFavoritesComponent,
    GiftFavoritesComponent,
    MobileFavoritesComponent,
    NewaccountComponent,
    BankFormComponent,
    ScanComponent,
    GiftCatalogComponent,
    CheckoutComponent,
    DetailComponent,
    GiftCartComponent,
    SearchComponent,
    CategoriesComponent,
    ChartComponent,
    ListComponent,
    FilterComponent,
    VideoComponent,
    ChatbotComponent,
    FaqComponent,
    MobileComponent,
    SectionsComponent,
    SpinnerComponent,
    WalletpanelComponent,
    MessageboxComponent,
    MessagebuttonComponent,
    AddressformComponent,
    ChatbuttonComponent,
    InputvalorComponent,
    TransacpanelComponent,
    MenuComponent,
    MenuInstitucionalComponent,
    CameraComponent,
    NospacePipe,
    FilterPipe,
    ToarrayPipe,
    HifenToSpacePipe,
    DetailShopComponent,
    UpdatesComponent,
    GetpointsComponent,
    GetpointsformComponent,
    ProspectComponent,
    PopupComponent,
    MercadopagoButtonComponent,
    JsontableComponent,
    PasswordPolicyComponent,
    HelpdeskComponent,
    SalesComponent,
    FormSalesComponent,
    ListSalesComponent,
    NegativeToZeroPipe,
    TotalDisplayComponent,
    QrcodepayComponent,
    QrcodepayFormComponent,
    ImageUrlPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TextMaskModule,
    NgPipesModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ScrollToModule.forRoot(),
    HttpClientModule,
    BarcodeScannerLivestreamModule,
    FormsModule,
    NgChartsModule,
    AngularFireModule.initializeApp(environment.firebaseAuth),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireMessagingModule,
    PasswordStrengthMeterModule.forRoot(),
    NgxScannerQrcodeModule,
    PrintvouchersComponent,
    ListaVouchersComponent
  ],
  providers: [ConfigService, AuthService, FormService, DatabaseService, CookieService, MessagingService, PrintService,
    CepService, NgxViacepService, HttpClient, firebaseConfig, DatePipe, NegativeToZeroPipe, AppUpdateService, AngularFireAuthModule, AngularFirestoreModule,
    AngularFireMessagingModule],
  bootstrap: [AppComponent]
})

  export class AppModule { 
    
  }
