<div id="top" class="toppad">
  &nbsp;
</div>
<div class="container top-5">
  <div class="row" *ngIf="service">
    
    <h3 [style.font-family]="ws.website.font.family"  class="col s12 m11 white-text upper"><i class="material-icons">{{service.icon}}</i>&nbsp;{{service.title}}</h3>
  </div>

  <div class="row cart-footer"[ngStyle]="{'background-color': (isMobile) ? ws.website?.color1 : ''}" [hidden]="hideCart">
    <app-shop-cart></app-shop-cart>
  </div>

  <div class="row" [hidden]="!hideCart">
    <app-shop-checkout [service]="service" [campanha]="ws.campanha"></app-shop-checkout>
  </div>
  
   
    <app-shop-catalog [hidden]="hideCart" [(hideMenu)]="hideMenu" ></app-shop-catalog>
    <app-detail-shop ></app-detail-shop>