<form action="/procesar-pago" method="POST" target="_blank" >
    <!-- <button type="button" 
    class="mercadopago-button" id="mercadopago-button">
    Buy now
</button> -->


    <button class="waves-effect waves-light btn col m3 s12 right" [style.backgroundColor]="ws.website?.color1"
        id="bt-cp-card" (click)="onBuy()"> <i aria-hidden="true" class=" white-text mdi mdi-credit-card right"></i>pagar
        no Mercado Pago</button>

</form>