import { Component, OnInit, ViewChild } from '@angular/core';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { WebsiteService } from 'src/app/services/di/website.service';
import { ConfirmService } from 'src/app/services/confirm.service';
import { AuthService } from '@ollieestudio/fire-lib';
import { Premiado } from 'src/app/services/interfaces/premiado';
import { Tipo } from 'src/app/services/enum/tipo.enum';
import { Service } from 'src/app/services/interfaces/service';
import { GiftCatalogComponent } from './catalog/catalog.component';
import { GiftCartComponent } from './cart/cart.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { DetailComponent } from './detail/detail.component';

@Component({
  selector: 'app-giftcard',
  templateUrl: './giftcard.component.html',
  styleUrls: ['./giftcard.component.css']
})
export class GiftcardComponent implements OnInit {
  @ViewChild(GiftCatalogComponent, { static: true }) catalog:GiftCatalogComponent;
  @ViewChild(GiftCartComponent, { static: true }) cart:GiftCartComponent;
  @ViewChild(CheckoutComponent, { static: true }) checkout:CheckoutComponent;
  @ViewChild(DetailComponent, { static: true }) detail:DetailComponent;
  servicetype: string =  Tipo['giftcard'];
  service:Service;
  public hideCart:boolean = false;
  searchStr: string;
  selectedCard: any;
  
  constructor(public analytics:AnalyticsService, private scrollService: ScrollToService, public ws:WebsiteService,  public confirm:ConfirmService, public auth:AuthService<Premiado>)
   { 
    let sub = this.ws.websiteEmitter.subscribe(website =>{
      sub.unsubscribe();
      this.service = {...this.ws?.campanha?.services[this.servicetype], ...this.ws?.services?.filter(service => service.type === this.servicetype)[0]};
    })
   }

   ngOnInit() {
    this.service = {...this.ws?.campanha?.services[this.servicetype], ...this.ws?.services?.filter(service => service.type === this.servicetype)[0]};
    this.confirm.resultEmitter.subscribe(result =>{
      if(result === true){
      //  this.showForm = true;
       window.scrollTo(0,0);
       }
    })
    this.catalog.cardEmitter.subscribe(card =>{
      this.selectCard(card);
    })

    this.detail.cardEmitter.subscribe(card =>{
      this.cart.addProduct(card);
      this.searchStr = "";
      this.analytics.logEvent('giftcard_addtocart', {userid: this.auth.User.id, product:card});
    })

    this.checkout.resetCheckout.subscribe(() =>{
      this.hideCart = false;
      this.cart.resetCart();
    })

    this.cart.checkoutEmitter.subscribe(checkout =>{
      this.checkout.cartList = this.cart.cartList;
      this.checkout.checkOut();
      this.hideCart = true;
    })
   }

   selectCard(product:any){
    this.selectedCard = product;
    this.detail.displayDetail(product);
    this.analytics.logEvent('view_item', {userid: this.auth.User.id});
   }

}
