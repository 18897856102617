import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ConfirmService } from 'src/app/services/confirm.service';
import { AuthService, CookieService, DatabaseService, MaterializeService } from '@ollieestudio/fire-lib';
import { HttpClient } from '@angular/common/http';
import { Transaction } from 'src/app/services/interfaces/transaction';
import { Phone } from 'src/app/services/interfaces/phone';
import { environment } from 'src/environments/environment';
import { WebsiteService } from 'src/app/services/di/website.service';
import { Premiado } from 'src/app/services/interfaces/premiado';
import { DevicesService } from 'src/app/services/devices.service';
import { Tipo } from 'src/app/services/enum/tipo.enum';

declare const detectOTP: any, receiveOTP:any;

@Component({
  selector: 'app-confirmpanel',
  templateUrl: './confirmpanel.component.html',
  styleUrls: ['./confirmpanel.component.css']
})
export class ConfirmpanelComponent implements OnInit {
  @ViewChild(Input, { static: true }) codeInput:Input;
  public modal;
  public transac:Transaction;
  public erros:string;
  verified_device: boolean;
  cookie:any;

  constructor(public devices:DevicesService, private database:DatabaseService<Transaction>, public ws:WebsiteService, private auth:AuthService<Premiado>, private cookies:CookieService<any>, public confirm:ConfirmService, public material:MaterializeService, public http:HttpClient) { }

  ngOnInit() {
    /* this.material.initModal('#modal-qrcode', {dismissible:false});
    this.material.initModal('#modal-qrcode', {dismissible:false});
    this.material.initModal('#modal-qrcode', {dismissible:false}); */
    this.confirm.confirmEmitter.subscribe(transac =>{
      if(transac.tipo === Tipo['compra-pontos']){
        this.openQrCodePanel(transac);
      }else{
        this.openConfirmPanel(transac);
      }
    })
    this.verified_device = this.checkVerifiedDevice();
  }

  openQrCodePanel(transac: Transaction) {
    let sub = this.database.observe(transac.id, 'TRANSACTIONS').subscribe(data =>{
    if(data.privatedata.brcode){
      sub.unsubscribe();
      this.transac = data;
      this.modal = this.material.initModal('#modal-qrcode', {dismissible:false});
      this.material.openModal('#modal-qrcode');
    }
   });
    
  }

  checkVerifiedDevice() {
   this.cookie = this.cookies.get('verified_device');
   return this.cookie?.id === this.auth.User.id;
  }

  async openConfirmPanel(transac: any) {
    this.transac = transac;
    this.modal = this.material.initModal('#modal-confirm', {dismissible:false});
    this.material.openModal('#modal-confirm');
    if (detectOTP()) {
      const code = receiveOTP().match(/^[\s\S]*otp=([0-9]{4})[\s\S]*$/m)[1];
      this.sendConfirm(code);
    }
  }

  sendConfirm(code:string){
    //envia codigo de validacao
    if(!code)  code = ((document.getElementById("confirmcode") as HTMLInputElement).value);
    let url: string = environment.rooturl +  `/transacConfirm?id=${this.transac.id}&code=${code}`;
    let sub = this.http.get(url).subscribe(result =>{
      sub.unsubscribe();
      // retorna resultado
      (document.getElementById("confirmcode") as HTMLInputElement).value = null;
      this.material.closeModal('#modal-confirm'); 
      document.body.style.overflow = '';
      this.confirm.resultEmitter.next(result as boolean);
      if(result === true){
        this.alertVerifiedDevice()
      }
    })
  }

  alertVerifiedDevice() {
    this.material.delay(3000);
    this.material.initModal('#modal-verify-device', {dismissible:false});
    this.material.openModal('#modal-verify-device'); 
  }


  confirmVerified(){
    let verifiedcookie:any = {}
    verifiedcookie.expires = new Date().getTime() + 5260000000;
    verifiedcookie.id = this.auth.User.id;
    this.cookies.set(verifiedcookie, 'verified_device');
    this.verified_device = true;
    this.cookie = verifiedcookie;
    this.devices.saveNewDevice(verifiedcookie.id, verifiedcookie.expires)
  }
  
  cancelVerified(){
    this.material.closeModal('#modal-verify-device'); 
    document.body.style.overflow = '';
  }

  cancelConfirm(){
    //envia codigo de validacao
    // retorna resultado
    this.material.closeModal('#modal-confirm'); 
    document.body.style.overflow = '';
   this.confirm.resultEmitter.next(false);
  }

  autoConfirm(){
    this.confirm.resultEmitter.next(true);
  }
  
  cancelAutoConfirm(){
    this.cookies.remove('verified_device');
    this.verified_device = null;
    this.material.closeModal('#modal-confirm'); 
    document.body.style.overflow = '';
    alert('Esse aparelho foi descadastrado, por favor realize o resgate novamente.')
  }

  sendEmail(){

  }

  copyToClipboard(inputElement: HTMLInputElement) {
    inputElement.select();
    document.execCommand('copy');
  }
}
