<div class="section">
  <div class=" row top-10">

    <form [formGroup]="formRestore" class="col s11 m5 valign centered">

      <div class="centered padded col s12 m10">
        <h5 [style.font-family]="ws.website.font.family"  class="center white-text text-darken-2">Recuperar senha</h5>
        <h6 class="white-text">Preencha o campo abaixo e um e-mail de renovação de senha será enviado.</h6>

        <div class="row">
          <div class="block input-field col s12 ">
            <label class="white-text" for="doc">Documento:</label><input [formControlName]="'documento'"
              [textMask]="{mask:maskCPF}" class="white-text" type="text" id="doc" />
          </div>
          <div class="block input-field col s12 ">
            <label class="white-text" for="email">Email:</label><input [formControlName]="'email'"
              class="white-text" type="email" id="email" />
          </div>
        </div>
        <div class="btns top-5 row s10">
          <div class="col s12 center">
            <a id="restore-btn" (click)="restoreUser()" [style.backgroundColor]="ws.website?.color1"  class="btn col s12 m3 white-text right">enviar</a>
            <a routerLink="../../institucional/sections" id="back" class="btn-flat col s12 m3 right white-text">Voltar</a>
          </div>
        </div>
      </div>

    </form>
  </div>
</div>