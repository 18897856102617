<footer id="contato" class="page-footer customcolor1" [style.backgroundColor]="website?.color1" *ngIf="website.title">
  <div class="section ">
    <div class="row top-10">
      <div class="col l6 s12">
        <h5 [style.font-family]="website.font.family"  id="quemtit" class="customcolor2">Quem somos</h5>
        <p  id="quemtext" class="customcolor2" [innerHTML]="website.resumo"></p>


      </div>
      <div class="col l3 s12" *ngIf="services">
        <h5 [style.font-family]="website.font.family"  id="servtit" class="white-text">Serviços</h5>
        <ul>
          <li class="service" *ngFor="let service of services" [hidden]="!service.active">
            <a class="customcolor2 link" routerLink="{{'service.'+ service.type}}">
              <i class="material-icons " aria-hidden="true">{{service.icon}}</i> {{service.title}}</a>
          </li>
        </ul>
      </div>
      <div class="col l3 s12">
        <h5 [style.font-family]="website.font.family"  id="conecttit" class="customcolor2">Conecte-se</h5>
        <ul>
          <li class="contato" *ngFor="let contato of website.contatos">
            <a class="customcolor2" href="{{'http://' + contato.link}}" target="_blank" *ngIf="contato.tipo === 'website'">
              <i class="material-icons" aria-hidden="true">{{contato.icon}}</i> {{contato.text}}
            </a>
            <a class="customcolor2" href="{{'mailto:' + contato.link}}" target="_blank" *ngIf="contato.tipo === 'email'">
              <i class="material-icons" aria-hidden="true">{{contato.icon}}</i> {{contato.text}}
            </a>
            <a class="customcolor2" href="{{'tel:' + contato.link}}" target="_blank" *ngIf="contato.tipo === 'telefone'">
              <i class="material-icons" aria-hidden="true">{{contato.icon}}</i> {{contato.text}}
            </a>
            <a class="customcolor2" href="{{contato.link}}" target="_blank" *ngIf="contato.tipo === 'facebook'">
              <i class="material-icons" aria-hidden="true">{{contato.icon}}</i> {{contato.text}}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="footer-copyright">
    <div class="col s10 centered">
      Powered by <a class="black-text" target="_blank" href="http://www.emaiswallet.com">E+ Wallet</a>
    </div>
  </div>
</footer>