<form [formGroup]="formProfile"  (submit)="openConfirm()">
  <div class="row">
    <div class="block input-field col s12 m6">
      <label class="active white-text text-darken-2" for="nome">Nome Completo/Razão Social:</label><input
        [formControlName]="'nome'" class="string white-text" type="text" id="nome" [readonly]="true"/>
    </div>

    <div class="block input-field col s12 m6">
      <label class="active white-text text-darken-2" for="apelido">Apelido:</label><input [formControlName]="'apelido'"
        class="string white-text" type="text" id="apelido" />
    </div>
  </div>
  <div class="row">
    <div class="block input-field col s12 m6" ng-hide="demosign != null">
      <label class="active white-text text-darken-2" for="doc">Documento:</label>
      <input [formControlName]="'documento'"  [readonly]="true"
        [textMask]="{mask:maskCPF, guide:false}" class="string white-text" type="text" id="doc" />
    </div>

    <div class="block range-field col s12 m6">
      <p class="col s6">
        <label for="tipopessoa1" class="white-text" >
          <input required name="pjpf" class="with-gap white-text " type="radio" id="tipopessoa1"
            [formControlName]="'pjpf'" value="Pessoa Física" selected [readonly]="true"/>
          <span>Pessoa Física</span></label>
      </p>
      <p class="col s6">
        <label for="tipopessoa2" class="white-text">
          <input required name="pjpf" class="with-gap white-text " type="radio" id="tipopessoa2"
            [formControlName]="'pjpf'" value="Pessoa Jurídica" [readonly]="true"/>
          <span>Pessoa Jurídica</span></label>
      </p>
    </div>
  </div>
  <div class="row">
    <div class="block input-field col s12 m6">
      <label class="active white-text active text-darken-2" for="email">Email:</label><input [formControlName]="'email'"
        class="string white-text" type="email" id="email"  />
    </div>

    <div class="block input-field col s12 m6">
      <label class="active white-text active text-darken-2" for="celular">Celular:</label>
      <input 
        [formControlName]="'celular'" [textMask]="{mask:maskCelular, guide:false}" class="string white-text" type="text" id="celular" />
    </div>
  </div>
  <div class="row">
    <div class="block input-field col s12 m6">
      <label class="active white-text text-darken-2" for="password">Senha:</label>
      <input [formControlName]="'password'"
        class="string white-text" [type]="showPassword ? 'text' : 'password'" id="dadospassword" [(ngModel)]="mypass" />
        <i class="grey-text material-icons link suffix" *ngIf="!showPassword" (click)="showPassword = !showPassword">visibility</i>
        <i class="grey-text material-icons link suffix" *ngIf="showPassword" (click)="showPassword = !showPassword">visibility_off</i>
        
        <password-strength-meter [minPasswordLength]="8" [password]="mypass"></password-strength-meter>
      <p class="tiny grey-text text-lighten-2">Sua senha deve conter número, letras maiúsculas e minúsculas e pelo
        menos 8 caracteres.</p>
    </div>

    <div class="block input-field col s12 m6">
      <label class="active white-text text-darken-2" for="password2">Confirmar senha:</label><input
        [formControlName]="'password2'" class="string white-text"  [type]="showPassword ? 'text' : 'password'" id="password2" />
    </div>
  </div>

  <div class="row">
      <div class="col s12 ">
        <a class="btn-flat col m3 s12 left" id="bt-cancel-register" routerLink="./start" routerLinkActive="router-link-active"  >voltar</a>
        <button type="submit" [disabled]="formProfile.invalid" [style.backgroundColor]="ws.website?.color1" class="waves-effect waves-light btn col m3 s12 right customcolor1 center" id="bt-login" >salvar</button>
      </div>
    </div>

</form>

<!-- Modal type Ok -->
<div id="modal-confirm" class="modal bottom-sheet confirmpanel">
  <div class="modal-content" >
<div class="" ng-show="showConfirm">
  <h5 [style.font-family]="ws.website.font.family"  class="customcolor1">CONFIRMAR</h5>
  <span class="grey-text col s12">Insira no campo abaixo, o código de 4 dígitos enviado por SMS, ou escolha uma
    das outras opções.</span>

  <div class="row">
    <div class="input-field col s12">
      <input #confirm required id="confirmcode" name="confirm" type="text" class="validate grey-text" />
      <label for="confirmcode" class="active grey-text text-lighten-1">Código de confirmação</label>
    </div>
  </div>

  <div class="row">
    <div class="col s12 centered">
      <a class="waves-effect waves-light btn col s12 green" (click)="savedata(confirm.value)"><i
          class="material-icons white-text">done</i> confirmar</a>
      
      <a class="waves-effect waves-light btn col s12 red modal-close" >cancelar</a>
    </div>
  </div>
  </div>
  </div>
  </div>

  