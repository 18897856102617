<div class="toppad">
  &nbsp;
</div>
<div class="container top-5 ">
  <div class="row">
    <div class="col s12 m11">
      <h3 [style.font-family]="ws.website.font.family"  class="col s12  white-text upper"><i class="fa fa-bar-chart"></i>&nbsp;EXTRATO</h3>
      <app-performance-chart [extrato]="extrato" *ngIf="extrato"></app-performance-chart>
      <div class="input-field left col s12 m12">
        <input id="search" class="active white-text col m8 s12" [(ngModel)]="searchStr"  />
        <label class="white-text active" for="search">Digite para filtrar...</label>
         <!-- Switch -->
        <div class="switch col m4 s8 right">
          <label>
            <input type="checkbox" [(ngModel)]="hideTransacs">
            <span class="lever "></span>
            Ocultar cancelados
          </label>
        </div>
      </div>

      <app-extrato-list [extrato]="extrato" [searchStr]="searchStr" [hidetransacs]="hideTransacs"></app-extrato-list>
    </div>
  </div>
</div>