import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { InputvalorComponent } from '../../shared/inputvalor/inputvalor.component';
import { Phone } from 'src/app/services/interfaces/phone';
import { Tipo } from 'src/app/services/enum/tipo.enum';
import { Service } from 'src/app/services/interfaces/service';
import { ConfirmService } from 'src/app/services/confirm.service';
import { MaterializeService, DatabaseService, AuthService } from '@ollieestudio/fire-lib';
import { Premiado } from 'src/app/services/interfaces/premiado';
import { WebsiteService } from 'src/app/services/di/website.service';
import { QueryFn } from '@angular/fire/compat/firestore';
import { Transaction } from 'src/app/services/interfaces/transaction';
import { Status } from 'src/app/services/enum/status.enum';

@Component({
  selector: 'app-mobile-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.css']
})
export class MobileFavoritesComponent implements OnInit {
  @Output() phoneEmitter:EventEmitter<Phone> = new EventEmitter();
  @ViewChild(InputvalorComponent, { static: true }) valor:InputvalorComponent;
  mymobiles: Phone[];
  selectedmobile: Phone;
  showform: boolean;
  servicetype: string =  Tipo['mobile'];
  service:Service;

  constructor(public confirm:ConfirmService, public material:MaterializeService, public database:DatabaseService<Phone>,  public auth:AuthService<Premiado>, public ws:WebsiteService) { 
    this.service = {...this.ws.campanha.services[this.servicetype], ...this.ws.services.filter(service => service.type === this.servicetype)[0]};
    let sub = this.auth.stateObservable.subscribe(result =>{
      sub.unsubscribe();
      this.getMobiles(result.uid);
    })
  }

  ngOnInit() {
    if(this.auth.User && !this.mymobiles){
      this.getMobiles(this.auth.User.authid)
    }
  }

  getMobiles(id){
    let query:QueryFn = ref => ref.where('authid', '==', id).where('usuario', '==', this.auth.User.id); 
    let sub = this.database.queryValues('MOBILES', query).subscribe(mobiles =>{
      this.mymobiles = mobiles;
      })
  }

  selectPhone(phone:Phone){
    this.phoneEmitter.next(phone);
  }

}