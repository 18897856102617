import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { InputBase, FormService, DatabaseService, AuthService, MaterializeService } from '@ollieestudio/fire-lib';
import { WebsiteService } from 'src/app/services/di/website.service';
import { Premiado } from 'src/app/services/interfaces/premiado';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ConfirmService } from 'src/app/services/confirm.service';
import { QueryFn } from '@angular/fire/compat/firestore';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-restore',
  templateUrl: './restore.component.html',
  styleUrls: ['./restore.component.css']
})
export class RestoreComponent implements OnInit {
  public formRestore:FormGroup;
  public maskCPF = [/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/];
  public maskCNPJ = [/[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/];
  private fields:InputBase[] = [
    {key:"email", required:true},
    {key:"documento", required:true}
  ]
  constructor(public confirm:ConfirmService, public http:HttpClient, public router:Router, public material:MaterializeService, public ws:WebsiteService, public formserv:FormService,public auth:AuthService<Premiado>, public database:DatabaseService<Premiado>) { }

  ngOnInit() {
    this.formRestore = this.formserv.toFormGroup(this.fields)
  }

  restoreUser(){
    let dados:any = this.formRestore.getRawValue();

    let url = environment.rooturl + "/resetPassword?email="+ dados.email + "&ws=" + this.ws.website.id ;
    this.http.get(url, {responseType: 'text'}).subscribe(result => {
      this.material.toast(this.confirm.generateCheckMessage("E-mail de recuperação enviado."), 3000, 'green full');
    }, error =>{
      this.material.toast(this.confirm.generateCheckMessage("Cadastro incompleto ou não localizado."), 3000, 'red full');

    })

  }
}
