import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-transacpanel',
  templateUrl: './transacpanel.component.html',
  styleUrls: ['./transacpanel.component.css']
})
export class TransacpanelComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
