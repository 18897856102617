<div class="toppad">
  &nbsp;
</div>
<div class="container top-5">
  <div class="row" *ngIf="service">
    
    <h3 [style.font-family]="ws.website.font.family"  class="col s12 m11 white-text upper"><i class="material-icons">{{service.icon}}</i>&nbsp;{{service.title}}
    </h3>
  </div>
  <div class="row">
    
    <div class="col s12 m11 instrucoes">
      <h5 [style.font-family]="ws.website.font.family"  class="white-text">INSTRUÇÕES</h5>
      <ul class="browser-default">
        <li><span class="white-text">A quitação das contas poderá ocorrer até as 23:59 do dia.</span></li>
        <li><span class="white-text">Somente pagamentos de conta de consumo e boletos são permitidos. </span></li>
        <li><span class="white-text">Serão aceitos apenas boletos registrados pelo cedente e dentro do prazo de vencimento.</span></li>
        <li><span class="white-text">NÃO serão aceitos boletos de DEPÓSITO EM CONTA.</span></li>
        <li><span class="white-text">Considere sua conta paga somente após visualizar o comprovante de pagamento no seu
            “Extrato”.</span></li>
        <li><span class="white-text">Para sua segurança, imprima o comprovante de pagamento e mantenha seu e-mail de cadastro atualizado.</span></li>
            <li *ngIf="service"><span class="white-text bold">Taxa de Serviço: {{service.tax*ws.campanha.conversion}} {{ws.campanha.moeda}} / Limite por solicitação: {{service.limite*ws.campanha.conversion}} {{ws.campanha.moeda}}</span></li>
          </ul>
    </div>
  </div>
  <div class="row" *ngIf="!selectedMethod">
   
      <a class="btn col m3 s12"  [style.backgroundColor]="ws.website?.color1" (click)="selectMethod('scan')"><i class="material-icons">camera</i> escanear código</a>
 
      <a class="btn col m3 s12"  [style.backgroundColor]="ws.website?.color1" (click)="selectMethod('type')"><i class="material-icons">edit</i> digitar código</a>

  </div>

  <barcode-scanner-livestream [hidden]="selectedMethod != 'scan'" type="i2of5"></barcode-scanner-livestream>

  <div id="menu-scan" class="row" [hidden]="selectedMethod != 'scan'">
    <div class="col s12 right">
      <a class="btn-flat col m3 s12 right white-text" id="bt-cancel-scan" (click)="selectMethod('type')">
        <i class="material-icons white-text">edit</i>  digitar código</a>
    </div>
  </div>

  <div class="row" [hidden]="selectedMethod != 'type'">
    <app-payment-form [(showform)]="selectedMethod" [service]="service"></app-payment-form>
  </div>
</div>