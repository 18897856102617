import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService, MaterializeService } from '@ollieestudio/fire-lib';
import { WebsiteService } from 'src/app/services/di/website.service';
import { Campanha } from 'src/app/services/interfaces/campanha';
import { Giftcard } from 'src/app/services/interfaces/giftcard';
import { Premiado } from 'src/app/services/interfaces/premiado';
import { Produto } from 'src/app/services/interfaces/produto';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DetailComponent implements OnInit {
  @Output() cardEmitter: EventEmitter<any> = new EventEmitter();
  @Input() card:Giftcard;
  campanha: Campanha;
  saldo: any;
  ready: boolean;
  showjson: boolean;
  http: any;
  descricao: any;
  fichatecnica: any;
  displayvalor: number;

  constructor(public ws:WebsiteService, public material:MaterializeService, private auth:AuthService<Premiado>) { }

  ngOnInit() {
    this.material.initModal('#modalvouchers');
    this.material.initTabs('.tabs');
    this.campanha = this.ws.campanha;
    if(this.auth.User) this.saldo = this.auth.User.saldo
  }

  checkSaldo():boolean{
    if(Number.isNaN(this.displayvalor)) return true;
    let total:number = this.displayvalor;
   return total > this.saldo;
  }

  displayDetail(card:Giftcard){
    this.card = card;
    console.log(card);
    this.displayvalor = this.card.valor;
    
    this.material.openModal('#modalvouchers');
  }

  changeVal(ivynt:Event){
    this.card.quantidade = parseInt(ivynt.target['value']);
  }

  addItem(){
    this.card.quantidade = 1;
    this.card.valor = this.displayvalor;
    this.cardEmitter.emit(this.card);
  }
}
