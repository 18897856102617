import { Component, Input, OnInit } from '@angular/core';
import { Website } from 'src/app/services/interfaces/website';
import { MaterializeService } from '@ollieestudio/fire-lib';

declare const M;
@Component({
  selector: 'app-menu-institucional',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuInstitucionalComponent implements OnInit {
  @Input() public website: Website;

  constructor(public materialize:MaterializeService) { }

  ngOnInit() {
    this.materialize.initSidenav();
  }

}