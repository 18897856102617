import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ListService } from '@ollieestudio/fire-lib';
import { Transaction } from 'src/app/services/interfaces/transaction';
import { VoucherService } from '../voucher.service';
import { Status } from 'src/app/services/enum/status.enum';
import { PrintvouchersComponent } from '../printvouchers/printvouchers.component';

@Component({
  selector: 'app-lista-vouchers',
  standalone: true,
  imports: [CommonModule, PrintvouchersComponent],
  templateUrl: './lista-vouchers.component.html',
  styleUrls: ['./lista-vouchers.component.css']
})

export class ListaVouchersComponent {
  @Output() redeem:EventEmitter<Transaction> = new EventEmitter();
  @ViewChild('printvouchers', {static:true}) print:PrintvouchersComponent;
  @Input() extrato:Transaction[];
  email:string;

constructor(public sorter:ListService, private service:VoucherService){}

  async sendEmailVoucher(voucher:Transaction, email:string){
  voucher.privatedata.dados.email = email;
  voucher.status = Status['enviado'];
  voucher['showEmail'] = false;
  await this.service.updateVoucher(voucher);
}

downloadVoucher(voucher:Transaction){
  this.print.printVoucher(voucher.pedido, [voucher]);
}

redeemVoucher(voucher:Transaction){
  if(confirm('Você confimar o resgate do voucher para sua conta?')){
    this.redeem.emit(voucher);
  }
}
}
