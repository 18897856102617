import { Component, OnInit } from '@angular/core';
import { WebsiteService } from 'src/app/services/di/website.service';
import { Campanha } from 'src/app/services/interfaces/campanha';
import { MaterializeService } from '@ollieestudio/fire-lib';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  campanha:Campanha;
  searchStr:string;
  
  constructor(public ws:WebsiteService, public material:MaterializeService) { }

  ngOnInit() {
    this.campanha = this.ws.campanha;
    this.material.initCollapsible();
  }

}
