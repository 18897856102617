<!-- Modal CONFIRM -->
<div id="modal-confirm" class="modal bottom-sheet confirmpanel">
  <div class="modal-content">
    <div class="" ng-show="showConfirm">
      <h5 [style.font-family]="ws.website.font.family" class="customcolor1">CONFIRMAR</h5>
      <span [hidden]="verified_device" class="grey-text col s12 top-10">Insira no campo abaixo, o código de 4 dígitos
        enviado por SMS, ou escolha uma
        das outras opções.</span>

      <h5 *ngIf="verified_device" class="grey-text col s12 top-5">Este aparelho está autorizado a realizar transações
        sem código de verificação até {{cookie.expires | date:'dd/MM/yyyy'}}
      </h5>

      <div [hidden]="verified_device" class="row top-10">
        <div class="input-field col s12">
          <input #confirm required id="confirmcode" name="confirm" type="text" class="validate grey-text" />
          <label for="confirmcode" class="active grey-text text-lighten-1">Código de confirmação</label>
        </div>
      </div>

      <span id="displayErros" class="red-text col s12">{{erros}}</span>

      <div [hidden]="verified_device" class="row">
        <div class="col s12 centered">
          <a class="waves-effect waves-light btn col s12 green" (click)="sendConfirm(confirm.value)"><i
              class="material-icons white-text">done</i> confirmar</a>
          <!-- <a class="waves-effect waves-light btn col s12 blue" (click)="sendEmail()"><i
          class="material-icons white-text">email</i> enviar por e-mail</a> -->
          <a class="waves-effect waves-light btn col s12 red" (click)="cancelConfirm()">cancelar</a>
        </div>
      </div>

      <div [hidden]="!verified_device" class="row">
        <div class="col s12 centered">
          <a class="waves-effect waves-light btn col s12 green modal-close" (click)="autoConfirm()"><i
              class="material-icons white-text">done</i> confirmar</a>

          <a class="waves-effect waves-light btn col s12 orange modal-close" (click)="cancelAutoConfirm()"><i
              class="material-icons white-text">cancel</i> descadastrar aparelho</a>

          <a class="waves-effect waves-light btn col s12 red" (click)="cancelConfirm()">cancelar</a>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal type Ok -->
<div id="modal-verify-device" class="modal bottom-sheet confirmpanel">
  <div class="modal-content">
    <div class="" ng-show="showConfirm">
      <h4 [style.font-family]="ws.website.font.family" [style.color]="ws.website.color1" class="customcolor1">VERIFICAR
        APARELHO</h4>
      <h5 *ngIf="!verified_device" class="grey-text col s12 top-5">Deseja gravar esse aparelho como VERIFICADO e
        realizar transações
        SEM CÓDIGO DE VERIFICAÇÃO?
      </h5>

      <h5 *ngIf="verified_device" class="grey-text col s12 top-5">Este aparelho está autorizado a realizar transações
        sem código de verificação até {{cookie.expires | date:'dd/MM/yyyy'}}
      </h5>


      <div class="row top-5">
        <div class="col s12 centered">
          <a *ngIf="!verified_device" class="waves-effect waves-light btn col s12 green" (click)="confirmVerified()"><i
              class="material-icons white-text">done</i> sim</a>
          <!-- <a class="waves-effect waves-light btn col s12 blue" (click)="sendEmail()"><i
          class="material-icons white-text">email</i> enviar por e-mail</a> -->
          <a class="waves-effect waves-light btn col s12 red" (click)="cancelVerified()">fechar</a>
        </div>
      </div>
    </div>
  </div>
</div>




<!-- Modal QRCODE -->
<div id="modal-qrcode" class="modal bottom-sheet confirmpanel">
  <div class="modal-content">
    <h5 [style.font-family]="ws.website.font.family"  [style.color]="ws.website.color1" class="center">CONFIRMAR PAGAMENTO</h5>
    <span [hidden]="verified_device" class="grey-text col s12 ">Confirme a compra de seus pontos pagando
      através do QR code ou copiando e colando o código abaixo. Os pontos serão creditados em até 48h. Agradecemos pela sua transação!</span>

      <div class="qrcode">
        <img src="{{transac?.privatedata?.brcode?.pictureUrl}}" alt="">
      </div>
      <div class="codigo row">
        <input type="hidden" #inputText value="{{transac?.privatedata?.brcode?.id}}">
        <!-- <div class="col s1"><i (click)="copyToClipboard(inputText)" [style.color]="ws.website.color1" class="material-icons link">content_copy</i></div> -->
        <div class="col s10 small"> {{transac?.privatedata?.brcode?.id}} </div>
        
      </div>
  </div>
  <div class="modal-footer">
    <a class="waves-effect waves-light btn col s8 push-s2 red modal-close" >fechar</a>
  </div>
</div>