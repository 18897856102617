import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MaterializeService } from '@ollieestudio/fire-lib';
import { ConfirmService } from 'src/app/services/confirm.service';
import { WebsiteService } from 'src/app/services/di/website.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent implements OnInit {
public postagem:any;
public viewed:any[] = [];

  constructor(public ws:WebsiteService, public material:MaterializeService) { }

  ngOnInit() {
    this.material.initModal('#modal-popup', {dismissible:true});
  }

  async open(postagem:any){
    if(!this.viewed.includes(postagem)){
      this.postagem = postagem;
      this.material.openModal('#modal-popup');
      this.viewed.push(postagem)
      await this.material.delay(10000);
      this.material.closeModal("#modal-popup");
      document.body.style.overflow = '';
    }
  }
}
