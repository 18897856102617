import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-messagebox',
  templateUrl: './messagebox.component.html',
  styleUrls: ['./messagebox.component.css']
})
export class MessageboxComponent implements OnInit {
  hidemessages:boolean;
  close:boolean;
  messages:any[];

  constructor() { }

  ngOnInit() {
  }

}
