import { Component, OnInit, Input } from '@angular/core';
import { Section } from "src/app/services/interfaces/section";
import { DomSanitizer } from '@angular/platform-browser';
import { HeaderComponent } from '../header/header.component';
import { Website } from 'src/app/services/interfaces/website';
import { WebsiteService } from 'src/app/services/di/website.service';

@Component({
  selector: 'app-icon-section',
  templateUrl: './iconset.component.html',
  styleUrls: ['./iconset.component.css']
})
export class IconsetComponent extends HeaderComponent implements OnInit  {
  @Input()  section:Section = {} as Section;
  @Input() website:Website = {} as Website;
  
  constructor(public sanitizer:DomSanitizer, public ws:WebsiteService) {
    super(sanitizer, ws);
  }

  ngOnInit() {
  }

  sanitizeSection(url:string){
    return this.sanitizer.bypassSecurityTrustStyle(`url(${url})`);
  }

}
