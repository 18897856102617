import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { Website } from 'src/app/services/interfaces/website';
import { WebsiteService } from 'src/app/services/di/website.service';
import { AuthService, MaterializeService, DatabaseService } from '@ollieestudio/fire-lib';
import { Premiado } from 'src/app/services/interfaces/premiado';
import { ConfirmService } from 'src/app/services/confirm.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { PopupComponent } from '../shared/popup/popup.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  @ViewChild('popup', { static: true }) popup:PopupComponent 
  public website: Website;
  public postagem:any;
  public hidemessages:boolean = true;
  hideBot:boolean = true;

  constructor(public ws:WebsiteService, public analytics:AnalyticsService, public router:Router, public database: DatabaseService < Premiado >, public auth:AuthService<Premiado>, public material:MaterializeService, public confirm:ConfirmService) { }

  ngOnInit() {
    this.website = this.ws.website;
    
    this.ws.popupEmitter.subscribe(popups =>{
      if(this.ws.popups && !this.postagem){
        this.postagem = popups.filter(item => {return item.gatilho === 'start'})[0];
        if(this.postagem){
          this.popup.open(this.postagem);
          this.postagem = null;
        }
      }
    })

    if(this.ws.popups && !this.postagem){
      this.postagem = this.ws.popups.filter(item => {return item.gatilho === 'start'})[0];
      if(this.postagem){
        this.popup.open(this.postagem);
        this.postagem = null;
      }
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
          this.analytics.setCurrentScreen(event.url);
          if(this.ws.popups && !this.postagem){
            let path = event.url.replace('/home/', '');
            this.postagem = this.ws.popups.filter(item => {return item.gatilho === path})[0];
            if(this.postagem){
              this.popup.open(this.postagem);
              this.postagem = null;
            }
          }
        }
    });
  }




  showBot(){
    this.hideBot = false;
  }
  
  hideBot_(){
    this.hideBot = true;
  }

}
