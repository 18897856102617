<div class="row holder">
  <!-- <ul> -->
  <!-- <li > -->
  <div [hidden]="shouldHideCard(card)" *ngFor="let card of giftcards | filter:searchStr | orderBy:['-parceiro'] " [ngxScrollTo]="'#formnewcard'" class="col s12 m4 ">
    <div class="card epaycard white valign-wrapper " (click)="selectCard(card)">
      <!-- <img src="{{selectedloja.logo}}" class="iconparceiro" /> -->
      <div class="col s4 m4 valign">
        <img src="{{card.img}}" class="responsive-img " loading="lazy" />
      </div>
      <div class="col s8 m8 valign ">
        <h6 class="left-align col s12 grey-text text-darken-3">{{card.modelo | shorten:60}}</h6>
        <h4 [style.font-family]="ws.website.font.family" class="black-text col m12 s12 right-align displayvalor">
          {{card.valor}}<span
            style="font-size:0.85rem; margin-left:8px; vertical-align:super">{{campanha.moeda}}</span></h4>
            <a class="btn-flat right" [style.color]="ws.website.color1" > ver mais</a>
      </div>
    </div>
  </div>
</div>