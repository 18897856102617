import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { DatabaseService, AuthService, MaterializeService } from '@ollieestudio/fire-lib';
import { Bankinfo } from 'src/app/services/interfaces/bankinfo';
import { Premiado } from 'src/app/services/interfaces/premiado';
import { QueryFn } from '@angular/fire/compat/firestore';
import { Transaction } from 'src/app/services/interfaces/transaction';
import { Status } from 'src/app/services/enum/status.enum';
import { Tipo } from 'src/app/services/enum/tipo.enum';
import { WebsiteService } from 'src/app/services/di/website.service';
import { Service } from 'src/app/services/interfaces/service';
import { InputvalorComponent } from '../../shared/inputvalor/inputvalor.component';
import { ConfirmService } from 'src/app/services/confirm.service';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-bank-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.css']
})
export class BankFavoritesComponent implements OnInit {
  @ViewChild(InputvalorComponent, { static: false }) valor:InputvalorComponent;
  @Input() serviceType:Tipo;
  service:Service;
  myaccounts: any[] | Bankinfo[];
  selectedAccount: Bankinfo;
  showform: boolean;
  taxmodel: number;
  
  constructor(public analytics:AnalyticsService, private scrollService: ScrollToService, public confirm:ConfirmService, public material:MaterializeService, public database:DatabaseService<Bankinfo>,  public auth:AuthService<Premiado>, public ws:WebsiteService) { 
  }
  
  ngOnInit() {
    this.service = {...this.ws.services.filter(service => service.type === this.serviceType)[0] , ...this.ws.campanha.services[this.serviceType]};
  
    let sub = this.auth.stateObservable.subscribe(result =>{
      this.getAccounts(this.auth.User.authid);
    })

    if(this.auth.User && !this.myaccounts){
      this.getAccounts(this.auth.User.authid)
    }

    switch (this.serviceType) {
      case 'online_transf': this.taxmodel =  this.service.tax; break;
      case 'digital_transf': this.taxmodel =  0; break;
      case 'online_pix': this.taxmodel = this.service.tax; break;
    }
  }

  getAccounts(id){
    let query:QueryFn = ref => ref.where('authid', '==', id).where('tipo', '==', this.serviceType).where('usuario', '==', this.auth.User.id); 
    this.database.queryValues('ACCOUNTS', query).subscribe(accounts =>{
      this.myaccounts = accounts;
       window.scrollTo(0,0);
      })
  }


  selectAccount(account:Bankinfo){
    if(account.status === 'ATIVO'){
      this.selectedAccount = account;
    this.confirm.resultEmitter.subscribe(result =>{
      if(result === true){
        this.material.toast(this.confirm.generateCheckMessage('Sua transferência foi enviada!'), 5000, 'green full');
        this.resetTransaction();
        this.analytics.logEvent('banktransfer_complete', {userid: this.auth.User.id});
      }else{
        this.material.toast(this.confirm.generateErrorMessage("Sua transferência não foi processada!"), 5000, 'red full');
        this.analytics.logEvent('banktransfer_error', {userid: this.auth.User.id});
      }
    })
  }else{
    alert('Esta conta ainda não foi validada!');
    this.getAccounts(this.auth.User.authid);
  }
  }

  deleteAccount(account){
    if(confirm('Confirma a exclusão da conta ' + account.apelido + '?')){
      account.tipo ='EXCLUDE';
      this.database.update(account, 'ACCOUNTS').then(res =>{
        this.resetTransaction();
        this.getAccounts(this.auth.User.authid);
      });
    }
  }

  resetTransaction(){
    this.showform = true;
    this.selectedAccount = null;
    this.scrollService.scrollTo({target:'#topbank'});
  }

  processTransaction(){
    let transac:Transaction = {} as Transaction;
    transac.enterprise = this.ws.website.enterprise;
    transac.cliente = this.ws.website.cliente;
    transac.campanha = this.ws.campanha.id;

    // transac.geo = this.geo.location;
    transac.identificador = this.service.title;
    transac.status = Status['novo'];
    transac.tipo = this.serviceType;
    transac.usuario = this.auth.User.id;
    transac.authid = this.auth.User.authid;
    transac.valorout = (this.valor.valor) + this.valor.taxmodel;
    transac.privatedata = {};
    transac.privatedata['account'] = this.selectedAccount;
    transac.privatedata['valor'] = this.valor.valor ;
    
    this.confirm.openConfirm(transac);
  }

}
