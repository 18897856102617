

    <form id="loginform" (submit)="login()" [formGroup]="formLogin" class="col s11 m5 valign centered">
       <div class="row">
        <div class="input-field col s12">
          <i class="white-text material-icons prefix">account_circle</i>
          <input id="username" name="username" type="text" class="white-text" [formControlName]="'username'" />
          <label class="white-text" for="username">Email</label>
        </div>
      </div>
      <div class="row">
        <div class="input-field col s12">
          <i class="white-text material-icons prefix">lock</i>
          <input id="loginpassword" name="password"  [type]="showPassword ? 'text' : 'password'" class=" white-text" [formControlName]="'password'" [autocomplete]="'current-password'"  />
          <i class="grey-text material-icons link suffix" *ngIf="!showPassword" (click)="showPassword = !showPassword">visibility</i>
          <i class="grey-text material-icons link suffix" *ngIf="showPassword" (click)="showPassword = !showPassword">visibility_off</i>
          <label class="white-text" for="loginpassword">Senha</label>
        </div>
      </div>
      <!-- <div id="recaptcha-container" class="center"></div> -->
      <div class="row">
          <button type="submit" [disabled]="formLogin.invalid || !captchaResolved" class="waves-effect waves-light btn col m5 s12 customcolor1 right" id="bt-login"  [style.backgroundColor]="ws.website?.color1">FAZER LOGIN</button>
          <a [ngxScrollTo]="'#register'" class="waves-effect waves-light btn-flat col m5 s12 right white-text"  id="btn-register" >PRIMEIRO ACESSO</a>
      </div>
      
      <div class="row ">
        <a  id="btn-recover" href="" routerLink="../../institucional/restore" class="white-text right col m5 s12 right tiny"> ESQUECEU A SENHA?</a>
        </div>
 
    </form>

  