import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { WebsiteService } from 'src/app/services/di/website.service';
import { MaterializeService } from '@ollieestudio/fire-lib';
import html2pdf from 'html2pdf.js';
import { Observable, map, mergeMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Transaction } from 'src/app/services/interfaces/transaction';
import { Campanha } from 'src/app/services/interfaces/campanha';
import { Website } from 'src/app/services/interfaces/website';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-printvouchers',
  standalone:true,
  imports:[CommonModule],
  templateUrl: './printvouchers.component.html',
  styleUrls: ['./printvouchers.component.css']
})

export class PrintvouchersComponent implements OnInit {
  @ViewChild("page", {static:true}) page!: ElementRef;
  vouchers:Voucher[];
  base64Image: string;

  constructor(private ws:WebsiteService, private http: HttpClient,  private material:MaterializeService) {
   }

  ngOnInit(): void {
  }

 
  async printVoucher(idpedido:string, transactions?:Transaction[]){
    const layout = await this.ws.website;
    const campanha = await this.ws.campanha;
    const identificador = transactions[0].identificador;

    await this.printVouchers(transactions, layout, campanha, identificador);
  }

  private async printVouchers(transactions: Transaction[], layout: Website, campanha: Campanha, identificador: string) {
    this.vouchers = [];
    if(!transactions.length) return;
    
    transactions.forEach(transac => {
      let voucher: Voucher = {} as Voucher;
      voucher.codigo = transac.id.match(/.{1,4}/g).join(' ');
      voucher.valor = transac.valorin;
      voucher.expire = transac.privatedata?.expire;
      voucher.color1 = layout.color1;
      voucher.color2 = layout.color2;
      voucher.logo = layout.logoBase64;
      voucher.moeda = campanha.moeda;
      voucher.url = layout.url;
      voucher.title = layout.title;
      this.vouchers = [voucher];
    });
    await this.material.delay(500);

    this.material.toast(`Exportando arquivo, aguarde.`, 5000, 'green full');
    const content = this.page.nativeElement;
    await this.material.delay(500);

    var opt = {
      margin: [0, 0, 0, 0],
      image: { type: 'jpeg', quality: 0.20 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: 'cm', format: [9, 21], orientation: 'l' }
    };

    await html2pdf().set(opt).from(content.innerHTML).save(`vouchers-${identificador}`);
  }

  getImageBase64(url: string): Observable<string> {
    return this.http.get(url, { responseType: 'blob' }).pipe(
      map((blob: Blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Observable<string>((observer) => {
          reader.onloadend = () => {
            observer.next(reader.result as string);
            observer.complete();
          };
          reader.onerror = (error) => observer.error(error);
        });
      }),
      mergeMap((obs) => obs)
    );
  }

}

export interface Voucher {
  title: string;
  expire: number;
  valor: number;
  moeda: string;
  color1:string;
  color2:string;
  logo:string | ArrayBuffer;
  url:string;
  codigo:string;
}