import { Component, OnInit, ViewChild } from '@angular/core';
import { WebsiteService } from 'src/app/services/di/website.service';
import { Website } from 'src/app/services/interfaces/website';
import * as firebase from 'firebase/compat/app';
import { PopupComponent } from '../shared/popup/popup.component';

var grecaptcha: any;

@Component({
  selector: 'app-institucional',
  templateUrl: './institucional.component.html',
  styleUrls: ['./institucional.component.css']
})

export class InstitucionalComponent implements OnInit {
  @ViewChild('popup', { static: true }) popup:PopupComponent 
  public website: Website;
  hideBot:boolean = true;
  public captchaResolved:boolean = false;
  postagem: any;


  constructor(public ws:WebsiteService) { }

  ngOnInit() {
    // this.captcha();
    let sub = this.ws.websiteEmitter.subscribe(website => {
      sub.unsubscribe();
      if(!this.website) {
        this.website = this.ws.website
        }
      })
    
    this.ws.popupEmitter.subscribe(popups =>{
        if(this.ws.popups && !this.postagem){
          this.postagem = popups.filter(item => {return item.gatilho === 'institucional'})[0];
          if(this.postagem){
            this.popup.open(this.postagem);
            this.postagem = null;
          }
        }
      })
  }

  captcha(){
    grecaptcha = new firebase.default.auth.RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        this.captchaResolved = true;
      },
      'expired-callback': () => {
        // Response expired. Ask user to solve reCAPTCHA again.
        this.captchaResolved = false;
        this.captcha();

      }
    });
    grecaptcha.render().then(()=> grecaptcha.verify());
   }



  showBot(){
    this.hideBot = false;
  }
  
  hideBot_(){
    this.hideBot = true;
  }

}
