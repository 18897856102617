import { Component, OnInit, Input, ViewChild, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { InputBase, FormService, MaskService, AuthService, GeolocationService, DatabaseService, MaterializeService } from '@ollieestudio/fire-lib';
import { Website } from 'src/app/services/interfaces/website';
import { WebsiteService } from 'src/app/services/di/website.service';
import { Card } from 'src/app/services/interfaces/card';
import { Transaction } from 'src/app/services/interfaces/transaction';
import { Endereco } from 'src/app/services/interfaces/endereco';
import { AddressformComponent } from '../../dados/addressform/addressform.component';
import { Service } from 'src/app/services/interfaces/service';
import { Status } from 'src/app/services/enum/status.enum';
import { Premiado } from 'src/app/services/interfaces/premiado';
import { InputvalorComponent } from '../../shared/inputvalor/inputvalor.component';
import { Tipo } from 'src/app/services/enum/tipo.enum';
import { ConfirmService } from 'src/app/services/confirm.service';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { EventEmitter } from '@angular/core';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-cards-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class CardsFormComponent implements OnInit {
  @Output() hideFormEmitter:EventEmitter<any> = new EventEmitter();
  public servicetype:string = "online_cardemiss";
  public service:Service;
  public formNovoCartao:FormGroup;
  public cards:any[];
  public selectedCard: Card;
  @ViewChild(AddressformComponent, { static: false }) address:AddressformComponent;
  @ViewChild(InputvalorComponent, { static: false }) valor:InputvalorComponent;
  public endereco:Endereco;
  
  private fields:InputBase[] = [
    {key:"nome", required:true},
    {key:"documento", required:true}
  ]

  constructor(public analytics:AnalyticsService, private scrollService: ScrollToService, public confirm:ConfirmService, public material:MaterializeService, public forms:FormService,  public ws:WebsiteService, public masks:MaskService, public auth:AuthService<Premiado>) {
    this.formNovoCartao = this.forms.toFormGroup(this.fields)
   }

  ngOnInit() {
    this.cards = this.ws.cards;
    this.service = {...this.ws.services.filter(service => service.type === this.servicetype)[0], ...this.ws.campanha.services[ this.servicetype]};
    this.formNovoCartao.controls['documento'].setValue(this.auth.User.documento);
    this.formNovoCartao.controls['nome'].setValue(this.auth.User.nome);
  }

  checkAddress(address:Endereco){
    return address?.bairro && address?.numero && address?.responsavel && address?.cep
  }
  
  selectCard(card){
      this.selectedCard = card;
      this.confirm.resultEmitter.subscribe(result =>{
        if(result === true){
          this.material.toast(this.confirm.generateCheckMessage("Seu novo cartão foi solicitado com sucesso!"), 5000, 'green full');
          this.analytics.logEvent('newcard_complete', {userid: this.auth.User.id});
          this.hideFormEmitter.next(true);
        }else{
          this.material.toast(this.confirm.generateErrorMessage("Sua solicitação não foi processada!"), 5000, 'red full');
          this.analytics.logEvent('newcard_error', {userid: this.auth.User.id});
        }
      })
  }

  resetTransaction(){
    this.hideFormEmitter.next(true);
    this.scrollService.scrollTo({target:'#topcards'});
  }

  processTransaction(){
    let transac:Transaction = {} as Transaction;
    transac.enterprise = this.ws.website.enterprise;
    transac.cliente = this.ws.website.cliente;
    transac.campanha = this.ws.campanha.id;

    // transac.geo = this.geo.location;
    transac.identificador = 'Emissão ' + this.service.title;
    transac.status = Status['novo'];
    transac.tipo = Tipo['card-emiss'];
    transac.usuario = this.auth.User.id;
    transac.authid = this.auth.User.authid;
    transac.valorout = Math.ceil(this.service.tax);
    transac.privatedata = {};
    transac.privatedata['card'] = this.selectedCard;
    transac.privatedata['address'] = this.address.formAddress.getRawValue();
    transac.privatedata['portador'] = this.auth.User.nome;
    transac.privatedata['documento'] = this.auth.User.documento;
    transac.privatedata['usuario'] = this.auth.User.id;
    transac.privatedata['identificador'] = this.selectedCard.identificador;

    this.confirm.openConfirm(transac);

  }

}
