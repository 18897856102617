<div id="messagebox" class="card z-depth-3 white scale-transition scale-in" ng-hide="close">
    <div class="col s12 z-depth-1 card-title customcolor1 fixed">
      <i (click)="close = true;" class="white-text material-icons right link">close</i>
    </div>
    
    <ul id="messagepanel" class="col s12">
    
    
        <li class="card row s12" *ngFor="let message of messages " ng-hide="message.status === 'delete'" >
          <div class="col s12 z-depth-1 card-title" ng-class="{'green': message.priority === 'low', 'red': message.priority === 'high', 'blue': message.priority === 'medium'}">
            <i class="white-text material-icons right link">mail</i>
          </div>
          <div class="card-content avatar">
          <img src="{{message.icon}}" alt="" class="circle">
          <span class="title">{{message.subject}}</span>
          <p style="font-size: 12px;" class="col s12">{{message.text}} </p>
          <a (click)="goToAction(message, $index)" class="secondary-content"><i class="material-icons link flip customcolor1">reply</i></a>
          <i (click)="deleteMessage(message, $index)" class="customcolor1 material-icons right link">delete</i>
        </div>
        </li>
    
    
      </ul>
    </div>
    