<div class="toppad">
  &nbsp;
</div>
<div class="container">
  <app-help-video></app-help-video>
  <video *ngIf="videostart" id="lgpdvideo" class="lgpd center" src="https://firebasestorage.googleapis.com/v0/b/smartpontos-develop.appspot.com/o/lgpd_mov.mp4?alt=media&token=ae717543-cd9e-498f-b6b7-d44a7d6f29d2" poster="img/vframe.jpg" autoplay></video>
  <div class="row center-align">
    <a (click)="mostraRegulamento()"  [style.backgroundColor]="ws.website?.color1" class="waves-effect waves-light btn col m4 push-m2 s12 customcolor1 center" id="bt-show-regulamento"  >regulamento da campanha</a>
    <a (click)="mostraVideo()"  [style.backgroundColor]="ws.website?.color1" class="waves-effect waves-light btn col m4 push-m2  s12 customcolor1 center" id="bt-show-regulamento"  >como protegemos seus dados</a>
      
  </div>
  <app-faq></app-faq>
  <!-- <app-chatbot *ngIf="!hideBot" (closer)="hideBot_($event)" class="chatbot" width="350" height="430"></app-chatbot>
  <app-helpdesk *ngIf="!hideBot" (closer)="hideBot_($event)" class="chatbot" width="350" height="430"></app-helpdesk>
  <app-chatbutton (click)="showBot()" [hidden]="!hideBot"></app-chatbutton> -->
  <app-regulamento ></app-regulamento>
    
</div>