import { Component, OnInit, Input } from '@angular/core';
import { Service } from 'src/app/services/interfaces/service';
import { AuthService } from '@ollieestudio/fire-lib';
import { Campanha } from 'src/app/services/interfaces/campanha';
import { Premiado } from 'src/app/services/interfaces/premiado';
import { WebsiteService } from 'src/app/services/di/website.service';
import { Tipo } from 'src/app/services/enum/tipo.enum';

@Component({
  selector: 'app-inputvalor',
  templateUrl: './inputvalor.component.html',
  styleUrls: ['./inputvalor.component.css']
})
export class InputvalorComponent implements OnInit {
  
  @Input() taxmodel:number;
  @Input() emissaotax:number;
  @Input() valor:number;
  @Input() service:Service;
  @Input() isemissao:boolean;
  @Input() editable:boolean;
  @Input() floatingtax:boolean | Number;
  max:number = 0;
  min:number = 0;
 
  constructor(public auth:AuthService<Premiado>, public ws:WebsiteService ) { }

  ngOnInit() {
      if(this.service.type != Tipo['compra-pontos']){
          this.max = Math.min(this.service.limite - this.service.tax, Math.max( 0, (this.auth.User.saldo / this.ws.campanha.conversion) - this.service.tax));
          if(!this.floatingtax) this.valor = this.min = 0;
          if(this.floatingtax) this.checkAmount(null);
      }else{
        this.min = 50;
        if(this.service.limite > 0) this.max =  this.service.limite;
      }
    }

  checkAmount = function(e?) {
    let amt = parseFloat(e?.target?.value );
    if(e == null) amt = this.valor || this.max;
    
    if (this.floatingtax) {
      this.taxmodel = Math.min(7, Math.ceil((amt * this.floatingtax) + this.service.tax)) 
      this.max = Math.min(this.service.limite, Math.max( 0, (this.auth.User.saldo / this.ws.campanha.conversion) - this.taxmodel));
    }else{
      this.taxmodel = parseFloat(this.service.tax);
    }
    
    if (this.isemissao) {
      this.valor = Math.min(this.service.tax, this.max);
    }else{
      this.valor = Math.min(amt, this.max);
    }
    
    
      // if (amt > this.max) {
      //   this.valor = this.max;
      // } 
    
      // if (amt < this.min) {
      //   this.valor = this.min;
      // } 

      e?.preventDefault();
  };

  reset() {
    this.valor = 0;
    this.taxmodel = 0;
  }

}
