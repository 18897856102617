<div id="formcontainer" class="editcontainer col s12 m8">
  <form name="formVoucher" [formGroup]="formVoucher" action="" class="col s12">
    <h4 [style.font-family]="ws.website.font.family" class="white-text">NOVO VOUCHER</h4>

    <div class="row">
      <div class="input-field col s11 l12">
        <input required  [textMask]="{mask:maskVoucher, guide:false}" [attr.maxlength]="19" [attr.minlength]="19" id="codigo" name="codigo" type="text"
          class="validate white-text" (keyup)="checkCodigo($event)" [formControlName]="'codigo'" />
        <label for="codigo" class="bactive white-text text-lighten-1">Código </label>
        <!-- <p class="tiny grey-text text-lighten-2" ng-show="!formPayment.$pristine && formPayment.codigo.$invalid">
            <i class="material-icons yellow-text">report_problem</i>
            Digite, cole ou fotografe o código de seu voucher.</p> -->
      </div>

    </div>


    <div class="row">
      <div class="input-field col s6" [hidden]="true">
        <input required id="datavoucher" ng-readonly="true" name="datavoucher" type="text" class="validate white-text"
          [formControlName]="'datavoucher'" />
        <label for="datavoucher" class="active white-text text-lighten-1">Validade</label>

      </div>
      <div class="input-field col s12 m5">
        <input required id="valorvoucher" [readonly]="true" name="valorvoucher" type="text" class="validate white-text"
          [formControlName]="'valor'" />
        <label for="valorvoucher" class="active white-text text-lighten-1">Valor</label>
      </div>
      <div class="col s12 m7">
        <button [disabled]="formVoucher.invalid" [style.backgroundColor]="ws.website?.color1"
          class="waves-effect waves-light btn col m5 s12 right" id="btn-send-voucher"
          (click)="processRecarga()">enviar</button>
        <a [style.backgroundColor]="ws.website?.color1" class="waves-effect waves-light btn col m5 s12 right"
          id="bt-cancel-voucher" (click)="resetTransaction()">limpar</a>
      </div>
      <div class="col s12" [hidden]="!erros?.length">
        <i class="material-icons yellow-text col s1" >report_problem</i>
        <span class="white-text erro"> {{erros[0]}} </span>
      </div>
    </div>
  </form>
</div>