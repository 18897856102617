<div id="topbank" class="toppad">
  &nbsp;
</div>
<div class="container top-5">
  <div class="row" *ngIf="service">
    
    <h3 [style.font-family]="ws.website.font.family"  class="col s12 m11 white-text upper"><i
        class="material-icons">{{service.icon}}</i>&nbsp;{{service.title}}
    </h3>
  </div>
    <div class="row">
      
      <div class="col s12 m11 instrucoes">
        <h5 [style.font-family]="ws.website.font.family"  class="white-text">INSTRUÇÕES</h5>
        <ul class="browser-default" *ngIf="!vouchers.length">
          <li><span class="white-text">Digite abaixo código numérico de seu voucher.</span></li>
          <li><span class="white-text">Se o voucher estiver válido a premiação será liberada imediatamente.</span></li>
          <li><span class="white-text">Cada voucher poderá ser usado somente uma vez.</span></li>
          <li><span class="white-text">Não nos responsabilizamos pela perda do voucher. </span></li>
          <li><span class="white-text">Após resgatar seu voucher, selecione uma das opções do site para resgatar seu
              prêmio.</span></li>
        </ul>

        <ul class="browser-default" *ngIf="vouchers.length">
          <li><span class="white-text">Selecione na lista abaixo o voucher que deseja TRANSFERIR ou RESGATAR.</span></li>
          <li><span class="white-text">Se deseja TRANSFERIR o voucher, informe o email do destinatário ou faça o DOWNLOAD.</span></li>
          <li><span class="white-text">Se RESGATAR o voucher, a premiação será adicionada imediatamente ao seu SALDO.</span></li>
          <li><span class="white-text">Cada voucher poderá ser usado somente uma vez.</span></li>
          <li><span class="white-text">Não nos responsabilizamos pela perda do voucher. </span></li>
          <li><span class="white-text">Após resgatar seu voucher, selecione uma das opções do site para resgatar seu
              prêmio.</span></li>
        </ul>
      </div>
    </div>
    <!-- <div class="row" *ngIf="vouchers.length">
      <button class="btn col m3 s12" [ngClass]="{'grey':showLista}" [style.backgroundColor]="ws.website?.color1" (click)="toggleShowlista(false)"><i class="material-icons">confirmation_number</i> resgatar voucher</button>
      <button class="btn col m3 s12" [ngClass]="{'grey':!showLista}" [style.backgroundColor]="ws.website?.color1" (click)="toggleShowlista(true)"><i class="material-icons">list</i> seus vouchers</button>

    </div> -->

    <div class="row" *ngIf="!vouchers.length">
      <app-voucher-form (redeem)="processRecarga($event)" ></app-voucher-form>
    </div>
    <div class="row"  *ngIf="vouchers.length">
      <app-lista-vouchers (redeem)="processRecarga($event)" [extrato]="vouchers"></app-lista-vouchers>
    </div>
  </div>

  <app-confirmpanel source="transaction" visible="showConfirmForm" ></app-confirmpanel>