import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imageUrl'
})
export class ImageUrlPipe implements PipeTransform {
  transform(url: string, width: number, height: number): string {
    // Replace {w} with the width and {h} with the height
    return url.replace('{w}', width.toString()).replace('{h}', height.toString());
  }
}
