export enum Tipo {
   'card-transf'='online_cardtransf',
   'bank-transf'='online_transf',
   'sales_input'='sales_input',
   'compra-pontos'='online_getpoints',
   'digital-transf'='digital_transf',
   'card-emiss'='online_cardemiss',
   'mobile'='online_mobile',
   'payment'='online_payment',
   'giftcard'='online_valepresente',
   'salesinput'='online_salesinput',
   'shop'='online_catalogo',
   'recarga' = 'online_recarga',
   'saldo-update' = 'saldo',
   'transac-erro' = 'erro',
   'transac-sucesso' = 'sucesso',
   'transac-recarga' = 'recarga',
   'premiado' = 'PREMIADO',
   'notification' = 'notification',
   'addtohome' = 'addtohome',
   'pix-online' = 'online_pix',
   'voucher' = 'online_voucher',
   'qrcodepay' = 'online_qrcodepay',
   'help' = 'helpdesk',
   'regulamento' = 'regulamento',
}

