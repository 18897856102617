import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class firebaseConfig {
 
    apiKey: "AIzaSyBuCt70s9w2aYUhVFoC7aIxl7-ZUiENZbU";
    authDomain: "smartpontos-prod.firebaseapp.com";
    databaseURL: "https://smartpontos-prod.firebaseio.com";
    projectId: "smartpontos-prod";
    storageBucket: "";
    messagingSenderId: "794619822389";
    appId: "1:794619822389:web:a2ec4aa0c1f05af217cc7c";
    measurementId: "G-H6PYZFK23W"
  
  constructor() { 
    
  }

  authConfig(){
    return {
      apiKey: "AIzaSyBuCt70s9w2aYUhVFoC7aIxl7-ZUiENZbU",
      authDomain: "smartpontos-prod.firebaseapp.com",
      databaseURL: "https://smartpontos-prod.firebaseio.com",
      projectId: "smartpontos-prod",
      storageBucket: "",
      messagingSenderId: "794619822389",
      appId: "1:794619822389:web:a2ec4aa0c1f05af217cc7c",
      measurementId: "G-H6PYZFK23W"
    }
  }
}
