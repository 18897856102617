import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthService, DatabaseService, FormService, GeolocationService, InputBase, MaskService, MaterializeService } from '@ollieestudio/fire-lib';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ConfirmService } from 'src/app/services/confirm.service';
import { Venda } from 'src/app/services/interfaces/venda';
import { WebsiteService } from 'src/app/services/di/website.service';
import { Status } from 'src/app/services/enum/status.enum';
import { Tipo } from 'src/app/services/enum/tipo.enum';
import { Premiado } from 'src/app/services/interfaces/premiado';
import { QueryFn } from '@angular/fire/compat/firestore';


@Component({
  selector: 'app-sales-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormSalesComponent implements OnInit {
  public formSales:FormGroup;
  private fields:InputBase[] = [
     {key:"valor", required:true},
     {key:"produto", required:true},
     {key:"codigo_loja", required:false},
     {key:"data", required:true},
     {key:"pedido", required:true},
    //  {key:"tipoconta", required:true},
    //  {key:"datavencto", required:true},
    //  {key:"datapagto", required:true}
   ]
  produtos: any[];

   constructor(public analytics:AnalyticsService, public confirm:ConfirmService, public database:DatabaseService<Venda>, public datepipe: DatePipe, public material:MaterializeService, public forms:FormService,  public ws:WebsiteService, public masks:MaskService, public auth:AuthService<Premiado>) {

    this.formSales = this.forms.toFormGroup(this.fields)

   }

  ngOnInit(): void {
    this.getProdutos();
  }

  getProdutos(){
    let query: QueryFn = ref => ref.where('ativo', '==', 'SIM');
    
    this.database.queryValues('CATALOGO/' + this.ws.website.cliente + '/PRODUTOS', query).subscribe(result => {
      this.produtos = result;
    })
  }

  selectProduto(event:Event){
    this.formSales.controls['valor'].setValue(JSON.parse(this.formSales.controls['produto'].value).valor);
  }

  processvendation(){
    let venda = this.formSales.getRawValue();
    venda.enterprise = this.ws.website.enterprise;
    venda.cliente = this.ws.website.cliente;
    // venda.geo = this.geo.location;
    venda.status = Status['pendente'];
    venda.tipo = Tipo['sales_input'];
    venda.usuario = this.auth.User.id;
    venda.premiado = this.auth.User.nome;
    venda.documento = this.auth.User.documento;
    venda.authid = this.auth.User.authid;
    venda.campanha = this.ws.campanha.id;
    venda.nomecampanha = this.ws.campanha.identificador;
    venda.produto = JSON.parse(venda.produto);
    venda.cliente = this.ws.campanha.cliente;
    this.database.add(venda, 'SALES').then(result =>{
      this.material.toast(this.confirm.generateCheckMessage("Venda cadastrada com sucesso!"), 5000, 'green full');
      this.formSales.reset();
    }).catch(error => console.log(error))
  }

}
