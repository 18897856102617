<div class="row updates-wrapper">
   <div class="updates">
    <div class="col s12 m3 update" *ngFor="let update of updates" id="update{{$index}}">
      <div class="card-panel white" (click)="callToAction(update)">
      <div class="btn-floating lighten-2 icon"  [style.backgroundColor]="ws.website?.color1" >
        <i class="material-icons white-text" *ngIf="update.tipo === 'saldo'">attach_money</i>
        <i class="material-icons white-text" *ngIf="update.tipo === 'erro'">priority_high</i>
        <i class="material-icons white-text" *ngIf="update.tipo === 'sucesso'">done</i>
        <i class="material-icons white-text" *ngIf="update.tipo === 'recarga'">sentiment_satisfied_alt</i>
        <i class="material-icons white-text" *ngIf="update.tipo === 'notification'">mail</i>
        <i class="material-icons white-text" *ngIf="update.tipo === 'addtohome'">home</i>
        <i class="material-icons white-text" *ngIf="update.tipo === 'helpdesk'">headset_mic</i>
        <i class="material-icons white-text" *ngIf="update.tipo === 'regulamento'">gavel</i>
      </div>
        <p class="bold orange-text title">{{update.titulo}}</p>
        <p class="grey-text text-darken-2">{{update.texto | shorten:60:"..."}}</p>
        <i *ngIf="update.tipo === 'helpdesk' && update.status === 'RESPONDIDA'" (click)="openModal(update.texto)" class="material-icons open" [style.color]="ws.website?.color1">open_in_new</i>
      </div>
      <div id="modal-update" class="modal " >
        <div class="modal-content" *ngIf="update.tipo === 'helpdesk'" [innerHTML]="update.texto" >
        </div>
        </div>
    </div>
  </div>


</div>
