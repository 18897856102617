import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CookieService, MaterializeService } from '@ollieestudio/fire-lib';
import { WebsiteService } from 'src/app/services/di/website.service';

@Component({
  selector: 'app-chatbutton',
  templateUrl: './chatbutton.component.html',
  styleUrls: ['./chatbutton.component.css']
})
export class ChatbuttonComponent implements OnInit {

  hideBot:boolean = true;
  helpdeskEnabled: boolean = false;
  
  constructor(public ws:WebsiteService, public cookies:CookieService<any>, public material:MaterializeService ) { }

  ngOnInit() {
    this.checkCookies();
  }

  checkCookies() {
    let cookie = this.cookies.get('smarthelpdesk');
   return (!cookie.expires || cookie.expires < new Date().getTime())
  }

  showBot(){
    if(this.checkCookies()){
      this.hideBot = false;
    }else{
      this.material.toast(`Você já tem um chamado em atendimento!`, 5000, 'red full');
    }
  }
  
  hideBot_(){
    this.hideBot = true;
  }
}
