<div class="row s12">
    <div class="col m1 hide-on-med-and-down">
      &nbsp;
    </div>
    <div class="section col m11 s12">

      <div class="row">
        <div class="col s12 center">
          <h3 [style.font-family]="ws.website.font.family" ><i class="mdi-content-send brown-text"></i></h3>
          <br>
          <h4 [style.font-family]="ws.website.font.family"  class="white-text">Passo a Passo</h4>
          <p class="left-align white-text light">Veja no vídeo abaixo como é simples resgatar seu prêmio. Caso ainda tenha alguma dificuldade tire todas suas dúvidas consultando as Perguntas Frequentes. </p>
        </div>
      </div>
      <div class="row">
        <div class="col s6 center">
        </div>
        <div class="col s6 right">

        </div>
        <video src="https://firebasestorage.googleapis.com/v0/b/safeward-a4cf3.appspot.com/o/helpdesk%2Fsitepremios_2.mp4?alt=media&token=88c7b106-1058-4f73-97d7-c2e6935b5187" poster="img/vframe.jpg" controls></video><br>
      </div>
    </div>
  </div>
