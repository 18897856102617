<div id="topcards" class="toppad">
  &nbsp;
</div>
<div class="container top-5" >
  <div class="row" *ngIf="service">
    
    <h3 [style.font-family]="ws.website.font.family"  class="col s12 m11 white-text upper"><i
        class="material-icons">{{service.icon}}</i>&nbsp;{{service.title}}
    </h3>
  </div>
  <div class="row">
    
    <div class="col s12 m11 instrucoes">
      <h5 [style.font-family]="ws.website.font.family"  class="white-text">INSTRUÇÕES</h5>
      <ul class="browser-default">
        <li><span class="white-text">Para novos cartões o prazo de envio é de 5 a 15 dias úteis.</span></li>
        <li><span class="white-text">Novos cartões serão enviados sem carga de créditos.</span></li>
        <li><span class="white-text">Em caso de recarga tenha em mãos o cartão para confirmação de dados. </span></li>
        <li><span class="white-text">Fique atento às regras, prazos e valores de cada cartão. </span></li>
        <li><span class="white-text">Perda e roubo devem ser informados na Central de Atendimento do cartão. </span></li>
        <li><span class="white-text">Limite por solicitação: {{service.limite*ws.campanha.conversion}} {{ws.campanha.moeda}}</span></li>
      </ul>

    </div>
  </div>
  <div class="row">
    <app-cards-favorites  [service]="service" ></app-cards-favorites>
   
    <a class="waves-effect waves-light btn col m4 s12  center" id="bt-new" [style.backgroundColor]="website?.color1"
     (click)="enableCardForm()"   *ngIf="!showForm && !favorites.selectedCard">solicitar novo cartão</a>
    
     <div id="cardselect" class="padding-5">
       <app-cards-form [hidden]="!showForm" ></app-cards-form>
     </div>
  </div>
</div>

