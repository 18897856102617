import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Section } from "src/app/services/interfaces/section";
import { DomSanitizer } from '@angular/platform-browser';
import { SecurityContext } from '@angular/core';
import { Website } from 'src/app/services/interfaces/website';
import { WebsiteService } from 'src/app/services/di/website.service';

@Component({
  selector: 'app-header-section',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Input() section:Section;
  @Input() website:Website;
  @Output() public captchaEmitter:EventEmitter<boolean> = new EventEmitter();

  showLogin:boolean = false;
 
  constructor( public sanitizer: DomSanitizer , public ws:WebsiteService) { }

  ngOnInit() {
  }

  sanitizeSection(url:string){
    return this.sanitizer.bypassSecurityTrustStyle(`url(${url})`);
  }

  captcha(event){
    this.captchaEmitter.next(event);
  }

}
