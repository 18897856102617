import { Injectable, Output, EventEmitter } from '@angular/core';
import { Transaction } from './interfaces/transaction';
import { DatabaseService, AuthService, MaterializeService, CookieService } from '@ollieestudio/fire-lib';
import { Premiado } from './interfaces/premiado';
import { Tipo } from './enum/tipo.enum';

@Injectable({
  providedIn: 'root'
})
export class ConfirmService {
  generateCheckMessage(frase: string): string {
    return `<div class='lottiecontainer'><lottie-player src='https://assets1.lottiefiles.com/datafiles/OhIfcxnkLsj1Jxj/data.json'  background='transparent'  speed='0.7'  style='width: 200px; height: 200px; margin:0 auto'    autoplay ></lottie-player><br> ${frase}</div>`
  }
  generateAlertMessage(frase: string): string {
    return `<div class='lottiecontainer'> ${frase}</div>`
  }
  generateErrorMessage(frase: string): string {
    return `<div class='lottiecontainer'><lottie-player src='https://assets7.lottiefiles.com/temp/lf20_QYm9j9.json'  background='transparent'  speed='0.8'  style='width: 200px; height: 200px;margin:0 auto'  loop  autoplay ></lottie-player><br> ${frase}</div>`;
  }
  generateHelloMessage(frase: string): string {
    return `<div class='lottiecontainer'><lottie-player src='https://assets8.lottiefiles.com/packages/lf20_VZWGZs.json'  background='transparent'  speed='0.8'  style='width: 275px; height: 275px;margin:0 auto'  loop  autoplay ></lottie-player><br> ${frase}</div>`;
  }
  @Output() confirmEmitter:EventEmitter<Transaction> = new EventEmitter();
  @Output() resultEmitter:EventEmitter<boolean> = new EventEmitter();
  public transac:Transaction;

  constructor(public cookies:CookieService<any>, public database:DatabaseService<Transaction>, public auth:AuthService<Premiado>, public material:MaterializeService) { }

  openConfirm(transac:Transaction){
    this.transac = transac;
    if(transac.tipo != Tipo['compra-pontos']){
    if(this.auth.User.saldo >= transac.valorout && transac.valorout > 0){

      if(this.checkVerifiedDevice()){
        const userinfo = this.cookies.get('smartuserinfo');
        transac.validate_token = userinfo.idtoken;
        transac.device = this.checkVerifiedDevice();
      }

      this.database.add(transac, 'TRANSACTIONS').then(result =>{
        transac.id = result['id'];
        this.auth.User.saldo -= transac.valorout;
        this.confirmEmitter.emit(transac);
      })
    }else{
        if(!transac.valorout || transac.valorout <= 0){
          this.material.toast(this.generateErrorMessage("Valor inválido"), 5000, 'red full');
        }else{
          this.material.toast(this.generateErrorMessage("Seu saldo não é suficiente."), 5000, 'red full');
        }
      }
    }else{
      if(transac.valorin >= 50){
        this.database.add(transac, 'TRANSACTIONS').then(result =>{
          transac.id = result['id'];
          this.confirmEmitter.emit(transac);
        })
      }
    }
  }

  confirmUpdate(transac:Transaction){
    this.confirmEmitter.emit(transac);
  }

  checkVerifiedDevice() {
    const cookie = this.cookies.get('verified_device');
    return cookie?.id+cookie?.expires;
   }

}
