import { Injectable } from '@angular/core';
import { AuthService, DatabaseService } from '@ollieestudio/fire-lib';
import { Premiado } from './interfaces/premiado';
import { Device } from './interfaces/device';
import { WebsiteService } from './di/website.service';
import { Status } from './enum/status.enum';

@Injectable({
  providedIn: 'root'
})
export class DevicesService {

  constructor(private auth:AuthService<Premiado>, private ws:WebsiteService, private database:DatabaseService<Device>) { }

  saveNewDevice(id, expires){
    let device:Device = {} as Device;
    device.id = id+expires;
    device.expires = expires;
    device.enterprise = this.auth.User.enterprise;
    device.cliente = this.auth.User.cliente;
    device.campanha = this.ws.website.campanha;
    device.website = this.ws.website.id;
    device.usuario = this.auth.User.id;
    device.info =  window.navigator.userAgent;
    device.status = Status['ativo'];

    this.database.set(device, 'DEVICES')

   }
}
