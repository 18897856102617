import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-voucher-scan',
  templateUrl: './scan.component.html',
  styleUrls: ['./scan.component.css']
})
export class VoucherScanComponent implements OnInit {
photo:any;

  constructor() { }

  ngOnInit() {
  }

  getPhoto(photo:any){

  }

}
