import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DatabaseService, MaterializeService, FormService, MaskService, AuthService } from '@ollieestudio/fire-lib';
import { ConfirmService } from 'src/app/services/confirm.service';
import { WebsiteService } from 'src/app/services/di/website.service';
import { Premiado } from 'src/app/services/interfaces/premiado';
import { QueryFn } from '@angular/fire/compat/firestore';
import { Campanha } from 'src/app/services/interfaces/campanha';
import { Giftcard } from 'src/app/services/interfaces/giftcard';

@Component({
  selector: 'app-gift-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.css']
})
export class GiftCatalogComponent implements OnInit {
  giftcards: Giftcard[];
  campanha:Campanha;
  @Output() cardEmitter = new EventEmitter<any>();
  @Input()  searchStr:string;
   
  constructor(public database:DatabaseService<any>, public confirm:ConfirmService, public material:MaterializeService, public forms:FormService,  public ws:WebsiteService, public masks:MaskService, public auth:AuthService<Premiado>) { }

  ngOnInit() {
    this.campanha = this.ws.campanha;
    let query: QueryFn = ref => ref.where('status', '==', 'ATIVO');
      let sub = this.database.listValues('VALEPRESENTES').subscribe(result =>{
         sub.unsubscribe();
         this.giftcards = result;
       })
    }

    selectCard(card:Giftcard, valor){
      let shop:any = {card:card, valor:valor, modelo:card.modelo, quantidade:1}
      this.cardEmitter.next(card);
    }

    shouldHideCard(card:Giftcard){
      return false//card.ENABLED != '1' || !card.AMOUNT || card.AMOUNT == 1 || card.MEDIA?.PROVIDER_LOGO == ""
    }
  }

