<div class="row s12" ng-form name="mf">

    <div class="input-field col s12" >
      <input id="inputvl"[(ngModel)]="valor" [disabled]="!editable" name="valor" required (keyup)="checkAmount($event)" [max]="max"  [min]="min" [step]="0.01" type="number" class="validate white-text" />
      <label class="active" for="inputvl">Valor</label>
    </div>
    <!-- <p class="tiny grey-text text-lighten-2" ng-show="!mf.valor.$pristine && mf.valor.$invalid">
      <i class="material-icons yellow-text">report_problem</i>  Informe um valor válido.</p> -->

        <h6 class="grey-text text-lighten-2 col s6 left"  *ngIf="editable && max">Máximo: <span class="white-text">R$ {{max}} </span> </h6>
        <h6 class="grey-text text-lighten-2 col s6 right" *ngIf="floatingtax">Taxa: <span class="white-text">{{taxmodel}} {{ws.campanha.moeda}} </span></h6>
        <h6 class="grey-text text-lighten-2 col s6 right" *ngIf="!floatingtax && service?.tax > 0">Taxa: <span class="white-text"> {{service?.tax}} {{ws.campanha.moeda}}</span></h6> 

    </div>