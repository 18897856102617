import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'negativeToZero'
})
export class NegativeToZeroPipe implements PipeTransform {
  transform(value: number): number {
    return value < 0 ? 0 : Number(value.toFixed(2));
  }
}
