import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthService } from '@ollieestudio/fire-lib';
import { Premiado } from 'src/app/services/interfaces/premiado';
import { WebsiteService } from 'src/app/services/di/website.service';



@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.css']
})
export class ChatbotComponent implements OnInit {
welcomeVars:any;
@Output() closer:EventEmitter<boolean> = new EventEmitter();

  constructor(public auth:AuthService<Premiado>, public ws:WebsiteService) { }

  ngOnInit() {
    if(this.auth.User){
      this.welcomeVars = JSON.stringify({
        enterpriseid: this.auth.User.enterprise,
        clientid: this.auth.User.cliente,
        userid: this.auth.User.id,
        authid: this.auth.User.authid,
        username: this.auth.User.apelido,
        site: this.ws.website.url,
        moeda:this.ws.campanha.moeda,
        campanha:this.ws.campanha.identificador
      });
      // this.loadScript('../assets/js/libs/webdemo-legacy.min.js');
    }
  }

  close(){
    this.closer.next(true);
  }

  loadScript(url) {
            let body = <HTMLDivElement> document.body;
            let script = document.createElement('script');
            script.innerHTML = '';
            script.src = url;
            script.async = true;
            script.defer = true;
            body.appendChild(script);
    }

}
