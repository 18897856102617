import { Component, OnInit } from '@angular/core';
import { AuthService, DatabaseService } from '@ollieestudio/fire-lib';
import { Premiado } from 'src/app/services/interfaces/premiado';
import { WebsiteService } from 'src/app/services/di/website.service';
import { QueryFn } from '@angular/fire/compat/firestore';
import { Subscription } from 'rxjs';
import { Status } from 'src/app/services/enum/status.enum';
import { Update } from 'src/app/services/interfaces/update';
import { Tipo } from 'src/app/services/enum/tipo.enum';
import { Transaction } from 'src/app/services/interfaces/transaction';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-extrato',
  templateUrl: './extrato.component.html',
  styleUrls: ['./extrato.component.css']
})
export class ExtratoComponent implements OnInit {
  extrato:Transaction[];
  searchStr:string;
  hideTransacs:boolean = true;
  
  constructor(public analytics:AnalyticsService, public auth:AuthService<Premiado>, public database:DatabaseService<any>, public ws:WebsiteService) { }

  ngOnInit() {
    this.getUserTransactions();
    this.analytics.logEvent('view_extrato_list', {userid: this.auth.User.id});
  }

  getUserTransactions(){
    let query:QueryFn = ref => ref.where('usuario', '==', this.auth.User.id).where('status', 'in', ['ATIVO', 'CONCLUIDO', 'PROCESSANDO', 'CANCELADO']); 
    let sub:Subscription = this.database.queryValues('TRANSACTIONS', query).subscribe(transactions =>{
        // sub.unsubscribe();
        this.extrato = transactions;
      })
  }

}
