import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InstitucionalComponent } from './views/institucional/institucional.component';
import { SectionsComponent } from './views/institucional/sections/sections.component';
import { LoginComponent } from './views/institucional/login/login.component';
import { RegisterComponent } from './views/institucional/register/register.component';
import { RestoreComponent } from './views/institucional/restore/restore.component';
import { HomeComponent } from './views/home/home.component';
import { StartComponent } from './views/start/start.component';
import { CardsComponent } from './views/cards/cards.component';
import { VoucherComponent } from './views/voucher/voucher.component';
import { BanklineComponent } from './views/bankline/bankline.component';
import { PaymentComponent } from './views/payment/payment.component';
import { MobileComponent } from './views/mobile/mobile.component';
import { GiftcardComponent } from './views/giftcard/giftcard.component';
import { ShopComponent } from './views/shop/shop.component';
import { DadosComponent } from './views/dados/dados.component';
import { HelpComponent } from './views/help/help.component';
import { ExtratoComponent } from './views/extrato/extrato.component';
import { GetpointsComponent } from './views/getpoints/getpoints.component';
import { ProspectComponent } from './views/institucional/prospect/prospect.component';
import { SalesComponent } from './views/sales/sales.component';
import { QrcodepayComponent } from './views/qrcodepay/qrcodepay.component';

const routes: Routes = [
  { path: '', redirectTo: '/institucional/sections', pathMatch: 'full' },
  {path: 'prospect/:prospectid/:messageid', component: ProspectComponent},
  {path: 'institucional', component: InstitucionalComponent,
  children: [
    { path:  '', redirectTo: 'sections', pathMatch: 'full' },
    { path:  'sections',component:SectionsComponent,  },
    { path:  'restore', component:RestoreComponent,   },
    { path:  'register',component:RegisterComponent,   }
    ]},  
   {path: 'home', component: HomeComponent,
   children: [
     { path:  '', redirectTo: 'start', pathMatch: 'full' },
     { path:  'start',component:StartComponent  },
     { path:  'online_cardtransf',component:CardsComponent  },
     { path:  'online_catalogo',component:ShopComponent   },
     { path:  'online_mobile',component:MobileComponent  },
     { path:  'online_payment',component:PaymentComponent  },
     { path:  'online_transf',component:BanklineComponent  },
     { path:  'online_valepresente',component:GiftcardComponent  },
     { path:  'online_getpoints',component:GetpointsComponent },
     { path:  'online_salesinput',component:SalesComponent },
     { path:  'online_qrcodepay',component:QrcodepayComponent },
     { path:  'online_voucher',component:VoucherComponent },
     { path:  'profile',component:DadosComponent  },
     { path:  'help',component:HelpComponent  },
     { path:  'extrato',component:ExtratoComponent  },
     ] },
  {path: 'sandbox',  redirectTo: '/institucional/sections', pathMatch: 'prefix'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
