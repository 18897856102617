import { Component, OnInit, ViewChild } from '@angular/core';
import { Website } from 'src/app/services/interfaces/website';
import { WebsiteService } from 'src/app/services/di/website.service';
import { InputBase, FormService, AuthService } from '@ollieestudio/fire-lib';
import { ConfirmService } from 'src/app/services/confirm.service';
import { FormGroup } from '@angular/forms';
import { Service } from 'src/app/services/interfaces/service';
import { Card } from 'src/app/services/interfaces/card';
import { Transaction } from 'src/app/services/interfaces/transaction';
import { Premiado } from 'src/app/services/interfaces/premiado';
import { Tipo } from 'src/app/services/enum/tipo.enum';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { CardsFavoritesComponent } from './favorites/favorites.component';
import { CardsFormComponent } from './form/form.component';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.css']
})
export class CardsComponent implements OnInit {
  @ViewChild(CardsFavoritesComponent, { static: true }) favorites:CardsFavoritesComponent;
  @ViewChild(CardsFormComponent, { static: true }) form:CardsFormComponent;
  private selectedCard:Card;
  servicetype: string =  Tipo['card-transf'];
  service:Service;
  showForm:boolean = false;
  addressForm: any;
  website:Website;
  card:Card;
  
  constructor(private scrollService: ScrollToService, public ws:WebsiteService, public scroll:ScrollToService,  public confirm:ConfirmService, public auth:AuthService<Premiado>) { 
    let sub = this.ws.websiteEmitter.subscribe(website =>{
      sub.unsubscribe();
      this.service = {...this.ws.campanha.services[this.servicetype], ...this.ws.services.filter(service => service.type === this.servicetype)[0]};
    })
  }

  ngOnInit() {
   this.website = this.ws.website;
   this.service = {...this.ws.campanha.services[this.servicetype], ...this.ws.services.filter(service => service.type === this.servicetype)[0]};
   this.confirm.resultEmitter.subscribe(result =>{
     if(result === true){
      this.showForm = false;
      window.scrollTo(0,0);
      }
   })
  }

  enableCardForm(){
    this.showForm = true;
    this.scroll.scrollTo({target:'#cardselect'});
    let sub = this.form.hideFormEmitter.subscribe(hide =>{
      sub.unsubscribe();
      this.showForm = false
    });
  }

}
