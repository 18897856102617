<nav class="customcolor1 main valign-wrapper" [style.backgroundColor]="website?.color1" role="navigation" >
    <div class="nav-wrapper  valign container" >
      <a id="logo-container" class="brand-logo">
        <img src="{{website.logo}}" alt="{{website.title}}">
      </a>
      <ul class="right hide-on-med-and-down logged">
        <li class="row saldocontainer">
          <div class="col s12 left-align saldo" >
            <div class="col m6">
              <img *ngIf="false" class="circle left" src="assets/images/yuna.png">
              <i *ngIf="true" routerLink="profile" [style.color]="ws.website?.color2" class="material-icons left sidenav-close profile-ico">account_circle </i>
              <h5 [style.font-family]="ws.website.font.family"   class="left white-text bold col m6">{{User.apelido | shorten:10:'...'}}</h5>
            </div>
            <h5 [style.font-family]="ws.website.font.family"  class="right white-text col m5"> {{saldo | negativeToZero}}
              <span class="tiny white-text">{{campanha.moeda}}</span>
            </h5>
          </div>
          <i class="mdi mdi-home white-text text-lighten-2 col s2 center sidenav-close"  routerLink="start" aria-hidden="true"></i>
          <i class="mdi mdi-account-circle white-text text-lighten-2 col s2 center sidenav-close"  routerLink="profile" aria-hidden="true"></i>
          <i class="mdi mdi-chart-line white-text text-lighten-2 col s2 center sidenav-close"  routerLink="extrato" aria-hidden="true"></i>
          <i class="mdi mdi-help-circle white-text text-lighten-2 col s2 center sidenav-close"  routerLink="help"  aria-hidden="true"></i>
          <i id="logout-btn-m" class="white-text text-lighten-2 col s2 center sidenav-close material-icons" (click)="logout()">exit_to_app</i>
        </li>
          <li *ngFor="let service of services"  class="col s2 right"  [hidden]="hideService(service)" >
              <a class="link_" routerLink="{{service.type}}" style="color:servicefontcolor">
                <i class="mdi {{service.icon}} material-icons large center col s12" aria-hidden="true">{{service.icon}}</i>
                <p class="center tiny col s12" > {{service.title | uppercase}} </p>
              </a>
            </li>
            <!-- <li  class="col s2 right" >
              <a class="link_" routerLink="sales_input" style="color:servicefontcolor">
                <i class="mdi sales material-icons large center col s12" aria-hidden="true">storefront</i>
                <p class="center tiny col s12" > CADASTRAR VENDAS </p>
              </a>
            </li> -->
      </ul>
  
      <!-- mobile nav -->
      <ul id="nav-mobile" class="sidenav sidenav-fixed left-aligned side-nav hide-on-large-only">

        <li>
          <div class="user-view" *ngIf="User" >
          <div class="background" [style.backgroundColor]="ws.website?.color1">
            <img src="assets/images/circuit.png" class="circuit">
          </div>
          <a>
            <img *ngIf="false" class="circle" src="assets/images/yuna.png">
            <i *ngIf="true" routerLink="profile" [style.color]="ws.website?.color2" class="material-icons profile sidenav-close">account_circle </i>
          </a>
          <a ><h5 [style.font-family]="ws.website.font.family"   class="name white-text bold">{{User.apelido}}</h5></a>
          <!-- <a ><span [style.color]="ws.website.color2" class="email bold">{{User.email}}</span></a> -->
          <div class="row left-align saldo" >
            <h5 [style.font-family]="ws.website.font.family"  class="right white-text"> {{saldo | negativeToZero}}
            <span class="tiny white-text">{{campanha.moeda}}</span>
          </h5>
         </div>
        </div>

      </li>

        <li *ngFor="let service of services"  class=""  [hidden]="hideService(service)" >
          <a class="row right menu-item sidenav-close" routerLink="{{service.type}}" style="color:servicefontcolor">
            <i class="mdi {{service.icon}}  material-icons grey-text col s2 right" aria-hidden="true">{{service.icon}}</i>
            <span class="col s9 right-align" > {{service.title}} </span>
          </a>
        </li>
        <!-- <li  class="" >
          <a class="row right menu-item sidenav-close" routerLink="sales_input" style="color:servicefontcolor">
            <i class="mdi sales material-icons grey-text col s2 right" aria-hidden="true">storefront</i>
            <span class="col s9 right-align" >CADASTRAR VENDA</span>
          </a>
        </li> -->
        <li>
          <a class="row right menu-item sidenav-close"(click)="logout()" style="color:servicefontcolor">
            <i class="mdi material-icons grey-text col s2 right" aria-hidden="true">exit_to_app</i>
            <span class="col s9 right-align" > sair </span>
          </a>
        </li>

        <!-- <li class="" >
          <a class="row right menu-item sidenav-close" routerLink="online_getpoints" style="color:servicefontcolor">
            <i class="mdi mdi-plus-circle-multiple-outline grey-text col s2 right" aria-hidden="true"></i>
            <span class="col s9 right-align" > Comprar + Pontos</span>
          </a>
        </li> -->

        <li class="bottom-nav customcolor1 row" [style.backgroundColor]="ws.website?.color1">
          <i class="mdi mdi-home white-text text-lighten-2 col s3 center sidenav-close"  routerLink="start" aria-hidden="true"></i>
          <i class="mdi mdi-account-circle white-text text-lighten-2 col s3 center sidenav-close"  routerLink="profile" aria-hidden="true"></i>
          <i class="mdi mdi-chart-line white-text text-lighten-2 col s3 center sidenav-close"  routerLink="extrato" aria-hidden="true"></i>
          <i class="mdi mdi-help-circle white-text text-lighten-2 col s3 center sidenav-close"  routerLink="help"  aria-hidden="true"></i>
        </li>
      </ul>

    
           
      <div class=""><a href="#" data-target="nav-mobile" class="top-nav sidenav-trigger waves-effect waves-light circle hide-on-large-only"><i class="material-icons ">menu</i></a></div>
      <div id="logout-btn" class="right hide-on-large-only" (click)="logout()"><i class="material-icons ">exit_to_app</i></div>
    
    
    </div>
  </nav>
          
  <app-regulamento *ngIf="showRegulamento"></app-regulamento>