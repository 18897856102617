<div id="chatbot" class="row" >
    <div class="head">
        <div class="b-agent-demo_header-agent-name left"><h5  [style.color]="ws.website?.color1">Chamado de Helpdesk </h5></div>
          <i (click)="close();" class="grey-text material-icons right link">close</i>
    </div>

    <form [formGroup]="formContato" (submit)="registerContato()" class="col s12 container">
     
      <div [hidden]="auth.User?.apelido"> 
        <div class="block col s12 ">
          <label class=" validate active  " for="nome">Nome Completo:</label><input [formControlName]="'nome'" minlength="5" pattern="[a-zA-Zà-úÀ-Ú ]*" class="" type="text"
            id="nome" />
        </div>
        <div class="block col s12 ">
          <label class=" validate active  active" for="emailct">Email:</label>
          <input [formControlName]="'email'" class="" type="email" id="emailct" />
        </div>
        <div class="block col s12 ">
          <label for="celular">Celular / Whatsapp:</label>
          <input [formControlName]="'celular'" [minlength]="12"
            [textMask]="{mask:mask.maskCelular, guide:false}" class="validate" type="text" id="celular" />
        </div>
      </div>
      <div class="block  col s12">
        <label class=" validate active  col s10 ">Qual o assunto de seu contato?</label>
        <select [ngClass]="{'ismac': ws.isMac()}" id="assunto" [formControlName]="'assunto'"
          class="col s12 browser-default">
          <option disabled [selected]="true">selecione</option>
          <option *ngFor="let item of assuntos" value="{{item}}">{{item}}</option>
        </select>
      </div>
      <div class="row">
  
        <div class="text col s10 push-s1" style="margin-top: 10px;">
          <label class=" validate active  active" for="resumo">Qual a sua mensagem?</label>
          <textarea [formControlName]="'mensagem'" class="materialize-textarea" rows="5" id="resumo"
            style="height: 90px;"></textarea>
        </div>
      </div>
  
      <div class="row">
        <a (click)="registerContato()"  [style.backgroundColor]="ws.website?.color1" [class.disabled]="formContato.controls['mensagem'].invalid"
          class="btn center col s8 push-s2 m6 push-m3">enviar <i class="material-icons right">check_circle_outline</i> </a>
      
      </div>
    </form>
  
  </div>
  