<div id="topbank" class="toppad">
    &nbsp;
  </div>
  <div class="container top-5">
    <div class="row" *ngIf="service">
      
      <h3 [style.font-family]="ws.website.font.family"  class="col s12 m11 white-text upper"><i
          class="material-icons">{{service.icon}}</i>&nbsp;{{service.title}}
      </h3>
    </div>
    <div class="row">
      
      <div class="col s12 m11 instrucoes">
        <h5 [style.font-family]="ws.website.font.family"  class="white-text">INSTRUÇÕES</h5>
        <ul class="browser-default">
          <li><span class="white-text">Selecione a quantidade de pontos que quer acrescentar ao seu saldo.</span></li>
          <li><span class="white-text">O valor mínimo para recarga é de R$ 50.</span></li>
          <li><span class="white-text">Prazo para efetivação da carga: 1 a 3 dias úteis após o pagamento. </span></li>
          <li *ngIf="service"><span class="white-text bold">Taxa de Serviço: {{service.tax*ws.campanha.conversion}} {{ws.campanha.moeda}} / Limite por solicitação: {{service.limite*ws.campanha.conversion}} {{ws.campanha.moeda}}</span></li>
        </ul>
  
      </div>
    </div>
    <div class="row">
  
    <app-getpointsform [service]="service" *ngIf="service"></app-getpointsform> 
  
    </div>
  
  
  </div>
  
