import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { WebsiteService } from 'src/app/services/di/website.service';
import { Giftcard } from 'src/app/services/interfaces/giftcard';

@Component({
  selector: 'app-gift-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class GiftCartComponent implements OnInit {
  cartList: Giftcard[] = [];
  totalproduct:number;
  totalvalue:number;
  @Output() checkoutEmitter = new EventEmitter<any>();
  
  constructor(public scrollService:ScrollToService, public ws:WebsiteService) { }
  
  ngOnInit() {
  }
  
  resetCart() {
    this.cartList = [];
    this.updateTotal();
  }

  addProduct(addcard: any) {
    if(this.cartList.filter(element => element.codigo === addcard.codigo).length > 0){
      this.cartList.filter(element => element.codigo === addcard.codigo)[0]['quantidade']++;
    }else{
      this.cartList = [addcard];
    }
    this.updateTotal();
    this.scrollService.scrollTo({target:"#top"});
    this.gotoCheckout();
  }

  updateTotal() {
    this.totalvalue = 0;
    this.totalproduct = 0;
    this.cartList.forEach(element => {
      this.totalproduct += element.quantidade;
      this.totalvalue += element.valor*element.quantidade;
    });
  }

  gotoCheckout(){
    this.checkoutEmitter.next(true);
  }
}
