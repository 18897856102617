<div id="modalvouchers" class="modal webstore">
    <div class="modal-content">
      <div class="row center" style="padding-top: 10px;">
        <div class="col s12 m4">
          <img class="responsive-img left" src="{{card?.img}}" alt="">

        </div>
        <div class="col s12 m7">
          <div class=" row valign-wrapper">
            <h5 [style.font-family]="ws.website.font.family"  class="customcolor1 left-align">{{card?.modelo}}
              <span class="normal-text">
                SKU: {{card?.sku}}
              </span>
            </h5>
            <h4 [style.font-family]="ws.website.font.family"  *ngIf="displayvalor" class="black-text col s8  m2 right valign">{{displayvalor}}<span
                style="font-size:0.85rem; margin-left:8px; vertical-align:super">{{campanha.moeda}}</span></h4>
          </div>
        </div>
      </div>
     <div class="row">
        <div class="col s12">
          <ul class="tabs tabs-fixed-width">
            <li class="tab col s6"><a href="#tab1" [style.color]="ws.website?.color1"
                class="col s12 active">Descrição</a></li>
            <li class="tab col s6"><a href="#tab2" [style.color]="ws.website?.color1" class="col s12">Como Resgatar</a>
            </li>
            <li class="tab col s6"><a href="#tab3" [style.color]="ws.website?.color1" class="col s12">Termos e Condições</a>
            </li>
          </ul>
        </div>
        <div id="tab1" class="col s12 tab-content">
          <p class="black-text">{{card?.descricao}}</p>
        </div>
        <div id="tab2" class="col s12 tab-content">
          <p class="black-text">{{card?.informacoes}}</p>
        </div>
        <div id="tab3" class="col s12 tab-content">
          <p class="black-text">{{card?.termos}}</p>
          <!-- <a class="orange-text" [href]="card?.INFOS[0]?.REDEMPTION_LINK" target="_blank">{{card?.INFOS[0]?.REDEMPTION_LINK}}</a> -->
        </div>
      </div>

        
    </div>
    <div class="modal-footer">
      <div class="row">
        <a class="modal-close btn-flat col m4 s4 center">cancelar</a>
        <button  [disabled]="checkSaldo()" (click)="addItem()"
          [style.backgroundColor]="ws.website?.color1"
          class="white-text modal-action modal-close col m4 s6 btn center right pull-m1 ">
          <i class="material-icons ">shopping_cart</i> <span style="margin-left: 5px;">RESGATAR</span> </button>
      </div>
    </div>
</div>
