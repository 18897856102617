<h4  [style.font-family]="ws.website.font.family"  class="white-text center" *ngIf="contaselecionada">NOVA TRANSFERÊNCIA</h4>

<div class="row" *ngIf="contaselecionada">
    <div class="row">
        <app-inputvalor editable="true" model="transaction.privatedata.valor" service="campanha.services.online_transf"></app-inputvalor>
    </div>
    <div class="row">
      <div class="col s12">
        <button class="waves-effect waves-light btn col m3 s12 right" [style.backgroundColor]="ws.website?.color1" [disabled]="formTransf.$pristine || formTransf.$invalid" id="bt-envia-bankline" (click)="processTransf()">enviar</button>
        <a class="btn-flat col m3 s12 right" id="bt-cancel-transfer" (click)="resetTransaction()">limpar</a>
      </div>
    </div>
  </div>