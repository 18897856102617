<div class="row">
    <div class="col s12 ">
      <h3 [style.font-family]="ws.website.font.family" ><i class="mdi-content-send brown-text"></i></h3>
      <h4 [style.font-family]="ws.website.font.family"  class="center white-text">Perguntas Frequentes</h4>
      <div class="row s12 input-field">
        <i class="material-icons white-text prefix">search</i>
        <input id="search" class="active white-text col s11 " [(ngModel)]="searchStr"  />
        <label for="searchbox">Digite sua dúvida</label>
      </div>
      <ul class="collapsible white" data-collapsible="accordion" filter-list="searchStr">
        <li class="" data-filter="subject1" data-item="item1">
          <div class="collapsible-header">

            O que é o {{campanha.identificador | uppercase}}?

          </div>
          <div class="collapsible-body grey lighten-3">
            {{campanha.identificador | uppercase}} é a sua carteira digital de incentivo o fidelidade. Conquiste seus {{campanha.moeda | uppercase}} 
            e troquepor milhares de produtos e serviços.
          </div>
        </li>
        <li class="" data-filter="subject1" data-item="item2">
          <div class="collapsible-header">

            O que é {{campanha.moeda | uppercase}}?

          </div>
          <div class="collapsible-body grey lighten-3">
            Sigla utilizada para identificar sua moeda no Programa {{campanha.identificador | uppercase}}.
          </div>
        </li>
        <li class="" data-filter="subject1" data-item="item3">
          <div class="collapsible-header">

            Quanto vale 1 {{campanha.moeda | uppercase}}?

          </div>
          <div class="collapsible-body grey lighten-3">
            O valor de conversão de 1 {{campanha.moeda | uppercase}} para R$ 1 é de {{campanha.conversion}} : 1.
          </div>
        </li>
        <!-- <li class="" data-filter="subject1" data-item="item4">
          <div class="collapsible-header">

            Por que o {{campanha.identificador | uppercase}} é diferente dos demais programas de fidelidade?

          </div>
          <div class="collapsible-body grey lighten-3">
            Mais que um programa de fidelidade, {{campanha.moeda | uppercase}} é um programa de coalizão, ou seja, várias empresas de segmentos diferentes distribuem {{campanha.moeda | uppercase}} em formatos diversificados. Enquanto nos demais programas de fidelidade os participantes ganham {{campanha.moeda | uppercase}} comprando em um
            único lugar, no {{campanha.identificador | uppercase}} é possível ganhar muito mais rápido porque diversos parceiros, de diversos segmentos, oferecem {{campanha.moeda | uppercase}}. Resultado: quem é participante ganha {{campanha.moeda | uppercase}} muito mais rápido e também troca muito mais rápido do que nos
            demais programas convencionais, pois o cliente consegue acumular nas compras do seu dia a dia.
          </div>
        </li> -->
        <li class="" data-filter="subject1" data-item="item5">
          <div class="collapsible-header">

            Quanto custa para participar do {{campanha.identificador | uppercase}}?

          </div>
          <div class="collapsible-body grey lighten-3">
            O {{campanha.identificador | uppercase}} é completamente gratuito. O participante não paga absolutamente nada para participar.
          </div>
        </li>
        <li class="" data-filter="subject1" data-item="item6">
          <div class="collapsible-header">

            Como entrar em contato com o {{campanha.identificador | uppercase}}?

          </div>
          <div class="collapsible-body grey lighten-3">
            <p>O {{campanha.identificador | uppercase}} está cada vez mais perto dos seus clientes. Fale com a Equipe {{campanha.moeda | uppercase}} por diversos Canais:</p>
            <p>
              Pelo Site
              <br> Acesse a seção helpdesk no site do {{campanha.identificador | uppercase}} e envie sua mensagem.
              <!-- <br> Ela está preparada para receber as ocorrências por meio de formulários separados por motivos de acordo com a solicitação:
              <br> a) Depósito de {{campanha.moeda | uppercase}}: recebe qualquer ocorrência relacionada a {{campanha.moeda | uppercase}} não creditado.
              <br> b) Depósito de {{campanha.moeda | uppercase}} em uma conta diferente: recebe ocorrências de {{campanha.moeda | uppercase}} depositados em contas sem propriedade.
              <br> c) Entrega de produtos ou compras em {{campanha.moeda | uppercase}}: recebe ocorrências relacionadas ao processo de troca de {{campanha.moeda | uppercase}}.
              <br> d) Outros assuntos: recebe solicitação de esclarecimento de dúvidas, críticas, sugestões e elogios.
            </p>
            <p>
              Por telefone
              <br> Capitais e regiões metropolitanas 4007-1339
              <br> Demais localidades 0800 601 1339
              <br> Todos os dias da semana, das 8h às 22h.
            </p> -->
          </div>
        </li>
        <li class="" data-filter="subject1" data-item="item7">
          <div class="collapsible-header">

            Como ter acesso às regras de participação do {{campanha.identificador | uppercase}}?

          </div>
          <div class="collapsible-body grey lighten-3">
            Todas as regras para participar do {{campanha.identificador | uppercase}} estão disponíveis na seção "Regulamento".<a routerLink="home.regulamento">Clique aqui</a> e confira.
          </div>
        </li>
        <!-- <li class="" data-filter="subject2" data-item="item8" >
          <div class="collapsible-header">

            Como realizar um cadastro no {{campanha.identificador | uppercase}}?

          </div>
          <div class="collapsible-body grey lighten-3">
            <p>É possível realizar o cadastro de várias maneiras diferentes: </p>
            <p>
              a) Diretamente nos estabelecimentos dos parceiros conveniados a este serviço Basta preencher a ficha de cadastro disponível no parceiro.
              <br> Entregando a ficha a um Promotor {{campanha.moeda | uppercase}}, o Conta {{campanha.identificador}} é retirado na hora.
              <br> Caso não haja um Promotor {{campanha.moeda | uppercase}} no local, basta depositar a ficha na Urna {{campanha.moeda | uppercase}}.
              <br> O cadastro pode ser realizado em até 15 dias, mas você já começa a pontuar na hora.
            </p>
            <p>
              b) Por meio do www.sitesquerendem{{campanha.url}}
              <br> Basta preencher a ficha de cadastro disponível no <a href="https://www.{{campanha.url}}/cadastro/">https://www.{{campanha.url}}/cadastro/</a>.
              <br> Uma mensagem de ativação é enviada via e-mail.
            </p>
            <p>
              c) Por meio da Central de Atendimento
              <br> Ligue para nossa central e escolha a opção para falar com um de nossos atendentes.
              <br> Basta informar os dados cadastrais ao Atendente {{campanha.moeda | uppercase}}. Informando um e-mail válido, receberá uma mensagem de ativação via e-mail.
              <br> A senha de acesso será enviada em até 24h. Se houve o registro de um e-mail válido no momento do cadastro, a senha será enviada via e-mail. Caso contrário, a senha será enviada via SMS.
            </p>
            <p>
              d) Diretamente no Autoatendimento do Banco do Brasil ou no Internet Banking (exclusivo para clientes Ourocard)
              <br> O Cadastro via autoatendimento da Rede Banco do Brasil é exclusivo para Clientes Ourocard. O Cliente Ourocard deverá acessar o menu "Ponto pra Você", escolhendo o {{campanha.identificador | uppercase}} em seguida. Para confirmar a adesão, basta digitar
              a senha da conta da conta corrente. Na sequência, aceita o Termo de Adesão e imprime o comprovante da operação.
              <br> Para receber sua senha de acesso ao {{campanha.identificador | uppercase}}, acesse o Site {{campanha.identificador}} e clique no link <a href="https://www.{{campanha.url}}/Atendimento/PegarSenha.aspx">Criar Senha.</a>
            </p>
          </div>
        </li> -->
        <li class="" data-filter="subject2" data-item="item9" >
          <div class="collapsible-header">

            Como acessar a Conta {{campanha.identificador}}?

          </div>
          <div class="collapsible-body grey lighten-3">
            A Conta {{campanha.identificador}} pode ser acessada a qualquer momento por meio do www.{{campanha.url}}. É possível acompanhar o saldo disponível em {{campanha.moeda | uppercase}}, atualizar os dados cadastrais, administrar os Adicionais e muito mais.
          </div>
        </li>
        <!-- <li class="" data-filter="subject2" data-item="item10" >
          <div class="collapsible-header">

            Quem pode acessar uma conta cadastrada?

          </div>
          <div class="collapsible-body grey lighten-3">
            Se uma Conta {{campanha.identificador}} possui Clientes Titulares e Adicionais, ambos acessam suas contas individualmente e visualizam o extrato no formato consolidado. Atenção! O cadastro no {{campanha.identificador | uppercase}} é individual e intransferível. Clientes Titular e Adicional possuem
            acessos particulares.
          </div>
        </li> -->
        <li class="" data-filter="subject2" data-item="item11" >
          <div class="collapsible-header">

            Qual a idade mínima para ter um cadastro no {{campanha.identificador | uppercase}}?

          </div>
          <div class="collapsible-body grey lighten-3">
            Poderão participar do {{campanha.identificador | uppercase}}, na condição de titulares de uma Conta {{campanha.identificador}}, as pessoas físicas maiores de 18 (dezoito) anos, regularmente inscritas no cadastro de pessoa física (CPF), bem como as pessoas
            jurídicas regularmente inscritas no cadastro de pessoas jurídicas (CNPJ), desde que tenham sede no Brasil.
            <br> Também poderão participar do {{campanha.identificador | uppercase}}, as pessoas físicas maiores de 18 (dezoito) anos, regularmente inscritas no cadastro de pessoa física (CPF), participantes de outros programas eventualmente incorporados/migrados
            ou em razão de contratos de parcerias específicas com a {{campanha.moeda | uppercase}}.
          </div>
        </li>
        <li class="" data-filter="subject2" data-item="item12" >
          <div class="collapsible-header">

            É obrigatório o cadastro de um e-mail?

          </div>
          <div class="collapsible-body grey lighten-3">
            O cadastro do participante no {{campanha.identificador | uppercase}}, exige uma conta de e-mail válida. Este e-mail somente poderá ser alterado mediante solicitação do próprio participante, por meio do Site {{campanha.identificador}} (
            <a href="https://www.{{campanha.url}}/www.{{campanha.url}}">www.{{campanha.url}}</a>).
          </div>
        </li>
        <li class="" data-filter="subject2" data-item="item14" >
          <div class="collapsible-header">

            Como adquirir os cartões de crédito parceiros do {{campanha.identificador | uppercase}}?

          </div>
          <div class="collapsible-body grey lighten-3">
            Alguns Parceiros possuem cartões de créditos próprios, os quais oferecem {{campanha.moeda | uppercase}} de maneira bastante vantajosa.
            <br> Para saber quais parceiros oferecem este benefício, acesse o <a href="https://www.{{campanha.url}}/cartoesdecredito/">site</a> e confira os procedimentos necessários. Vale lembrar que as regas de aquisição e as promoções de oferecimento
            de {{campanha.moeda | uppercase}} são de total responsabilidade dos parceiros e sempre estão sujeitas à atualização e à análise de crédito.
          </div>
        </li>
        <li class="" data-filter="subject2" data-item="item15" >
          <div class="collapsible-header">

            É necessário ter cartão de crédito para participar do {{campanha.identificador | uppercase}}?

          </div>
          <div class="collapsible-body grey lighten-3">
            Não é preciso ter cartão de crédito de qualquer natureza para ter {{campanha.moeda
            | uppercase}}.
          </div>
        </li>
        <!-- <li class="" data-filter="subject2" data-item="item16" >
          <div class="collapsible-header">

            Quantos Clientes {{campanha.moeda | uppercase}} Adicionais uma conta pode ter?

          </div>
          <div class="collapsible-body grey lighten-3">
            Uma Conta Titular pode ter até 3 (três) Contas Adicionais. Vale lembrar que para ser Adicional, o cliente deve possuir cadastro ativo no {{campanha.identificador | uppercase}} e o a solicitação de vinculação à determinado cliente Titular deve partir do Adicional.
          </div>
        </li> -->
        <!-- <li class="" data-filter="subject2" data-item="item17" >
          <div class="collapsible-header">

            É possível transformar um participante já cadastrado em Adicional de uma conta?

          </div>
          <div class="collapsible-body grey lighten-3">
            Sim, para transformar um cadastro titular em adicional de outra conta é imprescindível ter o cadastro na {{campanha.moeda | uppercase}} e o adicional manifestar interesse na vinculação de contas através da central de atendimento.
          </div>
        </li> -->
        <!-- <li class="" data-filter="subject2" data-item="item18" >
          <div class="collapsible-header">

            Um participante Adicional pode se transformar em Titular de uma conta?

          </div>
          <div class="collapsible-body grey lighten-3">
            Sim, a qualquer momento o Cliente Adicional pode se transformar em um Cliente Titular novamente. Vale lembrar que, ao deixar de ser Adicional seus {{campanha.moeda | uppercase}} permanecem com o titular, o Cliente sai da sociedade com 0 (zero) {{campanha.moeda | uppercase}} na sua conta.
          </div>
        </li> -->
        <!-- <li class="" data-filter="subject2" data-item="item19" >
          <div class="collapsible-header">

            Se um cliente deixa de ser Adicional para ser Titular, ele inicia a participação como Titular com quantos {{campanha.moeda | uppercase}}?

          </div>
          <div class="collapsible-body grey lighten-3">
            Ao deixar de ser Adicional para ser Titular, seus {{campanha.moeda | uppercase}} permanecem com o titular, o Cliente sai da sociedade com 0 (zero) {{campanha.moeda | uppercase}}.
          </div>
        </li> -->
        <li class="" data-filter="subject2" data-item="item20" >
          <div class="collapsible-header">

            O que fazer em caso de perda ou roubo do Conta {{campanha.identificador}}?

          </div>
          <div class="collapsible-body grey lighten-3">
            Em caso de perda ou roubo do Conta {{campanha.identificador}}, o cliente precisa comunicar a Equipe {{campanha.moeda | uppercase}} imediatamente. O Conta {{campanha.identificador}} será invalidado e um nova conta será emitido. O prazo de recebimento é
            de até 30 dias. Enquanto isso, o participante poderá continuar ganhando {{campanha.moeda | uppercase}} normalmente, basta mencionar o CPF no momento do pagamento da transação.
          </div>
        </li>
        <li class="" data-filter="subject3" data-item="item21">
          <div class="collapsible-header">

            Como altero meus dados cadastrais?

          </div>
          <div class="collapsible-body grey lighten-3">
            <p>Os dados podem ser atualizados de duas maneiras. Via Site {{campanha.identificador}}, clicando na seção “meus dados / alterar dados” ou via Central de Atendimento, com um de nossos atendentes.</p>
          </div>
        </li>
        <li class="" data-filter="subject3" data-item="item22">
          <div class="collapsible-header">

            Como resgato minha Senha?

          </div>
          <div class="collapsible-body grey lighten-3">
            Você pode resgatar a sua senha clicando em ESQUECEU A SENHA no login.
          </div>
        </li>
        <li class="" data-filter="subject3" data-item="item23">
          <div class="collapsible-header">

            É possível transferir {{campanha.moeda | uppercase}} para outra pessoa?

          </div>
          <div class="collapsible-body grey lighten-3">
            Sim. Os {{campanha.moeda | uppercase}} podem ser transferidos entre contas {{campanha.identificador}} sem custo.
          </div>
        </li>
        <li class="" data-filter="subject3" data-item="item24">
          <div class="collapsible-header">

            Os {{campanha.moeda | uppercase}} expiram?

          </div>
          <div class="collapsible-body grey lighten-3">
            Os {{campanha.moeda | uppercase}} ganhos pelos participantes terão validade determinada pela empresa que os disponibilizou.
          </div>
        </li>
        <li class="" data-filter="subject4" data-item="item25">
          <div class="collapsible-header">

            Onde ganhar {{campanha.moeda | uppercase}}?

          </div>
          <div class="collapsible-body grey lighten-3">
            <p>
              É possível ganhar {{campanha.moeda | uppercase}} de acordo com as regras determinadas pela campanha.
          </div>
        </li>
        <li class="" data-filter="subject4" data-item="item101">
          <div class="collapsible-header">

            Qual o prazo para meus {{campanha.moeda | uppercase}} serem creditados?

          </div>
          <div class="collapsible-body grey lighten-3">
            Os {{campanha.moeda | uppercase}} são creditados em até 48 horas após a confirmação do pagamento pela empresa.
          </div>
        </li>
       
        <li class="" data-filter="subject4" data-item="item28">
          <div class="collapsible-header">

            Em uma mesma compra é possível dividir o crédito de {{campanha.moeda | uppercase}} em mais que um Conta {{campanha.identificador}}?

          </div>
          <div class="collapsible-body grey lighten-3">
            A participação no {{campanha.identificador | uppercase}} é pessoal e intransferível. No momento do acúmulo, o participante que realizar o pagamento da transação receberá o respectivo valor integral em {{campanha.moeda | uppercase}} na sua conta.
          </div>
        </li>
       
        <li class="" data-filter="subject4" data-item="item340">
          <div class="collapsible-header">

            Qual o prazo para recebimento do meu produto trocado?

          </div>
          <div class="collapsible-body grey lighten-3">
            <p>A entrega é realizada em até 20 dias úteis (São Paulo - Capital e Grande São Paulo) e em até 25 dias úteis (demais localidades). Confira o prazo de entrega da sua troca no campo “prazo de entrega” de cada produto. Observação: em caso de
              greve dos correios, o prazo de entrega poderá se estender até a sua normalização.</p>
            <p>Para trocas realizadas por produtos de entretenimento, junto ao parceiro Easy Live, a entrega do voucher será feita via e-mail (<a href="mailto:easylive@easylive.com">easylive@easylive.com</a>), em até 72 horas.</p>
          </div>
        </li>
        <li class="" data-filter="subject5" data-item="item35">
          <div class="collapsible-header">

            Como trocar {{campanha.moeda | uppercase}} por produtos?

          </div>
          <div class="collapsible-body grey lighten-3">
            <p>Os {{campanha.moeda | uppercase}} podem ser trocados no site <a href="http://www.{{campanha.url}}/">www.{{campanha.url}}</a>.</p>
            <p>No Catálogo {{campanha.moeda | uppercase}}, na internet, é possível encontrar diversas categorias com segmentos variados como Eletrodoméstico, Viagens, Casa, dentre outras.</p>
           </div>
        </li>
        <li class="" data-filter="subject5" data-item="item36">
          <div class="collapsible-header">

            Qual é o valor mínimo em {{campanha.moeda | uppercase}} necessário para realização de trocas?

          </div>
          <div class="collapsible-body grey lighten-3">
            Não existe um valor mínimo necessário para troca. Depende apenas do produto e da sua disponibilidade em estoque. Verifique o seu saldo e utilize a “busca” no catalogo selecionando os valores.
          </div>
        </li>
        <li class="" data-filter="subject5" data-item="item37">
          <div class="collapsible-header">

            Que tipo de produtos é possível adquirir com {{campanha.moeda | uppercase}}?

          </div>
          <div class="collapsible-body grey lighten-3">
            O Catálogo {{campanha.moeda | uppercase}} possui uma variedade incrível, com milhares de produtos e passagens aéreas disponíveis. É possível adquirir desde CDs e DVDs até produtos para a casa, eletrodomésticos, eletroeletrônicos, cupons de descontos e
            vales-presente.
          </div>
        </li>
        <li class="" data-filter="subject5" data-item="item38">
          <div class="collapsible-header">

            Se eu não tiver o saldo em {{campanha.moeda | uppercase}} suficiente, posso completar minha troca com valor em reais?

          </div>
          <div class="collapsible-body grey lighten-3">
            <p>Para a realização da troca é necessária disponibilidade do saldo solicitado para a troca do produto desejado. </p>
            <p>No entanto, temos duas exceções:</p>
            <p>a) No Catálogo {{campanha.moeda | uppercase}} temos uma categoria chamada {{campanha.moeda | uppercase}} + reais, com alguns produtos exclusivos onde é possível complementar o restante do valor em reais e finalizar a troca.</p>
            <p>b) No caso de trocas de passagens áreas, é possível pagar metade da passagem com {{campanha.moeda | uppercase}} e completar o restante do valor em reais.</p>
          </div>
        </li>
        <li class="" data-filter="subject5" data-item="item39">
          <div class="collapsible-header">

            É possível presentear alguém com um produto mesmo que essa pessoa que não seja cadastrada no {{campanha.identificador | uppercase}}?

          </div>
          <div class="collapsible-body grey lighten-3">
            <p>Sim, inclusive é uma ótima forma de presentar alguém especial.</p>
            Basta inserir o nome da pessoa como destinatária e informar o endereço para a entrega. Vale lembrar que o {{campanha.identificador | uppercase}} apenas realiza entregas em território nacional e é necessária a presença de uma pessoa no local de entrega
            para receber o produto
          </div>
        </li>
        <li class="" data-filter="subject5" data-item="item40">
          <div class="collapsible-header">

            É necessário pagar frete para a entrega de um produto?

          </div>
          <div class="collapsible-body grey lighten-3">
           Os produtos físicos estão sujeitos a cobrança de frete.
          </div>
        </li>
        <li class="" data-filter="subject5" data-item="item41">
          <div class="collapsible-header">

            É possível cancelar uma troca?

          </div>
          <div class="collapsible-body grey lighten-3">
            <p>Sim, a {{campanha.moeda | uppercase}} segue as regras estabelecidas no código de Defesa do Consumidor.</p>
            <p>Em caso de desistência (cancelamento), o prazo para registrar sua solicitação é de até 7 dias corridos após a entrega do seu pedido ou assim que efetuar a troca.</p>
            <p>Caso você receba o produto com defeito de fabricação ou avaria no processo de entrega, solicite a análise e possível troca ou devolução no prazo de até 7 dias corridos após a entrega do produto. Após o prazo de 7 dias você deverá acionar
              a assistência técnica ou fabricante.</p>
            <p>Atenção! Se você receber um produto diferente do que trocou ou não tenha realizado a troca no Catálogo {{campanha.moeda | uppercase}}, comunique a Equipe {{campanha.moeda | uppercase}} no prazo de até 72 horas.</p>
          </div>
        </li>
        <li class="" data-filter="subject5" data-item="item42">
          <div class="collapsible-header">

            Qual o prazo de entrega da minha troca?

          </div>
          <div class="collapsible-body grey lighten-3">
            <p>Depende do produto ou serviço. De modo geral, a entrega será realizada em até 20 dias úteis (São Paulo Capital e Grande São Paulo) / 25 dias úteis (demais localidades).</p>
            <p>Confira o prazo de entrega a sua troca no campo “prazo de entrega” de cada produto.</p>
            <p>Fique atento: Ocorrendo greve dos correios o prazo de entrega poderá se estender até a sua normalização. </p>
            <p>Para trocas realizadas por produtos de entretenimento, junto ao parceiro Easy Live, a entrega do voucher será feita via e-mail (<a href="mailto:easylive@easylive.com">easylive@easylive.com</a>), em até 72 horas.</p>
          </div>
        </li>
        <li class="" data-filter="subject5" data-item="item43">
          <div class="collapsible-header">

            Quais são as condições de entrega de uma troca?

          </div>
          <div class="collapsible-body grey lighten-3">
            <p>No ato da entrega é necessária a presença de um maior de 18 anos para assinar o comprovante de recebimento. Caso o produto não seja entregue nas 3 tentativas realizadas, seja por problemas de endereço, ausência, entre outros, seu pedido
              será cancelado e seus {{campanha.moeda | uppercase}} devolvidos.</p>
            <p>Para trocas realizadas por produtos de entretenimento, junto ao parceiro Easy Live, a entrega do voucher será feita via e-mail (<a href="mailto:easylive@easylive.com">easylive@easylive.com</a>), em até 72 horas.</p>
          </div>
        </li>
        <li class="" data-filter="subject5" data-item="item44">
          <div class="collapsible-header">

            Posso doar meus {{campanha.moeda | uppercase}}?

          </div>
          <div class="collapsible-body grey lighten-3">
            Apenas para instituições beneficentes parceiras disponíveis na sessão de Doações de {{campanha.moeda | uppercase}}. Basta escolher a instituição desejada, que os {{campanha.moeda | uppercase}} serão direcionados a ela para serem utilizados em seu favor.
            <br> Os {{campanha.moeda | uppercase}} não podem ser doados para outras contas.
          </div>
        </li>
        <li class="" data-filter="subject5" data-item="item45">
          <div class="collapsible-header">

            Não reconheço uma troca efetuada em minha conta. E agora?

          </div>
          <div class="collapsible-body grey lighten-3">
            Ocorrendo a hipótese de possível irregularidade nas operações de trocas realizadas no programa, basta contatar a Equipe {{campanha.moeda | uppercase}} por meio dos canais oficiais de atendimento. Uma equipe preparada para análise estará disponível para
            ajudá-lo.
          </div>
        </li>
        
        <li class="" data-filter="subject6" data-item="item46">
          <div class="collapsible-header">

            Por que são necessários dados pessoais para o cadastro?

          </div>
          <div class="collapsible-body grey lighten-3">
            As informações coletadas durante o processo de cadastro da Conta {{campanha.identificador}} são utilizadas para garantir a identidade do participante durante as operações que realizar no Programa. Para mais informações, leia a nossa
            <a href="https://www.{{campanha.url}}/ajuda/privacidade.aspx">Clique aqui</a>
          </div>
        </li>
        <li class="" data-filter="subject6" data-item="item47">
          <div class="collapsible-header">

            As informações fornecidas para o {{campanha.identificador | uppercase}} são compartilhadas com terceiros?

          </div>
          <div class="collapsible-body grey lighten-3">
            Todos os dados fornecidos ao {{campanha.identificador | uppercase}}, são protegidos para assegurar a privacidade do participante e de suas informações nos limites da lei, salvo nas hipóteses de caso fortuito, força maior ou outras excludentes legais de
            responsabilidade civil, de acordo com a política de privacidade do {{campanha.identificador | uppercase}} (www.{{campanha.url}}), bem como respeitando ao aceite de cada participante.
          </div>
        </li>
        <li class="" data-filter="subject6" data-item="item48">
          <div class="collapsible-header">

            O que fazer em caso falecimento do proprietário da Conta {{campanha.identificador}}?

          </div>
          <div class="collapsible-body grey lighten-3">
            Com a morte do participante titular da Conta {{campanha.identificador}}, a pontuação {{campanha.moeda | uppercase}} será transferida aos herdeiros, observadas as regras de sucessão legítima ou testamentária. A {{campanha.moeda | uppercase}} informará
            o inventariante ou a qualquer outro representante legal interessado, a quantidade de Pontuação {{campanha.moeda | uppercase}} disponível na conta do participante {{campanha.moeda | uppercase}} falecido. Porém, a transferência e utilização
            dos {{campanha.moeda | uppercase}} somente ocorrerá mediante decisão judicial.
          </div>
        </li>
        <li class="" data-filter="subject6" data-item="item49">
          <div class="collapsible-header">

            É possível bloquear o recebimento de e-mails e malas diretas do {{campanha.identificador | uppercase}} e seus Parceiros?

          </div>
          <div class="collapsible-body grey lighten-3">
            <p>Caso o participante não tenha interesse em receber comunicações sobre ofertas e demais informações sobre o {{campanha.identificador | uppercase}}, basta acessar o site www.{{campanha.url}} e optar por esta decisão no processo de "Atualização
              de Dados". </p>
            <p>O participante poderá optar ou não pelo recebimento de informações via e-mail a qualquer momento. Ao optar pelo não recebimento das comunicações referentes ao {{campanha.identificador | uppercase}}, o participante fica ciente de que poderá
              não usufruir de promoções e de ofertas veiculadas exclusivamente pelo canal e-mail. </p>
            <p>No entanto, fica claro que a este processo não se exclui a newsletter mensal do {{campanha.identificador | uppercase}}, representada por uma comunicação mínima. Além disso, a desativação desta ferramenta não isenta o participante do recebimento
              das seguintes confirmações:
            </p>
            - Confirmação de trocas
            <br> - Confirmação de solicitação de Senha
            <br> - Confirmação de alteração de e-mail
            <br> - Respostas às requisições e e-mails enviados à Equipe de Atendimento
          </div>
        </li>




        <li>
          <div class="collapsible-header"> Como faço para resgatar meus {{campanha.moeda | uppercase}}? </div>
          <div class="collapsible-body grey lighten-3">
            Basta acessar o site e cadastrar-se. Você será direcionado ao nosso ambiente seguro e acessará nosso catálogo virtual de serviços. Lembrando que só é possível resgatar prêmios com pontuação menor do que seu saldo. Não há valor mínimo para resgates.
          </div>
        </li>
        <li>
          <div class="collapsible-header"> Em caso de perda de cartão ou senha, o que devo fazer? </div>
          <div class="collapsible-body grey lighten-3">
            Em caso de perda de cartão entre em contato conosco para solicitar a emissão de uma segunda via, caso o mesmo seja físico. Neste caso será cobrado uma taxa de 5 {{campanha.moeda | uppercase}} + frete. Caso seja virtual basta nos enviar um e-mail para
            sac@{{campanha.url | uppercase}}.com.br que reenviaremos os dados. Em relação a senha, a mesma deve ser solicitada no site.
          </div>
        </li>
        <li>
          <div class="collapsible-header"> Outra pessoa pode fazer resgates em meu lugar? </div>
          <div class="collapsible-body grey lighten-3">
            O cartão é de uso pessoal e intransferível, deixando ao usuário total responsabilidade sobre sua utilização.
          </div>
        </li>
        <li>
          <div class="collapsible-header">Não consigo acessar o site no meu trabalho. O que ocorre? </div>
          <div class="collapsible-body grey lighten-3">
            Muitas empresas adotam a política de bloquear sites para evitar problemas com vírus. Basta comunicar a área de TI de sua empresa ou seu superior direto para liberarem o acesso.
          </div>
        </li>
        <li>
          <div class="collapsible-header"> Minha empresa não tem internet liberada para funcionários. Posso acessar de outro computador em qualquer horário?</div>
          <div class="collapsible-body grey lighten-3">
            Sim, claro. O site fica 24 horas no ar e você pode acessar de qualquer computador conectado à internet.
          </div>
        </li>

        <li>
          <div class="collapsible-header"> Minha conta tem validade?</div>
          <div class="collapsible-body grey lighten-3">
            Sim, A validade do cartão é determinada pelo gestor de sua empresa.
          </div>
        </li>

        <li>
          <div class="collapsible-header"> Me desliguei da empresa e ainda tenho a conta com {{campanha.moeda | uppercase}}. Posso utilizar mesmo assim?</div>
          <div class="collapsible-body grey lighten-3">
            O mesmo poderá ser utilizado somente com a permissão de seu gestor.
          </div>
        </li>
        <li>
          <div class="collapsible-header"> Eu recebo comprovante? </div>
          <div class="collapsible-body grey lighten-3">
            Sim, o comprovante estará disponível em até 48 horas úteis e ficará guardado por até 45 dias.

          </div>
        </li>

        <li>
          <div class="collapsible-header">Esqueci minha senha eletrônica. O que faço? </div>
          <div class="collapsible-body grey lighten-3">
            Basta acessar o site e clicar em ESQUECI MINHA SENHA. Após este procedimento será enviado um e-mail contendo um LINK, 
            com um link para criar a nova senha.
          </div>
        </li>

        <li>
          <div class="collapsible-header"> Quais tipos de pagamentos posso realizar?</div>
          <div class="collapsible-body grey lighten-3">
            Pode ser realizado o pagamento de contas de consumo (água, luz, telefone, gás, TV a cabo, entre outras.) e boletos bancários com código de barras.
          </div>
        </li>

        <li>
          <div class="collapsible-header"> Para quais contas posso transferir?</div>
          <div class="collapsible-body grey lighten-3">
            Somente contas de mesma titularidade e CPF do cadastro efetuado. Fica a critério de sua empresa liberar as transferências para contas de terceiros ou não.

          </div>
        </li>

        <li>
          <div class="collapsible-header"> É cobrada alguma taxa para transferência?</div>
          <div class="collapsible-body grey lighten-3">
            Sim, o valor de {{campanha.moeda | uppercase}} é cobrado de acordo com a taxa vigente do banco.
          </div>
        </li>

        <li>
          <div class="collapsible-header">Após a transferência, em quanto tempo o valor fica disponível na conta? </div>
          <div class="collapsible-body grey lighten-3">
            Em até 48 horas úteis para transferências efetuadas até as 15h30.
          </div>
        </li>
        <li>
          <div class="collapsible-header"> É cobrado frete para o envio de vale presente? </div>
          <div class="collapsible-body grey lighten-3">
            Sim. O frete é cobrado de acordo com o CEP de destino e a quantidade.
          </div>
        </li>
        <li>
          <div class="collapsible-header"> Em caso de não recebimento do(s) vale(s) presente(s), como devo proceder?</div>
          <div class="collapsible-body grey lighten-3">
            Abra um chamado junto ao SAC para informar o ocorrido e você receberá as devidas instruções. O horário de atendimento é de segunda à sexta, das 9h às 18h.

          </div>
        </li>


      </ul>
    </div>
  </div>