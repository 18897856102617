<div id="accountselect" class="row top-10"></div>
<div id="formContainer" class="editcontainer col s12 m6">

  <form id="formNewAccount" name="formNewAccount" [formGroup]="formNewAccount" action="" class="col s12">
    <h4 [style.font-family]="ws.website.font.family" [style.font-family]="ws.website.font.family"
      class="white-text center">CADASTRAR CONTA</h4>
    <div class="row">
      <div class="input-field col s12">
        <input id="apelido" name="apelido" type="text" class="validate white-text" [formControlName]="'apelido'" />
        <label for="apelido" class="active white-text text-lighten-1">Apelido da Conta</label>
        <!-- <p class="tiny grey-text text-lighten-2" *ngIf="!formTransf.$pristine && formTransf.nome.$invalid">
        <i class="material-icons yellow-text">report_problem</i>
        Preencha o nome do titular.</p> -->
      </div>
    </div>
    <div class="row">
      <h6 class="col s12 white-text">Tipo de Conta</h6>
      <div class="block range-field col s12 m10">
        <!-- <p class="col s4">
          <label for="tipodigital" class="white-text">
            <input required name="tipo" class="with-gap white-text " type="radio" id="tipodigital"
              [formControlName]="'tipo'" value="digital_transf" selected (click)="tipoval = 'digital'" />
            <span>Digital</span></label>
        </p> -->
        <p class="col s4">
          <label for="tipobancaria" class="white-text">
            <input required name="tipo" class="with-gap white-text " type="radio" id="tipobancaria" class="white-text"
              [formControlName]="'tipo'" value="online_transf" (click)="changeTipoConta('bank')" />
            <span>Bancária</span></label>
        </p>
        <p class="col s4">
          <label for="tipopix" class="white-text">
            <input required name="tipo" class="with-gap white-text " type="radio" id="tipopix" class="white-text"
              [formControlName]="'tipo'" value="online_pix" (click)="changeTipoConta('pix')" />
            <span>Pix</span></label>
        </p>
      </div>
    </div>

    

    <div class="row" [hidden]="tipoval === 'pix'">
      <h6 class="col s12 white-text">Tipo de Pessoa</h6>
      <div class="block range-field col s12 m6">
        <p class="col s6">
          <label for="tipopessoa1" class="white-text">
            <input required name="pjpf" class="with-gap white-text " type="radio" id="tipopessoa1"
              [formControlName]="'pjpf'" value="Pessoa Física" selected (click)="pjpfval = 'Pessoa Física'" />
            <span>Física</span></label>
        </p>
        <p class="col s6">
          <label for="tipopessoa2" class="white-text">
            <input required name="pjpf" class="with-gap white-text " type="radio" id="tipopessoa2"
              [formControlName]="'pjpf'" value="Pessoa Jurídica" (click)="pjpfval = 'Pessoa Jurídica'" />
            <span>Jurídica</span></label>
        </p>
      </div>
    </div>
    <div class="row"  *ngIf="pjpfval === 'Pessoa Física'">
      <div class="input-field col s12">
        <input id="documento" name="documento" [textMask]="{mask:masks.maskCPF, guide:false}" type="text"
          class="validate white-text" [formControlName]="'documento'" (keyup)="cpfKeyUp($event)" readonly />
        <label for="documento" class="active white-text text-lighten-1">CPF do
          Titular da Conta</label>
      </div>
    </div>
    <div class="row" *ngIf="pjpfval === 'Pessoa Jurídica'">
      <div class="input-field col s12">
        <input id="documento" name="documento" [textMask]="{mask:masks.maskCNPJ, guide:false}" type="text"
          class="validate white-text" [formControlName]="'documento'" (keyup)="cpfKeyUp($event)" readonly />
        <label for="documento" class="active white-text text-lighten-1" >CNPJ da
          Conta</label>
      </div>
    </div>
    <div class="row" >
      <div class="input-field col s12">
        <input id="beneficiado" name="nome" type="text" class="validate white-text" [formControlName]="'nome'" readonly />
        <label for="beneficiado" class="active white-text text-lighten-1" *ngIf="pjpfval === 'Pessoa Física'">Nome do
          Titular da Conta</label>
        <label for="beneficiado" class="active white-text text-lighten-1" *ngIf="pjpfval === 'Pessoa Jurídica'">Razão
          Social da Conta</label>
        <!-- <p class="tiny grey-text text-lighten-2" *ngIf="!formTransf.$pristine && formTransf.nome.$invalid">
                  <i class="material-icons yellow-text">report_problem</i>
                  Preencha o nome do titular.</p> -->
      </div>
    </div>

    <div class="row" [hidden]="tipoval != 'pix'">
      <h6 class="col s12 white-text">Tipo de Chave</h6>
      <div class="block range-field col s12 m10">
        <p class="col s4">
          <label for="tipochave1" class="white-text">
            <input required name="tipochave" class="with-gap white-text " type="radio" id="tipochave1"
              [formControlName]="'tipochave'" value="email" selected (click)="setChavePix('email')" />
            <span>Email</span></label>
        </p>
        <p class="col s4">
          <label for="tipochave2" class="white-text">
            <input required name="tipochave" class="with-gap white-text " type="radio" id="tipochave2"
              [formControlName]="'tipochave'" value="cpf"  (click)="setChavePix('cpf')" />
            <span>CPF/CNPJ</span></label>
        </p>
        <p class="col s4">
          <label for="tipochave3" class="white-text">
            <input required name="tipochave" class="with-gap white-text " type="radio" id="tipochave3"
              [formControlName]="'tipochave'" value="celular"  (click)="setChavePix('celular')" />
            <span>Celular</span></label>
        </p>
      </div>
      <div class="col s12" *ngIf="chaveval === 'cpf'">
        <input id="pixcpf" name="pixcpf" type="text"
        class="validate white-text" [formControlName]="'chavepix'" [readonly]="true" />
        <label for="chavepix" class="active white-text text-lighten-1">Chave PIX</label>
      </div>

      <div class="col s12" *ngIf="chaveval === 'celular'">
        <input id="pixcelular" name="pixcelular" type="text"
        class="validate white-text" [formControlName]="'chavepix'" [readonly]="true"/>
        <label for="chavepix" class="active white-text text-lighten-1">Chave PIX</label>

      </div>

      <div class="col s12" *ngIf="chaveval === 'email'">
        <input id="pixemail" name="pixemail"  type="email"
        class="validate white-text" [formControlName]="'chavepix'" [readonly]="true" />
        <label for="chavepix" class="active white-text text-lighten-1">Chave PIX</label>

      </div>
    </div>

    <div class=""[hidden]="tipoval != 'bank'">
      <div class="row">
        <div class="block range-field col s12 m6">
          <p class="col s6">
            <label for="tipoconta1" class="white-text">
              <input required name="tipoconta" class="with-gap white-text " type="radio" id="tipoconta1"
                [formControlName]="'tipoconta'" value="CC" selected />
              <span>Conta Corrente</span></label>
          </p>
          <p class="col s6">
            <label for="tipoconta2" class="white-text">
              <input required name="tipoconta" class="with-gap white-text " type="radio" id="tipoconta2"
                [formControlName]="'tipoconta'" value="CP"   />
              <span>Conta Poupança</span></label>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="input-field col s12">
          <select class="browser-default" name="banco" [formControlName]="'banco'">
            <option *ngFor="let banco of banklist" [hidden]="!banco.codigo" [value]="banco.codigo">{{banco.codigo}} - {{banco.nome}}</option>
          </select>

          <label for="banco" class="active white-text text-lighten-1">Banco</label>
          <!-- <p class="tiny grey-text text-lighten-2" *ngIf="!formTransf.$pristine && formTransf.banco.$invalid">
          <i class="material-icons yellow-text">report_problem</i>
          Selecione o banco.</p> -->
        </div>
      </div>
      <div class="row">
        <div class="input-field col s6">
          <input id="agencia" name="agencia" type="text" class="validate white-text" [formControlName]="'agencia'" />
          <label for="agencia" class="active white-text text-lighten-1">Agencia (sem dígito)</label>
          <!-- <p class="tiny grey-text text-lighten-2" *ngIf="!formTransf.$pristine && formTransf.agencia.$invalid">
          <i class="material-icons yellow-text">report_problem</i>
          Preencha a agência.</p> -->
        </div>
        <div class="input-field col s6">
          <input id="conta" name="conta" type="text" class="validate white-text" [formControlName]="'conta'" />
          <label for="conta" class="active white-text text-lighten-1">Conta (com dígito)</label>
          <!-- <p class="tiny grey-text text-lighten-2" *ngIf="!formTransf.$pristine && formTransf.conta.$invalid">
          <i class="material-icons yellow-text">report_problem</i>
          Preencha a conta.</p> -->
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col s12">
        <button [disabled]="!formNewAccount.valid" class="waves-effect waves-light btn col m5 s12 right" [style.backgroundColor]="ws.website?.color1"
          id="bt-nova-conta" (click)="processTransaction()">cadastrar conta</button>
        <a class="btn-flat col m5 s12 right" id="bt-cancel-cartao" (click)="getFormValidationErrors()">cancelar</a>
      </div>
    </div>
  </form>


  <form id="formRefer" name="formRefer" [formGroup]="formRefer" action="" class="col s12">
    <!-- Modal Structure -->
    <div id="modalconvite" class="modal">
      <div class="modal-content">
        <!-- <lottie-player src="https://assets6.lottiefiles.com/datafiles/iMAIoaSAs29w2xR/data.json"  background="transparent"  speed="1"  style="width: 233px;height: 210px;margin-top: -78px;margin-bottom: -31px;"  loop  autoplay></lottie-player> -->
        <img class="col m4 s12 center" src="../../../../assets/images/refer-image.png" alt="">
        <h6 [style.font-family]="ws.website.font.family" class="row center">Este CPF/CNPJ ainda não tem uma conta
          digital {{ws.website.title}}, que tal
          enviar um convite agora?
        </h6>

        <div class="">
          <div class="input-field col s12">
            <input id="nome" name="nome" type="text" class="validate black-text" [formControlName]="'refer_name'" />
            <label for="nome" class="active black-text text-lighten-1">Nome do Convidado</label>
          </div>
          <div class="input-field col s12">
            <input id="email" name="email" type="text" class="validate black-text" [formControlName]="'refer_email'" />
            <label for="email" class="active black-text text-lighten-1">E-mail do Convidado</label>
          </div>
          <div class="input-field col s12">
            <input id="celular" name="celular" type="text" class="validate black-text"
              [formControlName]="'refer_cel'" />
            <label for="celular" class="active black-text text-lighten-1">Celular do Convidado</label>
          </div>
        </div>

        <div class="col s12">
          <div class="home-botoes-modal center-align">
            <button [disabled]="!formRefer.valid" [style.backgroundColor]="ws.website?.color1" (click)="sendRefer()"
              class="btn col s12 m5 modal-close right">Enviar</button>
            <a class="btn-flat col m3 s12 right modal-close" id="bt-cancel-cartao">cancelar</a>
          </div>
        </div>
      </div>
    </div>
  </form>