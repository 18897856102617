import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { Campanha } from 'src/app/services/interfaces/campanha';
import { Service } from 'src/app/services/interfaces/service';
import { Endereco } from '@brunoc/ngx-viacep';
import { Transaction } from 'src/app/services/interfaces/transaction';
import { Status } from 'src/app/services/enum/status.enum';
import { Tipo } from 'src/app/services/enum/tipo.enum';
import { ConfirmService } from 'src/app/services/confirm.service';
import { MaterializeService, AuthService } from '@ollieestudio/fire-lib';
import { Premiado } from 'src/app/services/interfaces/premiado';
import { AddressformComponent } from '../../dados/addressform/addressform.component';
import { WebsiteService } from 'src/app/services/di/website.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { Giftcard } from 'src/app/services/interfaces/giftcard';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {
  // @ViewChild(AddressformComponent) address:AddressformComponent;
  @Output() resetCheckout:EventEmitter<string> = new EventEmitter();
  @Input() campanha:Campanha;
  @Input() service: Service;
  cartList: Giftcard[];
  privatedata:any = {retido:0, produtos:[], frete:null, endereco:{}};
  displaytax: number;

  constructor(public analytics:AnalyticsService, public ws:WebsiteService, public confirm:ConfirmService, public material:MaterializeService, public auth:AuthService<Premiado>) { }

  ngOnInit() {
  }
  
  removeProd(produto, index){
    this.analytics.logEvent('giftcard_remove_product', {userid: this.auth.User.id, product:this.cartList[index]});
    this.cartList.splice(index, 1);
    if(this.cartList.length == 0) {
      this.resetTransaction();
    }else{
      this.checkOut();
    }
  }
  
  
    // resetFrete(){
    //   this.privatedata.frete = null;
    //   this.privatedata.endereco = null;
    //   this.address.reset();
    // }
  // calculaFrete(endereco:Endereco){
  //   this.privatedata.frete = 100;
  //   this.privatedata.endereco = endereco;
  // }

  checkOut(){
    this.privatedata.retido = 0;
    this.cartList.forEach(element => {
        this.privatedata.retido += element.valor*element.quantidade;
      });
      this.displaytax = this.service.tax;
      this.analytics.logEvent('giftcard_begin_checkout', {userid: this.auth.User.id});
  }

  resetTransaction(){
    this.cartList = null;
    this.privatedata =  {retido:0, produtos:[], frete:null, endereco:{}};
    // this.address.reset();
    this.resetCheckout.next('reset');
  }

  processTransaction(){
    this.confirm.resultEmitter.subscribe(result =>{
      if(result === true){
        this.material.toast(this.confirm.generateCheckMessage("Seu resgate foi solicitado com sucesso!"), 5000, 'green full');
        this.analytics.logEvent('giftcard_complete', {userid: this.auth.User.id, product:this.cartList});
        this.resetTransaction();
      }else{
        this.material.toast(this.confirm.generateErrorMessage("Seu resgate não foi processado!"), 5000, 'red full');
        this.analytics.logEvent('giftcard_error', {userid: this.auth.User.id});
        this.resetTransaction();
      }
    })

    let transac:Transaction = {} as Transaction;
    transac.enterprise = this.campanha.enterprise;
    transac.cliente = this.campanha.cliente;
    transac.campanha = this.ws.campanha.id;

    // transac.geo = this.geo.location;
    transac.identificador = `${this.service.title} - ${this.cartList[0].modelo}`;
    transac.status = Status['novo'];
    transac.tipo = Tipo['giftcard'];
    transac.usuario = this.auth.User.id;
    transac.authid = this.auth.User.authid;
    transac.valorout = Math.ceil(this.privatedata.retido) + Math.ceil(this.service.tax || 0);
    this.privatedata.produtos = this.cartList;
    this.privatedata.email = this.auth.User.email;
    if(this.auth.User.pjpf === 'Pessoa Física'){
      this.privatedata.tipopessoa = 'FISICA';
      this.privatedata.nome = this.auth.User.nome;
      this.privatedata.CPF = this.auth.User.documento;
    }
    if(this.auth.User.pjpf === 'Pessoa Jurídica'){
      this.privatedata.tipopessoa = 'JURIDICA';
      this.privatedata.razaoSocial = this.auth.User.nome;
      this.privatedata.CNPJ = this.auth.User.documento;
    }
    
    this.privatedata.ddd = this.auth.User.celular.toString().substr(0,2);
    this.privatedata.celular = this.auth.User.celular.toString().substr(2,9);
    transac.privatedata = this.privatedata;
  
    this.confirm.openConfirm(transac);
    this.resetTransaction();
  }
}
