import { Component, OnInit, ViewChild } from '@angular/core';
import { Tipo } from 'src/app/services/enum/tipo.enum';
import { Service } from 'src/app/services/interfaces/service';
import { WebsiteService } from 'src/app/services/di/website.service';
import { ConfirmService } from 'src/app/services/confirm.service';
import { AuthService } from '@ollieestudio/fire-lib';
import { Premiado } from 'src/app/services/interfaces/premiado';
import { BankFavoritesComponent } from './favorites/favorites.component';
import { BankFormComponent } from './form/form.component';
import { NewaccountComponent } from './newaccount/newaccount.component';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';

@Component({
  selector: 'app-bankline',
  templateUrl: './bankline.component.html',
  styleUrls: ['./bankline.component.css']
})
export class BanklineComponent implements OnInit {
  @ViewChild('digital', { static: true }) favoritesDigital:BankFavoritesComponent;
  @ViewChild('bank', { static: true }) favoritesBank:BankFavoritesComponent;
  @ViewChild('pix', { static: true }) favoritesPix:BankFavoritesComponent;
  @ViewChild(NewaccountComponent, { static: true }) form:NewaccountComponent;
  servicetype: string =  Tipo['bank-transf'];
  service:Service;
  showForm: boolean = true;

  constructor(private scrollService: ScrollToService, public ws:WebsiteService,  public confirm:ConfirmService, public auth:AuthService<Premiado>) 
  { 
   let sub = this.ws.websiteEmitter.subscribe(website =>{
      sub.unsubscribe();
       this.service = {...this.ws.campanha.services[this.servicetype], ...this.ws.services.filter(service => service.type === this.servicetype)[0]};
    })
  }

  ngOnInit() {
    window.scrollTo(0,0);
    this.service = {...this.ws.campanha.services[this.servicetype], ...this.ws.services.filter(service => service.type === this.servicetype)[0]};
    this.confirm.resultEmitter.subscribe(result =>{
      if(result === true){
       this.showForm = true;
       }
    })
   }

   enableAccountForm(){
    this.showForm = false;
    let sub = this.form.hideFormEmitter.subscribe(hide => {
      sub.unsubscribe();
      this.showForm = true;
      this.favoritesDigital.getAccounts(this.auth.User.authid);
      this.favoritesBank.getAccounts(this.auth.User.authid);
      this.favoritesPix.getAccounts(this.auth.User.authid);
    });
    this.scrollService.scrollTo({target:'#formNewAccount'});
  }

}
