import { Component, OnInit, ViewChild } from '@angular/core';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { WebsiteService } from 'src/app/services/di/website.service';
import { ConfirmService } from 'src/app/services/confirm.service';
import { AuthService } from '@ollieestudio/fire-lib';
import { Premiado } from 'src/app/services/interfaces/premiado';
import { Tipo } from 'src/app/services/enum/tipo.enum';
import { Service } from 'src/app/services/interfaces/service';
import { BarcodeScannerLivestreamComponent } from 'ngx-barcode-scanner';
import { PaymentFormComponent } from './form/form.component';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  //@ViewChild(CardsFavoritesComponent) favorites:CardsFavoritesComponent;
  //private selectedCard:Card;
  servicetype: string =  Tipo['payment'];
  service:Service;
  showForm:boolean = true;
  addressForm: any;
  selectedMethod:any;
  barcodeValue;

  @ViewChild(BarcodeScannerLivestreamComponent, { static: true }) barecodeScanner: BarcodeScannerLivestreamComponent;
  @ViewChild(PaymentFormComponent, { static: true }) form:PaymentFormComponent;
  
  constructor(public analytics:AnalyticsService, private scrollService: ScrollToService, public ws:WebsiteService,  public confirm:ConfirmService, public auth:AuthService<Premiado>) { 
    let sub = this.ws.websiteEmitter.subscribe(website =>{
      sub.unsubscribe();
      this.service = {...this.ws.campanha.services[this.servicetype], ...this.ws.services.filter(service => service.type === this.servicetype)[0]};
    })
  }

  ngAfterViewInit() {
  }

  onValueChanges(result){
    this.analytics.logEvent('barcode_start', {userid: this.auth.User.id});
      if(result.codeResult.code === 43 || result.codeResult.code === 44) {
        this.selectedMethod = "type";
        this.form.getCameraResult(result.codeResult.code);
        this.analytics.logEvent('barcode_success', {userid: this.auth.User.id});
      }else{
        this.analytics.logEvent('barcode_error', {userid: this.auth.User.id, data:result});
      }
  }

  onStarted(started){
  }

  ngOnInit() {
    this.service = {...this.ws.campanha.services[this.servicetype], ...this.ws.services.filter(service => service.type === this.servicetype)[0]};
    this.confirm.resultEmitter.subscribe(result =>{
     if(result === true){
      this.showForm = true;
      window.scrollTo(0,0);
      }
   })
  }

  selectMethod(method:any){
    this.selectedMethod = method;
    if(method === 'scan'){
      this.barecodeScanner.valueChanges.subscribe(result => this.onValueChanges(result))
      this.barecodeScanner.start();
    }
  }

  resetMethod(){
    this.selectedMethod = null;
  }

}
