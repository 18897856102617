<div id="register" class="">


  <div class="container row">
    <h5 [style.font-family]="ws.website.font.family"  class="center white-text text-darken-2">Cadastre-se</h5>

    <form [formGroup]="formSignup" class="col s12 m6 push-m3"  (submit)="signup()">
      <div class="row">
        <div class="block range-field col s12 m12">
          <h6 class="center grey-text text-lighten-1" [style.font-family]="ws.website.font.family">
            Por favor informe seu CPF ou CNPJ para iniciar seu cadastro</h6>
          <p class="col ">
            <label for="tipopessoa1" class="white-text" >
              <input required name="pjpf" class="with-gap white-text " type="radio" id="tipopessoa1"
                [formControlName]="'pjpf'" value="Pessoa Física" selected />
              <span>Pessoa Física</span></label>
          </p>
          <p class="col ">
            <label for="tipopessoa2" class="white-text">
              <input required name="pjpf" class="with-gap white-text " type="radio" id="tipopessoa2"
                [formControlName]="'pjpf'" value="Pessoa Jurídica" />
              <span>Pessoa Jurídica</span></label>
          </p>
        </div>
        
        <div class="block input-field col s12 m12" *ngIf="formSignup.controls['pjpf'].value === 'Pessoa Física'" >
          <label class="white-text text-darken-2 active" for="doc">CPF:</label><input [formControlName]="'documento'"
          [textMask]="{mask:maskCPF, guide:false}" [readonly]="!formSignup.controls['pjpf'].value || tempUser" class="string white-text active" type="text" id="doc" (keyup)="getTempUser($event)" />
        </div>
        <div class="block input-field col s12 m12" *ngIf="formSignup.controls['pjpf'].value === 'Pessoa Jurídica'">
          <label class="white-text text-darken-2 active" for="doc">CNPJ:</label><input [formControlName]="'documento'"
          [textMask]="{mask:maskCNPJ, guide:false}" [readonly]="!formSignup.controls['pjpf'].value || tempUser" class="string white-text active" type="text" id="doc" (keyup)="getTempUser($event)" />
        </div>
      </div>
      
      <app-spinner class="col s2 push-s4"  *ngIf="showspinner" ></app-spinner>
      <p [hidden]="!tempUser || codeVerified" *ngIf="tempUser?.status === 'NOVO'" class="small white-text">Localizamos seu pré-cadastro e enviamos um código de validação para o e-mail {{tempUser?.email}} e para o celular {{tempUser?.celular}}</p>
      <p [hidden]="!tempUser || codeVerified" *ngIf="tempUser?.status != 'NOVO'" class="small white-text">Localizamos uma conta ATIVA para esse CPF, por favor tente fazer login ou entre em contato com a empresa solicitante.</p>
      <a class="btn-flat col m2 s12 right white-text" *ngIf="tempUser && tempUser?.status != 'NOVO'" id="bt-cancel-register" [ngxScrollTo]="'#start'">voltar</a>
      <div class="row" [hidden]="!codeVerified || !ws.campanha.customfield">
        <div class="block input-field col s12 m12">
          <label class="white-text active text-darken-2" for="nome">{{ws.campanha.customfield}}:</label><input
            [formControlName]="'codigointerno'" class="string white-text"  type="text" id="codigointerno" />
        </div>
      </div>
      <div class="row" [hidden]="!codeVerified">
        <div class="block input-field col s12 m6">
          <label class="white-text active text-darken-2" for="nome">Nome Completo/Razão Social:</label><input
            [formControlName]="'nome'" class="string white-text"  type="text" id="nome" />
        </div>

        <div class="block input-field col s12 m6">
          <label class="white-text text-darken-2" for="apelido">Apelido:</label><input  [formControlName]="'apelido'"
            class="string white-text" type="text" id="apelido" />
        </div>
      </div>

      <div class="row" [hidden]="!codeVerified">
        <div class="block input-field col s12 m6">
          <label class="white-text active text-darken-2" for="email">Email:</label>
          <input [formControlName]="'email'" 
            class="string white-text" type="email" id="email" />
        </div>

        <div class="block input-field col s12 m6">
          <label class="white-text active text-darken-2" for="celular">Celular:</label><input
            [formControlName]="'celular'" class="string white-text" type="text" id="celular" />
        </div>
      </div>
      <div class="row" [hidden]="!codeVerified">
        <div class="block input-field col s12 m6">
          <label class="white-text text-darken-2" for="password">Senha:</label>
          <input [formControlName]="'password'"  [type]="showPassword ? 'text' : 'password'" class="string white-text"  id="password" #password
          (keyup)="newPasswordValue(password.value)" [autocomplete]="'new-password'"
          (focus)="isfocused = true" (blur)="isfocused = false"
          [(ngModel)]="mypass" />
          
          <i class="grey-text material-icons link suffix" *ngIf="!showPassword" (click)="showPassword = !showPassword">visibility</i>
          <i class="grey-text material-icons link suffix" *ngIf="showPassword" (click)="showPassword = !showPassword">visibility_off</i>
          
          <password-strength-meter (strengthChange)="onPasswordChanged($event)" [minPasswordLength]="6" [password]="mypass"></password-strength-meter>
          <p *ngIf="passwordweak" class="tiny grey-text text-lighten-2">Sua senha não é forte suficiente, evite nome ou números em sequência.</p>
          <app-password-policy
          class="col s12 login-form-row"
          [valueObservable]="passwordValueSub.asObservable()"
          [policies]="policies"
          [hidden]="!isfocused && (formSignup.controls['password'].pristine || formSignup.controls['password'].valid)"
        ></app-password-policy>
         </div>

        <div class="block input-field col s12 m6">
          <label class="white-text text-darken-2" for="password2">Confirmar senha:</label>
          <input [autocomplete]="'new-password'"
            [formControlName]="'password2'" class="string white-text"  [type]="showPassword ? 'text' : 'password'" id="password2" />
          
          <i class="grey-text material-icons link suffix" *ngIf="!showPassword" (click)="showPassword = !showPassword">visibility</i>
          <i class="grey-text material-icons link suffix" *ngIf="showPassword" (click)="showPassword = !showPassword">visibility_off</i>
            
            <p *ngIf="passwordMismatch()" class="tiny grey-text text-lighten-2">As senhas não correspondem.</p>
        </div>
      </div>

      <div class="row" [hidden]="!codeVerified">
          <div class="col s12 ">
            <button type="submit" [disabled]="formSignup.invalid || passwordMismatch() || passwordweak" [style.backgroundColor]="ws.website?.color1" class="waves-effect waves-light btn col m3 s12 right" id="bt-register-1" >cadastrar</button>
            <a class="btn-flat col m2 s12 right white-text" id="bt-cancel-register" [ngxScrollTo]="'#start'">voltar</a>
          </div>
        </div>
    </form>
    <div class="row col s12"  [hidden]="!tempUser || codeVerified || tempUser?.status != 'NOVO'">
        <div class="block input-field col s12  m3 push-m3">
          <label class="white-text active text-darken-2" for="celular">Código de Verificação:</label><input
            [(ngModel)]="verificationCode" class="string white-text" type="text" id="codigo" />
            <p class="small white-text">Informe aqui o código enviado.</p>
        </div>
        <div class="col s12  m3 push-m3">
            <button (click)="checkVerificationCode()"  [disabled]="!tempUser || !verificationCode " [style.backgroundColor]="ws.website?.color1" class="waves-effect waves-light btn col m6 s12 right" id="bt-register-1" >enviar</button>
        </div>
      </div>
  </div>
</div>
