<div class="row s12 section" style="min-height: 40vh;">
  <div class="col s12">

    <select id="mainstatus" [(ngModel)]="status" (change)="changeStatus($event)" class="col m2 s4 right browser-default ">
      <option value="NOVO">NOVO</option>
      <option value="VERIFICADO">VERIFICADO</option>
      <option value="PENDENTE">PENDENTE</option>
      <option value="CONCLUIDO">CONCLUIDO</option>
      <option value="CANCELADO">CANCELADO</option>
    </select>
    
    <app-total-display [items]="extrato" [campovalor]="'valor'" class="col m4 s8
     right"></app-total-display>

    
  </div>
    <table class="table highlight white-text">
      <thead>
        <tr>
            <th></th>
            <th  (click)="orderBy('timestamp')">Data</th>
            <th (click)="orderBy('identificador')">Produto</th>
            <th (click)="orderBy('valor')">Valor</th>
            <th (click)="orderBy('status')">Status</th>
        </tr>
      </thead>
  
     <tbody>
      <tr class="row" *ngFor="let transaction of extrato ">
        <td>
          <i  [style.color]="ws.website?.color1" style="font-size: 16px;" *ngIf="transaction.valorin" class="material-icons">add</i>
          <i  [style.color]="ws.website?.color1" style="font-size: 16px;" *ngIf="transaction.valorout" class="material-icons">remove</i>
        </td>
        <td>{{transaction.data | date:"dd/MM"}}</td>
        <td>{{transaction.produto.nome || transaction.produto | shorten:30}}</td>
        <td>{{transaction.valor || transaction.valorin}}</td>
        <td ng-class="{'yellow-text text-darken-2': transaction.status == 'NOVO',
        'green-text': transaction.status == 'valid',
        'blue-text': transaction.status == 'CONCLUIDO',
        'orange-text': transaction.status == 'PROCESSANDO',
        'red-text': transaction.status == 'ERRO'}">{{transaction.status}}
        </td>
        <td>
        <i   (click)="downloadReceipt(transaction);" *ngIf="transaction.status == 'CONCLUIDO' && transaction?.privatedata?.result"  class="material-icons white-text link modal-trigger" >receipt</i>
        </td>
      </tr>
     </tbody>
    </table>