import { Component, OnInit, Input, SecurityContext } from '@angular/core';
import { Website } from 'src/app/services/interfaces/website';
import { WebsiteService } from 'src/app/services/di/website.service';
import { Section } from "src/app/services/interfaces/section";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-sections',
  templateUrl: './sections.component.html',
  styleUrls: ['./sections.component.css']
})
export class SectionsComponent implements OnInit {
  public website:Website;
  captchaResolved: boolean;

  constructor(public ws:WebsiteService) { }

  ngOnInit() {
    let sub = this.ws.websiteEmitter.subscribe(website => {
      sub.unsubscribe();
      if(!this.website) {
        this.website = this.ws.website
        }
      })
  }

  captcha(event:boolean){
    this.captchaResolved = event;
  }
}
