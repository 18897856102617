import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-jsontable',
  templateUrl: './jsontable.component.html',
  styleUrls: ['./jsontable.component.css']
})
export class JsontableComponent implements OnInit {
@Input() data:any[];
@Input() fieldlist:string[];
         rows:any[] = [];

  constructor() { }

  ngOnInit(): void {
   
  }

}
