<app-menu-institucional *ngIf="website" [website]="website"></app-menu-institucional>
<div class="outlet-section-institucional" *ngIf="website" [style.backgroundColor]="website?.color2">
<div id="start"></div>
  <router-outlet >
  </router-outlet>
</div>
<app-popup #popup ></app-popup>

<app-chatbutton id="botbutton_home" ></app-chatbutton>


<div id="recaptcha-container" class="center"></div>
<app-spinner *ngIf="!website" class="institucional" ></app-spinner>
<app-footer *ngIf="website"  [website]="website"></app-footer>