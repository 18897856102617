import { Component, OnInit, NgZone } from '@angular/core';
import { Website } from './services/interfaces/website';
import { WebsiteService } from './services/di/website.service';
import { ConfigService, AuthService, CookieService, DatabaseService } from '@ollieestudio/fire-lib';
import { environment } from 'src/environments/environment';
import { Router, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { AnalyticsService } from './services/analytics.service';
import { AppUpdateService } from './services/di/app-update.service';
import packageJson from '../../package.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit{
  public website:Website;
  public version: string = packageJson.version;
  title = "";

  constructor(public analytics:AnalyticsService, public config:ConfigService, public router:Router, public zone:NgZone, public ws:WebsiteService, private update:AppUpdateService){
    config.setAuthConfig(environment.firebaseAuth,['/institucional/sections'],['./home'],'/institucional/sections','/institucional/sections', '/institucional/sections',this.router );
    config.setDatabaseConfig(environment.firebaseAuth);
    config.setGeoConfig(environment.firebaseAuth);
    config.setMessagingConfig(environment.firebaseAuth);
    config.setSmsConfig("emais.sms", "");
    config.setDataPath("SMARTPONTOS/DATA");

    let database:DatabaseService<Website> = new DatabaseService(config, zone, null);
    this.ws.init();
  }
   
  
  ngOnInit(): void {
    let sub = this.ws.websiteEmitter.subscribe(website =>{
      sub.unsubscribe();
      this.title = website.title;
     // this.router.navigateByUrl('');
    });
    console.log(this.version)
  }
  
  

}
