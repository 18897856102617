import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if(!items) return [];
    if(!searchText) return items;
searchText = searchText.toLowerCase();
if(items[0].subcategorias){
  searchText = this.cleanUp(searchText);
}
return items.filter( it => {
      return JSON.stringify(it).toLowerCase().includes(searchText);
    });
   }

  cleanUp(searchText: string): string {
    let result = searchText.split(" ").join("-");
    result = this.retira_acentos(result);
    return result;
  }

    retira_acentos(str){

      let com_acento = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";
      let sem_acento = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
      
      let novastr="";

      for(var i=0; i<str.length; i++) {
          let troca=false;
          for (var a=0; a<com_acento.length; a++) {
              if (str.substr(i,1)==com_acento.substr(a,1)) {
                  novastr+=sem_acento.substr(a,1);
                  troca=true;
                  break;
              }
          }
          if (troca==false) {
              novastr+=str.substr(i,1);
          }
      }
      return novastr;
  } 
}