import { Component, OnInit } from '@angular/core';
import { ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { AuthService, DatabaseService, FormService, MaterializeService } from '@ollieestudio/fire-lib';
import { ConfirmService } from 'src/app/services/confirm.service';
import { WebsiteService } from 'src/app/services/di/website.service';
import { Tipo } from 'src/app/services/enum/tipo.enum';
import { Premiado } from 'src/app/services/interfaces/premiado';
import { Service } from 'src/app/services/interfaces/service';
import { VoucherService } from './voucher.service';
import { Transaction } from 'src/app/services/interfaces/transaction';
import { Status } from 'src/app/services/enum/status.enum';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.css']
})
export class VoucherComponent implements OnInit {

  servicetype: string =  Tipo['voucher'];
  service:Service;
  showForm: boolean;
  vouchers: Transaction[];
  public showLista:boolean = false;
  
  constructor(public ws:WebsiteService, private analytics:AnalyticsService, private material:MaterializeService, public database:DatabaseService<Transaction>, private voucherservice:VoucherService, public confirm:ConfirmService, public auth:AuthService<Premiado>) {
    let sub = this.ws.websiteEmitter.subscribe(website =>{
      sub.unsubscribe();
       this.service = {...this.ws.campanha.services[this.servicetype], ...this.ws.services.filter(service => service.type === this.servicetype)[0]};
    })
   }

  async ngOnInit() {
    window.scrollTo(0,0);
    this.service = {...this.ws.campanha.services[this.servicetype], ...this.ws.services.filter(service => service.type === this.servicetype)[0]};
    this.confirm.resultEmitter.subscribe(result =>{
      if(result === true){
       this.showForm = true;
       }
    })
    this.vouchers = await this.voucherservice.getVouchersPremiado(this.auth.User.codigointerno || this.auth.User.documento)
  }

  toggleShowlista(state: boolean) {
   this.showLista = state;
    }

  
    processRecarga(transaction:Transaction){
      transaction.status = Status['sucesso'];
      transaction.usuario = this.auth.User.id;
      transaction.authid = this.auth.User.authid;
      
      this.database.update(transaction, 'TRANSACTIONS').then(result =>{
        this.material.toast(this.confirm.generateCheckMessage('Seu resgate de voucher foi enviado!'), 5000, 'green full');
        this.analytics.logEvent('voucher_success', {userid: this.auth.User.id});
      }).catch(err =>{
        this.material.toast(this.confirm.generateCheckMessage('Houve um erro ao processar o resgate de voucher.'), 5000, 'red full');
        this.analytics.logEvent('voucher_error', {userid: this.auth.User.id});
      })
    }

}
