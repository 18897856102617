import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { InputBase, AuthService, FormService, MaterializeService, MaskService, DatabaseService, CookieService } from '@ollieestudio/fire-lib';
import { WebsiteService } from 'src/app/services/di/website.service';
import { Status } from 'src/app/services/enum/status.enum';
import { Contato } from 'src/app/services/interfaces/contato';
import { Helpdesk } from 'src/app/services/interfaces/helpdesk';
import { Premiado } from 'src/app/services/interfaces/premiado';

@Component({
  selector: 'app-helpdesk',
  templateUrl: './helpdesk.component.html',
  styleUrls: ['./helpdesk.component.css']
})
export class HelpdeskComponent implements OnInit {
  @Output() closer:EventEmitter<boolean> = new EventEmitter();
  public formContato:FormGroup;
  public assuntos:string[];

  private fields:InputBase[] = []

  formstep: number;

constructor(public auth:AuthService<Premiado>, public ws:WebsiteService, public cookies:CookieService<any>, public forms:FormService, public material:MaterializeService, public mask:MaskService, public database:DatabaseService<Contato>, ) {
  
  this.formContato = this.forms.toFormGroup([
    {key:"assunto", required:true},
    {key:"mensagem", required:true},
    {key:"nome", required:false},
    {key:"email", required:false},
    {key:"celular", required:false},
    ]);
 }

  ngOnInit(): void {
   
      this.assuntos = [
        'Cadastro de dados pessoais',
        'Dúvidas sobre PIX',
        'Dúvidas sobre Transferências',
        'Dúvidas sobre Produtos',
        'Dúvidas sobre Recargas',
        'Dúvidas sobre Cartões',
        'Dúvidas sobre Vale-presentes',
        'Habilitar-Desabilitar o App',
        'Termos de uso',
        'Dúvida geral',
      ]

  }

  

   close(){
    this.closer.next(true);
  }

  registerContato(){
    let message:Helpdesk = this.formContato.getRawValue();
    message.enterprise = this.ws.website.enterprise;
    message.cliente = this.ws.website.cliente;
    message.nomecliente = this.ws.campanha.nome_cliente;
    message.usuario = this.auth.User?.id || "";
    if(!message.nome) message.nome = this.auth.User?.nome || "";
    if(!message.email) message.email = this.auth.User?.email || "";
    if(!message.celular) message.celular = this.auth.User?.celular || "";
    message.status = Status['novo'];

    message.perfil = "premiado";
    message.tipo = 'webapp';
    message.chat = [{
        usuario:this.auth.User?.id  || "",
        dataenvio: new Date().getTime(),
        texto: message.mensagem,
        status: 'NOVO',
        origem:'usuario'
      }];

    this.database.add(message,'HELPDESK').then(result =>{
      this.material.toast(`Sua mensagem foi enviada com sucesso!`, 5000, 'green full');
      this.setCookies();
      this.formContato.reset();
      }).catch( error => console.error(error))
  }

  setCookies() {
    let helpcookie:any = {}
    helpcookie.expires = new Date().getTime() + 7200000;
    this.cookies.set(helpcookie, 'smarthelpdesk');
    this.close();
  }
}