<div id="top" class="toppad">
  &nbsp;
</div>
<div class="container top-5">
  <div class="row" *ngIf="service">
    
    <h3 [style.font-family]="ws.website.font.family"  class="col s12 m11 white-text upper"><i class="material-icons">{{service.icon}}</i>&nbsp;{{service.title}}
    </h3>
  </div>

  <div class="row" [hidden]="true">
    <app-gift-cart></app-gift-cart>
  </div>

  <div class="row" [hidden]="!hideCart">
    <app-checkout [service]="service" [campanha]="ws.campanha"></app-checkout>
  </div>

  <div class="row" [hidden]="hideCart">
    <div class="input-field searchbox">
      <label for="search" class=" col s2 left"><i class="material-icons">search</i></label>
      <input id="search" class="validate white-text col s12 " [(ngModel)]="searchStr"  />
    </div>

    <app-gift-catalog [hidden]="hideCart" [searchStr]="searchStr"></app-gift-catalog>
    <app-detail></app-detail>
  </div>