import { Component, Input, OnInit } from '@angular/core';
import { get } from 'scriptjs';
import { CheckoutService } from 'src/app/services/checkout.service';
import { WebsiteService } from 'src/app/services/di/website.service';
import { Transaction } from 'src/app/services/interfaces/transaction';

@Component({
  selector: 'app-mercadopago-button',
  templateUrl: './mercadopago-button.component.html',
  styleUrls: ['./mercadopago-button.component.css']
})
export class MercadopagoButtonComponent implements OnInit {
  @Input() transac:Transaction;
  
  init_point: any;
  
  preference = {
    items: []
  };

  constructor(private checkoutService: CheckoutService, public ws:WebsiteService) { }

  ngOnInit(): void {

    get("https://www.mercadopago.com.ar/integrations/v1/web-payment-checkout.js", () => {
      //library has been loaded...
    });
  }

  onBuy() {
    let item:any=  {
      title: 'Carga de Pontos ' + this.transac.identificador ,
      unit_price: this.transac.valorin+this.transac.privatedata['taxa'],
      quantity: 1,
      notification_url:"https://us-central1-smartpontos-develop.cloudfunctions.net/mpconfirm?uid="+this.transac.id
  }

    this.preference.items.push(item);
    this.checkoutService.goCheckOut(this.preference).subscribe(result => {
      // Read result of the Cloud Function.
      this.init_point = result['body'].init_point;
      window.open(this.init_point, "_blank");
      // window.location.href = this.init_point;
    },
    error =>{
    })
  }
}

/* part of the https://medium.com/@jolvinisland/mercadopago-angular-ddfbe875c6a1 article */