import { Component, OnInit } from '@angular/core';
import { DatabaseService, CookieService } from '@ollieestudio/fire-lib';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-prospect',
  templateUrl: './prospect.component.html',
  styleUrls: ['./prospect.component.css']
})
export class ProspectComponent implements OnInit {
  message:any = {};
  private sub: any;
  public prospectinfo:any = {};

  constructor(public analytics:AnalyticsService, public cookies:CookieService<any>, public database:DatabaseService<any>, private route: ActivatedRoute, private router:Router) { }

  ngOnInit() {

    this.sub = this.route.params.subscribe(params => {
      this.setCookies(params['messageid'], params['prospectid']);
      this.database.get(params['messageid'], 'PROSPECTS/'+params['prospectid']+'/MESSAGES', "default").subscribe(result =>{
        this.message = result.data();
        if(this.message && this.message.status != "CLICADO"){
          this.message.status = 'CLICADO'; 
          this.message.dataclicado = new Date().getTime(); 
          this.database.update(this.message, 'PROSPECTS/'+params['prospectid']+'/MESSAGES').then();
        }
      })
      this.router.navigateByUrl("/");
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  setCookies(messageid, prospectid) {
    this.prospectinfo.lastlogin = new Date().getTime();
    this.prospectinfo.messageid = messageid;
    this.prospectinfo.prospectid = prospectid;
    this.prospectinfo.cookiename = 'prospectinfo';
    
    this.analytics.logEvent('prospect_message', {prospectid:prospectid, messageid:messageid});
    this.cookies.set(this.prospectinfo, this.prospectinfo.cookiename);
  }

}
