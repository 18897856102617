<div class="page" #page id="page">
    <!-- <app-item-voucher *ngFor="let voucher of vouchers" [voucher]="voucher"></app-item-voucher> -->

    <div class="voucher row" *ngFor="let voucher of vouchers">
        <div class="col m7 instrucoesvoucher" [style.backgroundColor]="voucher.color1">
            <h5 class="bold" class="white-text">{{voucher.title}}</h5>
            <img class="responsive-img logo-voucher" src="{{voucher.logo}}" alt=""/>
            <div class="col m12 white-text">
                <p class="white-text">1) Acesse o site <a target="_blank" href="`https://${layout.url}/`">{{voucher.url}}</a></p>
                <p class="white-text">2) Clique em PRIMEIRO ACESSO</p>
                <p class="white-text">3) Informe seu CPF e siga as instruções de CADASTRO</p>
                <p class="white-text">4) Ao concluir o cadastro vá em RESGATE VOUCHER no menu</p>
                <p class="white-text">5) Siga as instrucões da tela para resgatar seu voucher</p>
                <p class="white-text">6) Dúvidas e solicitações: <a target="_blank" href="mailto:sac@emaiswallet.com">sac&#64;emaiswallet.com</a></p>
            </div>
        </div>
        <div class="col m5 valores white" [style.borderColor]="voucher.color2">
            <div class="col m12 qrcode">
                <img src="https://api.qrserver.com/v1/create-qr-code/?size=140x140&data={{voucher.url}}" alt="">
                <!-- <qrcode [qrdata]="voucher.url" [width]="180" [errorCorrectionLevel]="'M'"></qrcode> -->
            </div>
            <div class="col m12 valorvoucher center">
                <div  class="bold row black-text" style="border: 1px solid black; padding: 0.2cm 0.2cm; margin-bottom: 0;" >
                    <span class="col m8 right-align" style="font-size: 3.8rem; margin: 0; line-height: auto; ">{{voucher.valor}}</span> 
                    <span class="bold col m4 right black-text left-align" style="font-size: 1.64rem;margin: 0;" >{{voucher.moeda}}</span>
                    <h5 class="center col s12 codigovoucher"  style="margin: 0;">{{voucher.codigo}}</h5>
                    <span class="black-text center col s12">código voucher</span>
                </div>
                
            </div>
        </div>
    </div>
</div>