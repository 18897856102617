import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  

  constructor() { }

  printReceipt(transaction){
    let resultData
    try {
      switch (transaction.tipo) {
        case 'online_valepresente':
          resultData = {'IDENTIFICADOR': transaction.identificador,
          ...transaction?.privatedata?.result?.PINCREDENTIALS,
          'DATA/HORA':transaction?.privatedata?.result?.SERVERDATETIME,
          //  'RESULTADO':transaction?.privatedata?.result?.RESULTTEXT,
          'TXID':transaction?.privatedata?.result?.TXID,
             '*INSTRUÇÕES':transaction?.privatedata?.produtos[0]?.INFOS[0]?.DESCRIPTION_REDEMPTION
          } ;
          break;
        default:
          resultData = JSON.parse(transaction?.privatedata?.result || transaction['result']);
          break;
      }
      
      
    } catch (error) {
      resultData = transaction?.privatedata?.result || transaction['result'];
    }

    let result = [];

    if(resultData){
      Object.keys(resultData).forEach(key =>{
        if (key != 'account' &&
        key != 'datacadastro' &&
        key != 'usuario' &&
        key != 'fee' &&
        key != 'tags' &&
        key != 'transactionIds' &&
        key != 'bgcolor' 
        ) {
          var nObj:any = {};
          nObj.label = key;
          nObj.value = resultData[key].toString();
          
          if(key === 'amount'){
            nObj.value = nObj.value.substr(0, nObj.value.length-2 ) + '.' + nObj.value.substr(nObj.value.length-2, nObj.value.length-1) ;
          }
          result.push(nObj);
        }
      })
    }

    let doc = new jsPDF('p','pt', 'a4'); 
    doc.setFontSize(14);
    doc.text( 'COMPROVANTE', 20, 30);
    doc.setFontSize(10);

    if(transaction.detalhe){
      doc.text('Identificador', 20, 70);
      if(transaction.detalhe) doc.text(transaction.detalhe, 150, 70);
    }

    result.forEach((element, ind) => {
      let line = 95 + 25*ind;
      doc.text(element.label, 20, line);
      doc.text(element.value, 150, line);
    });
    
    doc.save(`${'comprovante'+ transaction.identificador +'.pdf'}`);
  }
}
