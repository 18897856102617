import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService, InputBase, MaterializeService, FormService, DatabaseService } from '@ollieestudio/fire-lib';
import { Premiado } from 'src/app/services/interfaces/premiado';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { WebsiteService } from 'src/app/services/di/website.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ConfirmService } from 'src/app/services/confirm.service';
import { Transaction } from 'src/app/services/interfaces/transaction';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Status } from 'src/app/services/enum/status.enum';

@Component({
  selector: 'app-dados-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class DadosFormComponent implements OnInit {
  @ViewChild('confirm', { static: true }) confirmInput:ElementRef;
  mypass:string;
  premiado:Premiado;

  public formProfile:FormGroup;
  public maskCPF = [/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/];
  public maskCNPJ = [/[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/];
  public maskCelular = [/[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/,  ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
 public showPassword:boolean = false;
 
  private fields:InputBase[] = [
    {key:"email", required:true},
    {key:"password", required:false},
    {key:"nome", required:true},
    {key:"apelido", required:true},
    {key:"celular", required:true},
    {key:"pjpf", required:true},
    {key:"documento", required:true},
    {key:"password2", required:false},
  ]

  constructor(public http:HttpClient, public analytics:AnalyticsService,  public confirm:ConfirmService,  public material:MaterializeService, public router:Router, public formserv:FormService, public auth:AuthService<Premiado>, public ws:WebsiteService, public database:DatabaseService<Premiado>) {
      this.formProfile = this.formserv.toFormGroup(this.fields)
   }

   ngOnInit(): void {
    this.premiado = this.auth.User;
    this.formProfile.patchValue(this.premiado)
  }

  savedata(code:number){
    let user = {...this.auth.User, ...this.formProfile.getRawValue()} as Premiado;
    user['codigo'] = code;
    user.status = Status['update'];
    this.material.closeModal('#modal-confirm');
    this.material.closeModal('#modal-confirm');
    this.confirmInput.nativeElement.value = "";

    this.database.update(user,'PREMIADOS').then(result =>{
      this.material.toast(`${this.auth.User.nome }, seus dados foram atualizados!`, 5000, 'green full');
      this.analytics.logEvent('profile_update', {userid: this.auth.User.id});
      this.router.navigateByUrl('/home/start');
      }).catch( error => console.error(error))
  }

  openConfirm(){
    this.material.initModal('#modal-confirm');
    this.material.openModal('#modal-confirm');
    var url = environment.rooturl + "/validaDoc?q=" + this.auth.User.documento + "&t=" + this.ws.website.cliente;
    this.http.get(url).subscribe(result =>{
    },(error) =>{
      this.material.toast(`Hove um erro em sua solicitação!`, 5000, 'red full');
     })
  }
  
}
