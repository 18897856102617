<!-- <div oc-lazy-load="['libs/materialize-stepper/materialize-stepper.min.css', 'libs/angular/angular-viacep.min.js','libs/angular/exif-restorer.js','libs/angular/camera.js','libs/qrcode.min.js']"></div> -->
<app-menu *ngIf="website"></app-menu>
<div class="outlet-section"  [style.backgroundColor]="website.color2">
    <router-outlet>

    </router-outlet>
</div>

<app-messagebox hidenewmessage="hideNewMessage" [hidden]="true"></app-messagebox>
<app-messagebutton [hidden]="true"></app-messagebutton>

<app-chatbutton id="botbutton_interno" ></app-chatbutton>

<app-footer *ngIf="website" [website]="website"></app-footer>
<div *ngIf="ws.checkDemo();" class="demo_disclaimer red white-text">Este é um ambiente de teste, todos os pontos e resgates são fictícios.</div>
<!-- Modal type Ok 
<div id="modal-confirm" class="modal bottom-sheet confirmpanel">
    <div class="modal-content" >-->
        <app-confirmpanel source="transaction" visible="showConfirmForm"></app-confirmpanel>
        <app-popup #popup ></app-popup>
    <!-- </div>
</div> -->
