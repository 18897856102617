<div class="row s12 section">
  <table class="table highlight white-text">
    <thead>
      <tr>
          <th></th>
          <th  (click)="orderBy('timestamp')">Data</th>
          <th (click)="orderBy('identificador')">Identificador</th>
          <th (click)="orderBy('valor')">Valor</th>
          <th (click)="orderBy('status')">Status</th>
      </tr>
    </thead>

   <tbody>
    <tr class="row" [hidden]="transaction.status ==='CANCELADO' && hidetransacs" *ngFor="let transaction of extrato | orderBy:'-timestamp' | filter: searchStr">
      <td>
        <i  [style.color]="ws.website?.color1" style="font-size: 16px;" *ngIf="transaction.valorin" class="material-icons">add</i>
        <i  [style.color]="ws.website?.color1" style="font-size: 16px;" *ngIf="transaction.valorout" class="material-icons">remove</i>
      </td>
      <td>{{transaction.datacriacao | date:"dd/MM"}}</td>
      <td>{{transaction.identificador | shorten:16}}</td>
      <td>{{transaction.valorout || transaction.valorin}}</td>
      <td ng-class="{'yellow-text text-darken-2': transaction.status == 'NOVO',
      'green-text': transaction.status == 'valid',
      'blue-text': transaction.status == 'CONCLUIDO',
      'orange-text': transaction.status == 'PROCESSANDO',
      'red-text': transaction.status == 'ERRO'}">{{transaction.status}}
      </td>
      <td>
      <i   (click)="downloadReceipt(transaction);" *ngIf="transaction.status == 'CONCLUIDO' && transaction?.privatedata?.result"  class="material-icons white-text link modal-trigger" >receipt</i>
      <i   (click)="checkStatus(transaction);" *ngIf="transaction.status == 'PROCESSANDO' && transaction?.privatedata?.result"  class="material-icons white-text link modal-trigger" >refresh</i>
      </td>
    </tr>
   </tbody>
  </table>