<div id="formcontainer" class="editcontainer col s12 m8">
    <form id="formSales" name="formSales" [formGroup]="formSales" action="" class="col s12">
      <h4 [style.font-family]="ws.website.font.family"  class="white-text">NOVA VENDA</h4>
      <!-- <img src="{{imgsrc}}" alt="" class="responsive_img" style="max-height:80px" /> -->
  
      <!-- <barcoder transaction="transaction" barcode="barcode" result="erro_c"/> -->
  
      <div class="row">
        <div class="input-field col s6">
          <input min="47" max="48" id="pedido" name="pedido" type="text" class="validate white-text"
            [formControlName]="'pedido'" />
          <label for="codigo" class="active white-text text-lighten-1">Número do Pedido</label>
          <!-- <p class="tiny grey-text text-lighten-2" ng-show="!formSales.$pristine && formSales.codigo.$invalid">
              <i class="material-icons yellow-text">report_problem</i>
              Digite, cole ou fotografe o código de barras/linha digitável sem espaços.</p> -->
        </div>
        <div class="row">
          <div class="input-field col s6" >
            <input id="codigo_loja" name="codigo_loja" type="text" class="validate white-text"
              [formControlName]="'codigo_loja'" />
            <label for="codigo_loja" class="active white-text text-lighten-1">Código da Loja</label>
            <!-- <p class="tiny grey-text text-lighten-2" ng-show="!formSales.$pristine && formSales.produto.$invalid">
                <i class="material-icons yellow-text">report_problem</i>
                Informe o cedente ou convênio do pagamento.</p> -->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="input-field col s12">
          <select class="browser-default black-text" name="produto" [formControlName]="'produto'"  (change)="selectProduto($event)">
            <option *ngFor="let produto of produtos" value="{{produto | json}}">{{produto.nome}} </option>
          </select>

          <label for="produto" class="active white-text text-lighten-1">Produto</label>
          <!-- <p class="tiny grey-text text-lighten-2" ng-show="!formSales.$pristine && formSales.produto.$invalid">
              <i class="material-icons yellow-text">report_problem</i>
              Informe o cedente ou convênio do pagamento.</p> -->
        </div>
      </div>
      
  
  
      <div class="row">
     
        <div class="input-field col s6">
          <input required id="data" ng-readonly="true" name="data"
            type="date" class="validate white-text"
            [formControlName]="'data'" />
          <label for="data" class="active white-text text-lighten-1">Data da Venda</label>
          <!-- <p class="tiny grey-text text-lighten-2" ng-show="!formSales.$pristine && formSales.data.$invalid">
              <i class="material-icons yellow-text">report_problem</i>
              Preencha a data de pagamento.</p> -->
        </div>

        <div class="input-field col s6">
            <input required id="valor" readonly name="valor"
               type="number" class="validate white-text"
              [formControlName]="'valor'" />
            <label for="valor" class="active white-text text-lighten-1">Pontuação</label>
            <!-- <p class="tiny grey-text text-lighten-2" ng-show="!formSales.$pristine && formSales.data.$invalid">
                <i class="material-icons yellow-text">report_problem</i>
                Preencha a data de pagamento.</p> -->
          </div>
      </div>
  
      <div class="row">
        <div class="col s12">
          <button class="waves-effect waves-light btn col m3 s12 right"  [style.backgroundColor]="ws.website?.color1"
          [disabled]="!formSales.valid" id="bt-payment"
            (click)="processvendation()">enviar</button>
          <a class="btn-flat col m3 s12 right" id="bt-cancel-payment" (click)="formSales.reset()">cancelar</a>
        </div>
      </div>
    </form>
  
  </div>