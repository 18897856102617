import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { CheckoutComponent } from '../giftcard/checkout/checkout.component';
import { Tipo } from 'src/app/services/enum/tipo.enum';
import { Service } from 'src/app/services/interfaces/service';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { WebsiteService } from 'src/app/services/di/website.service';
import { ConfirmService } from 'src/app/services/confirm.service';
import { AuthService } from '@ollieestudio/fire-lib';
import { Premiado } from 'src/app/services/interfaces/premiado';
import { ShopCatalogComponent } from './catalog/catalog.component';
import { ShopCartComponent } from './cart/cart.component';
import { ShopCheckoutComponent } from './checkout/checkout.component';
import { Produto } from 'src/app/services/interfaces/produto';
import { DetailShopComponent } from './detail/detail.component';
import { Subscription } from 'rxjs';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.css']
})

export class ShopComponent implements OnInit {

  @ViewChild(ShopCatalogComponent, { static: true }) catalog:ShopCatalogComponent;
  @ViewChild(ShopCartComponent, { static: true }) cart:ShopCartComponent;
  @ViewChild(ShopCheckoutComponent, { static: true }) checkout:ShopCheckoutComponent;
  @ViewChild(DetailShopComponent, { static: true }) detail:DetailShopComponent;
  servicetype: string =  Tipo['shop'];
  service:Service;
  public hideCart:boolean = false;
  searchStr: string;
  hideMenu:boolean = true;
  selectedProduct: Produto;
  subs: Subscription;
  isMobile:boolean;
  
  constructor(public analytics:AnalyticsService, private scrollService: ScrollToService, public ws:WebsiteService,  public confirm:ConfirmService, public auth:AuthService<Premiado>)
   { 
    this.getScreenSize();
   let sub =  this.ws.websiteEmitter.subscribe(website =>{
    sub.unsubscribe();
      this.service = {...this.ws.campanha.services[this.servicetype], ...this.ws.services.filter(service => service.type === this.servicetype)[0]};
    })
   }

   scrHeight:any;
    scrWidth:any;

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
          this.scrHeight = window.innerHeight;
          this.scrWidth = window.innerWidth;
          this.isMobile = this.scrWidth < 600;
    }

   ngOnInit() {
    this.service = {...this.ws.campanha.services[this.servicetype], ...this.ws.services.filter(service => service.type === this.servicetype)[0]};
    this.confirm.resultEmitter.subscribe(result =>{
      if(result === true){
      //  this.showForm = true;
       window.scrollTo(0,0);
       this.cart.cartList = [];
       this.checkout.cartList = [];
       this.catalog.getVitrine('MAGAZINE');
       }
    })

    this.detail.productEmitter.subscribe(addcard =>{
      this.analytics.logEvent('add_to_cart', {userid: this.auth.User.id, produto:addcard});
      this.cart.addProduct(addcard);
      this.searchStr = "";
    })

    this.catalog._selectProduct.subscribe(produto => this.selectProduct(produto));
    this.catalog.hideMenuEmitter.subscribe(hideMenu => this.hideMenu = hideMenu);
    this.checkout.resetCheckout.subscribe(() =>{
      this.hideCart = false;
    })
    this.cart.checkoutEmitter.subscribe(checkout =>{
      this.analytics.logEvent('checkout_begin', {userid: this.auth.User.id});
      this.checkout.cartList = this.cart.cartList;
      this.checkout.checkOut();
      this.hideCart = true;
    })
   }

   selectProduct(product:Produto){
    this.selectedProduct = product;
    this.detail.displayDetail(product);
    this.analytics.logEvent('view_item', {userid: this.auth.User.id});
   }

}
