import { Component, OnInit } from '@angular/core';
import { Service } from 'src/app/services/interfaces/service';
import { Tipo } from 'src/app/services/enum/tipo.enum';
import { WebsiteService } from 'src/app/services/di/website.service';

@Component({
  selector: 'app-getpoints',
  templateUrl: './getpoints.component.html',
  styleUrls: ['./getpoints.component.css']
})
export class GetpointsComponent implements OnInit {
  service:Service;
  servicetype: string =  Tipo['compra-pontos'];
  
  constructor(public ws:WebsiteService) { 
    this.service = {...this.ws.campanha.services[this.servicetype], ...this.ws.services.filter(service => service.type === this.servicetype)[0]};
  }
  
  ngOnInit(): void {
    let sub = this.ws.websiteEmitter.subscribe(website =>{
      sub.unsubscribe();
       this.service = {...this.ws.campanha.services[this.servicetype], ...this.ws.services.filter(service => service.type === this.servicetype)[0]};
    })
  }

}
