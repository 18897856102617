<div id="formNovoCartaoContainer" class="editcontainer col s12 m9 top-5">
  <h4 [style.font-family]="ws.website.font.family"  class="white-text">NOVO CARTÃO</h4>
  <div class="row col s12">
    <ul>
      <li *ngFor="let card of cards" [hidden]="selectedCard != null && selectedCard != card">
        <div (click)="selectCard(card)" [ngxScrollTo]="'#formnewcard'" class="range-field col m4 s12 cards">
          <h5 [style.font-family]="ws.website.font.family"  class="center col s12 white-text">{{card.identificador}}</h5>
          <p class="center col s12 white-text small-text">{{card.bandeira}}</p>
          <label for="test{{$index}}" class="white-text">
            <img src="{{card.imagem}}" class="responsive-img" />
          </label>
          <p class="left col s12 white-text small-text">{{card.texto}}</p>
        </div>
      </li>
    </ul>
    <!-- <p class="tiny grey-text text-lighten-2" [hidden]="!formNovoCartao.$pristine && formNovoCartao.tipocard.$invalid">
      <i class="material-icons yellow-text">report_problem</i> Selecione o tipo do cartão.</p> -->
  </div>
  <div id="formnewcard" class="row top-10"></div>
  <form id="formNovoCartao" name="formNovoCartao" [formGroup]="formNovoCartao" action="" class="col s12">
    <div class="row">
      <div class="input-field col s12">
        <input required id="nome" name="nome" type="text" [readonly]="true" class="validate white-text" [formControlName]="'nome'" />
        <label for="nome" class="active white-text text-lighten-1 ">Nome do Titular do Cartão</label>
        <!-- <p class="tiny grey-text text-lighten-2" [hidden]="formNovoCartao.$pristine || !formNovoCartao.documento.$invalid">
            <i class="material-icons yellow-text">report_problem</i> Preencha o nome do titular.</p> -->
      </div>
    </div>
    <div class="row">
      <div class="input-field col s12">
        <input required id="documento" [readonly]="true" name="documento" [textMask]="{mask:masks.maskCPF, guide:false}" type="text"
          class="validate white-text" [formControlName]="'documento'" />
        <label for="documento"  class="active white-text text-lighten-1">CPF do Titular do Cartão</label>
        <!-- <p class="tiny grey-text text-lighten-2" [hidden]="formNovoCartao.$pristine || !formNovoCartao.documento.$invalid">
          <i class="material-icons yellow-text">report_problem</i>
          Preencha o documento do titular.</p>  -->
        <!-- <p class="tiny grey-text text-lighten-2" [hidden]="!formNovoCartao.$pristine && formNovoCartao.documento.$error.validDoc">
            <i class="material-icons yellow-text">report_problem</i> Número de documento inválido</p> -->
      </div>
    </div>

    <div class="row">
      <div class="col s12 m12">
        <h6 class="white-text">Endereço de Entrega</h6>
      </div>
    </div>

    <div class="row">
      <div class="col s12 m12">
        <app-addressform #address [address]="endereco"></app-addressform>
        <span *ngIf="address.formAddress.dirty && address.formAddress.invalid" class="red-text">Endereço incompleto</span>
      </div>
    </div>


    <app-inputvalor *ngIf="selectedCard" [floatingtax]="false" [isemissao]="true"
      [emissaotax]="selectedCard.taxaemissao" [editable]="false" (model)="valor" [service]="service"> </app-inputvalor>

    <div class="row">
      <div class="col s12">
        <button class="waves-effect waves-light btn col m3 s12 right" [style.backgroundColor]="ws.website?.color1"
          [disabled]="formNovoCartao.errors || !selectedCard || address.formAddress.invalid"
          id="bt-novo-cartao" (click)="processTransaction()">solicitar cartão</button>
        <a class="btn-flat col m3 s12 right" id="bt-cancel-cardtransf" (click)="resetTransaction()">cancelar</a>
      </div>
    </div>
  </form>

</div>