<div id="cart" class="row" *ngIf="cartList.length">
  <div class="col s4 m2 padding">
    <i class="material-icons white-text">card_giftcard</i> <span class="white-text">{{totalproduct}}</span>
  </div>
  <div class="col s4 m2 padding">
    <i class="material-icons white-text">attach_money</i> <span class="white-text">{{totalvalue}}</span>
  </div>
  <div class="col s4 m2">
    <div class="btn right" (click)="gotoCheckout()" [style.backgroundColor]="ws.website?.color1">
      <i class="material-icons">
        shopping_cart
      </i>
    </div>
  </div>
</div>