import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { InputBase, MaterializeService, FormService, MaskService, AuthService, DatabaseService } from '@ollieestudio/fire-lib';
import { ConfirmService } from 'src/app/services/confirm.service';
import { WebsiteService } from 'src/app/services/di/website.service';
import { Premiado } from 'src/app/services/interfaces/premiado';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { Service } from 'src/app/services/interfaces/service';
import banklist from '../../../../assets/json/banklist.json';
import { Bankinfo } from 'src/app/services/interfaces/bankinfo.js';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { QueryFn } from '@angular/fire/compat/firestore';
import { Validation } from 'src/app/services/validation/validation';
import { Status } from 'src/app/services/enum/status.enum';
import { Tipo } from 'src/app/services/enum/tipo.enum';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-newaccount',
  templateUrl: './newaccount.component.html',
  styleUrls: ['./newaccount.component.css']
})
export class NewaccountComponent implements OnInit {
  @Output() hideFormEmitter:EventEmitter<any> = new EventEmitter();
  @Input() service:Service;
  public formNewAccount:FormGroup;
  public formRefer:FormGroup;
  pjpfval:string = 'Pessoa Física';
  tipoval:string = 'bank';
  chaveval:string = 'cpf';
  banklist:any[];

  private fields:InputBase[] = [
    {key:"tipo", required:true},
    {key:"apelido", required:true},
    {key:"nome", required:true},
    {key:"documento", required:true},
    {key:"pjpf", required:true},
    {key:"conta", required:true},
    {key:"agencia", required:true},
    {key:"tipoconta", required:true},
    {key:"banco", required:true},
    {key:"tipochave", required:true},
    {key:"chavepix", required:true}
  ]

  private referfields:InputBase[] = [
    {key:"refer_name", required:true},
    {key:"refer_email", required:null},
    {key:"refer_cel", required:null}
  ]
  newUser: Premiado;
  dadosbancarios: Bankinfo;

   constructor(public analytics:AnalyticsService, public confirm:ConfirmService, private scrollService: ScrollToService, public database:DatabaseService<any>, public mask:MaskService, public material:MaterializeService, public forms:FormService,  public ws:WebsiteService, public masks:MaskService, public auth:AuthService<Premiado>) {
    this.formNewAccount = this.forms.toFormGroup(this.fields);
    this.formRefer = this.forms.toFormGroup(this.referfields);
    this.banklist = banklist;
    this.banklist.sort((itemA, itemB) => itemA.codigo - itemB.codigo)
   }

  ngOnInit() {
    this.material.initModal('#modalconvite');
    this.formNewAccount.controls['documento'].setValue(this.auth.User.documento);
    this.formNewAccount.controls['pjpf'].setValue(this.auth.User.pjpf);
    this.formNewAccount.controls['nome'].setValue(this.auth.User.nome);
  }

  setChavePix(tipo){
    this.chaveval = tipo;
    switch (tipo) {
      case 'email':
        this.formNewAccount.controls['chavepix'].setValue(this.auth.User.email);
        break;
      case 'celular':
        this.formNewAccount.controls['chavepix'].setValue('+55'+this.auth.User.celular.replace(/[^0-9]+/g, ''));
        break;
        case 'cpf':
        this.formNewAccount.controls['chavepix'].setValue(this.auth.User.documento);
        break;
    }
  }

  processTransaction(){
    this.dadosbancarios = {...this.dadosbancarios, ...this.formNewAccount.getRawValue()};
    this.dadosbancarios.usuario = this.auth.User.id;
    this.dadosbancarios.authid = this.auth.User.authid;
    this.dadosbancarios.campanha = this.ws.campanha.id;

    if(this.dadosbancarios.banco) this.dadosbancarios.banco = JSON.stringify(this.dadosbancarios.banco);
    this.database.add(this.dadosbancarios, "ACCOUNTS").then(result =>{
      this.material.toast(this.confirm.generateCheckMessage("Sua conta foi cadastrada com sucesso!"), 5000, 'green full');
      this.formNewAccount.reset();
      this.hideFormEmitter.next(true);
      this.scrollService.scrollTo({target:'#topbank'});
      this.analytics.logEvent('new_bankaccount', {userid: this.auth.User.id});
    });
  }

  resetTransaction(){
    this.formNewAccount.reset();
    this.formNewAccount.controls['documento'].setValue(this.auth.User.documento);
    this.formNewAccount.controls['pjpf'].setValue(this.auth.User.pjpf);
    this.formNewAccount.controls['nome'].setValue(this.auth.User.nome);
    this.hideFormEmitter.next(true);
    this.scrollService.scrollTo({target:'#topbank'});
  }

  cpfKeyUp(event: any) { // without type info
    let documento = this.formNewAccount.controls['documento'].value.replace(/[-\/\\^$*+?.()/_/|[\]{}]/g, '');
  if(documento.length >= 11){
   if (Validation.ValidaCpf(this.formNewAccount.controls['documento']) == null) {
    if(this.formNewAccount.controls['tipo'].value === 'digital_transf'){
      let query: QueryFn = ref => ref.where('documento', '==', documento).limit(1);
      
      let subs = this.database.queryValues('PREMIADOS', query).subscribe(result => {
        subs.unsubscribe();
        if (result.length == 0) {
          this.abreModalConvite();
        }else{
          let newAccountUser:Premiado = result[0] as Premiado;
          this.dadosbancarios = {} as Bankinfo;
          this.dadosbancarios.nome = newAccountUser.nome;
          this.dadosbancarios.digitalID = newAccountUser.id;
          this.dadosbancarios.documento = newAccountUser.documento;
          this.dadosbancarios.email = newAccountUser.email;
          this.dadosbancarios.pjpf = newAccountUser.pjpf;
          this.dadosbancarios.tipo = Tipo['digital-transf'];
          this.formNewAccount.patchValue(this.dadosbancarios);
        }
      })
    }
   }else{
      this.formNewAccount.controls['documento'].reset();
      this.material.toast(`CPF/CNPJ inválido!`, 3000, '');
   }
  }
}

  abreModalConvite() {
    this.material.openModal('#modalconvite');
  }

  sendRefer(){
    var data:any = this.formRefer.getRawValue();

    var refer:any = {};
    refer.referer = this.auth.User;
    refer.timestamp = new Date().getTime();
    refer.status = Status['novo'];
    refer.enterprise = this.ws.website.enterprise;
    refer.nome = data.refer_name;
    refer.email = data.refer_email;
    refer.celular = data.refer_cel;

    this.database.add(refer, "REFERS").then(result =>{
      this.material.toast(this.confirm.generateCheckMessage("Seu convite foi enviado!"), 5000, 'green full');
      this.formNewAccount.reset();
      this.analytics.logEvent('send_refer', {referer: this.auth.User.id});
    }).catch(error => {});
  }

  changeTipoConta(tipo){
    
    this.formNewAccount.controls['documento'].setValue(this.auth.User.documento);
    this.formNewAccount.controls['pjpf'].setValue(this.auth.User.pjpf);
    this.formNewAccount.controls['nome'].setValue(this.auth.User.nome);
    // this.formNewAccount.controls['tipo'].setValue(tipo);

    this.tipoval = tipo;
    
    if(this.tipoval === 'pix'){
      this.formNewAccount.controls['agencia'].setValue("0");
      this.formNewAccount.controls['conta'].setValue("0");
      this.formNewAccount.controls['pjpf'].setValue("0");
      this.formNewAccount.controls['banco'].setValue("0");
      this.formNewAccount.controls['tipoconta'].setValue("0");
      this.formNewAccount.controls['tipochave'].reset();
      this.formNewAccount.controls['chavepix'].reset();
    }
    if(this.tipoval === 'bank'){
      this.formNewAccount.controls['agencia'].reset();
      this.formNewAccount.controls['conta'].reset();
      this.formNewAccount.controls['pjpf'].reset();
      this.formNewAccount.controls['banco'].reset();
      this.formNewAccount.controls['tipoconta'].reset();
      this.formNewAccount.controls['chavepix'].setValue("0");
      this.formNewAccount.controls['tipochave'].setValue("0");
    }
  } 

  getFormValidationErrors() {
    Object.keys(this.formNewAccount.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.formNewAccount.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
        });
      }
    });
  }
}
