import { Component } from '@angular/core';

@Component({
  selector: 'app-qrcodepay-form',
  templateUrl: './qrcodepay-form.component.html',
  styleUrls: ['./qrcodepay-form.component.css']
})
export class QrcodepayFormComponent {

}
