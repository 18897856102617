<div class="row">

  <table class="white-text striped row s12" *ngIf="cartList">
    <thead>
      <tr class="white-text">
        <th>&nbsp;</th>
        <th>Modelo</th>
        <th>Qt.</th>
        <th>Valor</th>
        <th>&nbsp;</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let produto of cartList; let i=index">
        <td><img src="{{produto.imagem}}" alt="" class="thumb"></td>
        <td [ngClass]="{'error': produto.error}" >{{produto.nome}}</td>
        <td>{{produto.quantidade}}</td>
        <td>{{produto.pontos*produto.quantidade}}</td>
        <td><i (click)="removeProd(produto, i)" class="link material-icons white-text">delete_sweep</i></td>
      </tr>
    </tbody>
  </table>
  <!-- [ngClass]="{unavailable: produto.quantidade === 0}" -->
  <div class="row">
    <div class="col m4 s12 input-field">
      <input class="active white-text " disabled id="totalprod" type="text" [(ngModel)]="privatedata.retido">
      <label for="totalprod" class="active white-text">TOTAL DOS PRODUTOS</label>
    </div>
    <div class="col m4 s12 input-field">
      <input class="active white-text " disabled id="totaltx" type="text" [(ngModel)]="displaytax">
      <label for="totaltx" class="active white-text">TAXA DE SERVIÇO</label>
    </div>
     <div class="col m4 s12 input-field" *ngIf="privatedata.endereco">
      <input class="col s8 active white-text " disabled id="totalfrete" type="text" [(ngModel)]="privatedata.frete">
      <label for="totalfrete" class="active white-text">TAXA DE ENTREGA</label>
      <a *ngIf="privatedata.frete" class="waves-effect waves-light btn-floating customcolor1" [style.backgroundColor]="ws.website?.color1" (click)="resetFrete()"><i class="material-icons">undo</i></a>
    </div> 

  </div>
    <app-addressform #address ></app-addressform >

      <div class="row" >
      <div class="col s12">
        <button class="waves-effect waves-light btn col m3 s12 right" [style.backgroundColor]="ws.website?.color1" [disabled]="!privatedata.frete || !privatedata.endereco.numero" id="bt-checkout-gift" (click)="processTransaction()">concluir pedido</button>
        <a class="btn-flat col m3 s12 right" id="bt-cancel-transfer" (click)="continueShopping()">escolher mais produtos</a>
      </div>
    </div>
</div>
