import { Component, OnInit, Input } from '@angular/core';
import { Website } from 'src/app/services/interfaces/website';
import { MaterializeService, AuthService, DatabaseService, CookieService } from '@ollieestudio/fire-lib';
import { WebsiteService } from 'src/app/services/di/website.service';
import { Campanha } from 'src/app/services/interfaces/campanha';
import { Service } from 'src/app/services/interfaces/service';
import { Premiado } from 'src/app/services/interfaces/premiado';
import { Datatype } from 'src/app/services/enum/datatype.enum';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Logininfo } from 'src/app/services/interfaces/logininfo';
import { UpdatesService } from '../../updates.service';
import { Tipo } from 'src/app/services/enum/tipo.enum';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
 public website: Website;
 public campanha: Campanha;
 public services: Service[] = [];
  saldo: number;
  User: Premiado =  {} as Premiado;
  showRegulamento: boolean;
  userObserver:Subscription;
  logininfo: any;

  constructor(public material:MaterializeService, public updates:UpdatesService, public cookies:CookieService<Logininfo>, public router:Router, public materialize:MaterializeService, public ws:WebsiteService, public auth:AuthService<Premiado>, public database: DatabaseService < any >) { }

  ngOnInit() {
    this.materialize.initSidenav();    
    this.website = this.ws.website;
    this.campanha = this.ws.campanha;
    this.services = this.ws.services;
    
    this.updates.regulamento.subscribe(evt=>{
     if(evt === true){
       this.mostraRegulamento()
    }else{
      this.showRegulamento = false;
    }
    } )

    if(this.auth.User){
        this.getUserData(this.auth.User.id);
      }else{
        this.router.navigateByUrl('/institucional/sections')
      }
    }

  
  getUserData(uid: string) {
    if(!this.userObserver){
      this.userObserver = new Subscription();
      this.userObserver = this.database.observe(uid, 'PREMIADOS').subscribe(result =>{
        if (result) {
          this.auth.User = result as Premiado;
          this.User = this.auth.User;
          this.saldo = this.User.saldo;
          if(!this.auth.User.hasRegulamento && !this.showRegulamento){
            this.showRegulamento = true;
            window.setTimeout(() =>{
              this.material.initModal('#modalregulamento', {'dismissible':false});
              this.material.openModal('#modalregulamento');
            }, 3000)
          }
        }
      })
    }
  }

  hideService(service):boolean{
    return !service.active || service.type === Tipo['pix-online'] || service.type === Tipo['card-emiss'];
  }

  logout(){
    this.auth.logOut();
    window.location.href = "/";
    this.cookies.remove('smartuserinfo');
  }

  mostraRegulamento(){
    this.showRegulamento = true;
    this.material.initModal('#modalregulamento', {dismissible:false});
    this.material.openModal('#modalregulamento');
  }

}
