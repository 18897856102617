import { Component, OnInit } from '@angular/core';
import { WebsiteService } from 'src/app/services/di/website.service';
import { Tipo } from 'src/app/services/enum/tipo.enum';
import { Service } from 'src/app/services/interfaces/service';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.css']
})
export class SalesComponent implements OnInit {
  servicetype: string =  Tipo['salesinput'];
  service:Service;
  showForm:boolean = true;
  selectedMethod:any = 'form';

  constructor(public ws:WebsiteService) {
    let sub = this.ws.websiteEmitter.subscribe(website =>{
      sub.unsubscribe();
      this.service = {...this.ws.campanha.services[this.servicetype], ...this.ws.services.filter(service => service.type === this.servicetype)[0]};
      this.service.instructions = [
        'Serão aceitas apenas vendas confirmadas',
        'Serão aceitas apenas vendas de produtos participantes',
        'As vendas serão atribuídas ao mês seguinte',
        'Não serão aceitas vendas de outro CPF',
      ]
    })
  }

  ngOnInit(): void {
    this.service = {...this.ws.campanha.services[this.servicetype], ...this.ws.services.filter(service => service.type === this.servicetype)[0]};
    this.service.instructions = [
      'Serão aceitas apenas vendas confirmadas',
      'Serão aceitas apenas vendas de produtos participantes',
      'As vendas serão atribuídas ao mês seguinte',
      'Não serão aceitas vendas de outro CPF',
    ]
  }

  
  selectMethod(method:any){
    this.selectedMethod = method;
  }

}
