<div id="formcontainer" class="editcontainer col s12 m8">
  <form id="formPayment" name="formPayment" [formGroup]="formPayment" action="" class="col s12">
    <h4 [style.font-family]="ws.website.font.family"  class="white-text">NOVO PAGAMENTO</h4>
    <!-- <img src="{{imgsrc}}" alt="" class="responsive_img" style="max-height:80px" /> -->

    <!-- <barcoder transaction="transaction" barcode="barcode" result="erro_c"/> -->

    <div class="row">
      <div class="input-field col s12">
        <input min="47" max="48" id="codigo" name="codigo" type="text" class="validate white-text"
          [formControlName]="'barcode'" (keyup)="getKeyUp($event)"  (keydown)="getKeyUp($event)" />
        <label for="codigo" class="active white-text text-lighten-1">Linha digitável</label>
        <!-- <p class="tiny grey-text text-lighten-2" ng-show="!formPayment.$pristine && formPayment.codigo.$invalid">
            <i class="material-icons yellow-text">report_problem</i>
            Digite, cole ou fotografe o código de barras/linha digitável sem espaços.</p> -->
      </div>
    </div>
    <div class="row">
      <div class="input-field col s12">
        <input required id="beneficiado" name="beneficiado" type="text" class="validate white-text"
          [formControlName]="'beneficiado'" />
        <label for="beneficiado" class="active white-text text-lighten-1">Cedente / convênio</label>
        <!-- <p class="tiny grey-text text-lighten-2" ng-show="!formPayment.$pristine && formPayment.beneficiado.$invalid">
            <i class="material-icons yellow-text">report_problem</i>
            Informe o cedente ou convênio do pagamento.</p> -->
      </div>
    </div>
    <div class="row">
      <div class="input-field col s12" [hidden]="privatedata.tipoconta == 'consumo'">
        <input id="doccedente" name="doccedente" type="text" class="validate white-text"
          [formControlName]="'doccedente'" />
        <label for="doccedente" class="active white-text text-lighten-1">CPF/CNPJ Cedente</label>
        <!-- <p class="tiny grey-text text-lighten-2" ng-show="!formPayment.$pristine && formPayment.beneficiado.$invalid">
            <i class="material-icons yellow-text">report_problem</i>
            Informe o cedente ou convênio do pagamento.</p> -->
      </div>
    </div>

    <div class="row">
      <div class="block range-field col s12 m6">
        <p class="col s6">
          <label for="tipoconta1" class="white-text">
            <input required name="tipoconta" class="with-gap white-text " type="radio" id="tipoconta1"
              [formControlName]="'tipoconta'" value="boleto" selected />
            <span>Boleto</span></label>
        </p>
        <p class="col s6">
          <label for="tipoconta2" class="white-text">
            <input required name="tipoconta" class="with-gap white-text " type="radio" id="tipoconta2"
              [formControlName]="'tipoconta'" value="consumo" />
            <span>Conta de Consumo</span></label>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="input-field col s6">
        <input required id="vencimento" ng-readonly="true" name="vencimento"
          [textMask]="{mask:masks.maskData, guide:false}" type="text" class="validate white-text"
          [formControlName]="'datavencto'" />
        <label for="vencimento" class="active white-text text-lighten-1">Vencimento</label>
        <!-- <p class="tiny grey-text text-lighten-2" ng-show="!formPayment.$pristine && formPayment.datavencto.$invalid">
            <i class="material-icons yellow-text">report_problem</i>
            Preencha a data de vencimento</p> -->
      </div>
      <div class="input-field col s6">
        <input required id="datapagto" ng-readonly="true" name="datapagto"
          [textMask]="{mask:masks.maskData, guide:false}" type="text" class="validate white-text"
          [formControlName]="'datapagto'" />
        <label for="datapagto" class="active white-text text-lighten-1">Data de Pagamento</label>
        <!-- <p class="tiny grey-text text-lighten-2" ng-show="!formPayment.$pristine && formPayment.datapagto.$invalid">
            <i class="material-icons yellow-text">report_problem</i>
            Preencha a data de pagamento.</p> -->
      </div>
    </div>

    <app-inputvalor *ngIf="auth.User" [editable]="vl_editable" [valor]="privatedata.valor"  [floatingtax]="false" [taxmodel]="2" [service]="service"></app-inputvalor>
    <p class="grey-text text-lighten-2" [hidden]="alert.length == 0" > <i class="material-icons yellow-text">report_problem</i> {{alert}}</p>
    <div class="row">
      <div class="col s12" [hidden]="!erro_c.length">
        <i class="material-icons yellow-text col s1">report_problem</i>
        <p>{{erro_c}}</p>
      </div>
      <div class="col s12">
        <button class="waves-effect waves-light btn col m3 s12 right"  [style.backgroundColor]="ws.website?.color1"
        [disabled]="!formPayment.valid" id="bt-payment"
          (click)="processTransaction()">enviar</button>
        <a class="btn-flat col m3 s12 right" id="bt-cancel-payment" (click)="resetTransaction()">cancelar</a>
      </div>
    </div>
  </form>

</div>