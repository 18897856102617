<table class="responsive-table col s12 white-text">
    <thead>
  <tr>
    <th class="link" (click)="sorter.sortList('subcampanha', extrato)">SubCampanha</th>
    <th class="link" (click)="sorter.sortList('timestamp', extrato)">Data Emissão</th>
    <th class="link" (click)="sorter.sortList('codigo', extrato)">Código</th>
    <th class="link" (click)="sorter.sortList('owner', extrato)">Cliente</th>
    <th class="link" (click)="sorter.sortList('premiado', extrato)">Premiado</th>
    <th class="link" (click)="sorter.sortList('valor', extrato)">Valor</th>
    <th class="link" (click)="sorter.sortList('status', extrato)">Status</th>
    <th> TRANSFERIR </th>
    <th> RESGATAR </th>
  </tr>
</thead>
 
<tbody>

  <tr *ngFor="let voucher of extrato" >
    <td class="center">{{voucher.subcampanha}}</td>
    <td>{{voucher.timestamp | date:"dd/MM/yy" }}</td>
    <td>{{voucher.codigo}}</td>
    <td>{{voucher.owner}}</td>
    <td>{{voucher.premiado}}</td>
    <td>{{voucher.valorin}}</td>
    <td [ngClass]="{'yellow-text text-darken-2': voucher.status === 'PENDENTE',
    'green-text': voucher.status === 'CONCLUIDO',
    'orange-text text-darken-4': voucher.status === 'AGUARDANDO',
    'blue-text': voucher.status === 'NOVO',
    'orange-text': voucher.status === 'ATIVO',
    'red-text': voucher.status === 'CANCELADO' || voucher.status === 'ESTORNADO'}">
    {{voucher.status}}
    </td>
    <td *ngIf="voucher.showEmail">
      <div style="display: flex; align-items: center;">
        <input type="email" id="email" #email required style="margin-right: 5px;">
        <i class="material-icons" style="cursor: pointer;" (click)="sendEmailVoucher(voucher, email.value)">send</i>
      </div>
    </td>
    <td *ngIf="!voucher.showEmail" class="center">
      <i  *ngIf="voucher.status != 'CONCLUIDO'" (click)="voucher.showEmail = true" style="margin-right: 6px;"  class="material-icons white-text link modal-trigger" >email</i>
      <i  *ngIf="voucher.status != 'CONCLUIDO'" (click)="downloadVoucher(voucher);"  class="material-icons white-text link modal-trigger" >download</i>
    </td>
    <td *ngIf="!voucher.showEmail" class="center">
      <i  *ngIf="voucher.status != 'CONCLUIDO'" (click)="redeemVoucher(voucher);"  class="material-icons orange-text link " >monetization_on</i>
    </td>
  </tr>
</tbody>
</table>

<div [hidden]="true">
  <app-printvouchers #printvouchers></app-printvouchers>
</div>