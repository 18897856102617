import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
@Injectable({
  providedIn: 'root'
})
export class AppUpdateService {
constructor(private readonly updates: SwUpdate) {
  this.updates.available.subscribe(event => {
    this.showAppUpdateAlert();
  });
}
showAppUpdateAlert() {
  // Use MatDialog or ionicframework's AlertController or similar
  if(confirm("Deseja baixar uma atualização disponível?")){
    this.doAppUpdate()
  }
}

doAppUpdate() {
    this.updates.activateUpdate().then(() => document.location.reload());
  }
}
