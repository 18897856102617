import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthService, DatabaseService, MaterializeService, MessagingService } from '@ollieestudio/fire-lib';
import { Premiado } from 'src/app/services/interfaces/premiado';
import { QueryFn } from '@angular/fire/compat/firestore';
import { Subscription } from 'rxjs';
import { Update } from 'src/app/services/interfaces/update';
import { Status } from 'src/app/services/enum/status.enum';
import { Tipo } from 'src/app/services/enum/tipo.enum';
import { WebsiteService } from 'src/app/services/di/website.service';
import { Transaction } from 'src/app/services/interfaces/transaction';
import { NegativeToZeroPipe } from 'src/app/pipes/negative-to-zero.pipe';
import { UpdatesService } from '../../updates.service';

@Component({
  selector: 'app-updates',
  templateUrl: './updates.component.html',
  styleUrls: ['./updates.component.css']
})
export class UpdatesComponent implements OnInit {
  updates:Update[] = [];
 

  constructor(public messaging:MessagingService, private service:UpdatesService, public negtozero:NegativeToZeroPipe, public material:MaterializeService, public auth:AuthService<Premiado>, public database:DatabaseService<Transaction>, public ws:WebsiteService ) { }

  ngOnInit() {
    if(this.auth.User){
      this.getUserData(this.auth.User.id);
      
    }

    const slider = document.querySelector('.updates') as HTMLElement;
    let isDown = false;
    let startX;
    let scrollLeft;

slider.addEventListener('mousedown', (e:MouseEvent) => {
    isDown = true;
    slider.classList.add('active');
    startX = e.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
  });
  slider.addEventListener('mouseleave', () => {
    isDown = false;
    slider.classList.remove('active');
  });
  slider.addEventListener('mouseup', () => {
    isDown = false;
    slider.classList.remove('active');
  });
  slider.addEventListener('mousemove', (e:MouseEvent) => {
    if(!isDown) return;
    e.preventDefault();
    const x = e.pageX - slider.offsetLeft;
    const walk = (x - startX) * 3; //scroll-fast
    slider.scrollLeft = scrollLeft - walk;
  });
  }

  getUserData(uid) {
    let sub = this.database.get(uid, 'PREMIADOS', "default").subscribe(result =>{
      sub.unsubscribe();
      if (result) {
        this.auth.User = result.data() as Premiado;
        this.checkRegulamento();
        let saldoUpdate:Update = {} as Update;
        saldoUpdate.titulo = `Olá ${this.auth.User.apelido} `;
        saldoUpdate.texto = `Seu saldo atual é de ${this.negtozero.transform(this.auth.User.saldo)} ${this.ws.campanha.moeda}`;
        saldoUpdate.data = new Date().toLocaleDateString();
        saldoUpdate.status = Status['novo'];
        saldoUpdate.tipo = Tipo['saldo-update'];
        if(this.auth.User.saldo) this.updates.push(saldoUpdate);
        this.getUserTransactions();
        this.getUserHelp();

        if(!this.auth.User.FCMtoken){
          let fcmUpdate:Update = {} as Update;
          fcmUpdate.titulo = `Receba nossas notificações!`;
          fcmUpdate.texto = `Clique aqui para permitir o envio de nossas notificações.`;
          fcmUpdate.data = new Date().toLocaleDateString();
          fcmUpdate.status = Status['novo'];
          fcmUpdate.tipo = Tipo['notification'];
          this.updates.push(fcmUpdate);
          this.messaging?.tokenSubject?.subscribe(update => this.updateToken(update))
          this.messaging.requestPermission(this.auth.User.id);
        }

        if(this.ws.showAddToHomeScreen){
          let fcmAddToHome:Update = {} as Update;
          fcmAddToHome.titulo = `Adicione à Tela Inicial`;
          fcmAddToHome.texto = `Clique aqui para adicionar nosso ícone à tela do seu dispositivo.`;
          fcmAddToHome.data = new Date().toLocaleDateString();
          fcmAddToHome.status = Status['novo'];
          fcmAddToHome.tipo = Tipo['addtohome'];
          this.updates.push(fcmAddToHome);
        }
      }
    })
  }

  callToAction(update:Update){
    switch (update.tipo) {
      case 'notification':
        this.messaging.tokenSubject.subscribe(update => this.updateToken(update))
        this.messaging.requestPermission(this.auth.User.id);
        break;
      case 'addtohome':
        this.ws.deferredPrompt.prompt();
        break;
      case 'regulamento':
        this.service.regulamento.emit(true);
        break;
      default:
        break;
    }
  }

  updateToken(update:any) {
    if(update){
      this.auth.User.FCMtoken = update.token;
      this.database.update(this.auth.User, 'PREMIADOS').then(result =>{
        //BI:cadastro editado
      }).catch(error =>{
        console.error(error);
      });
    }
  }

  checkRegulamento() {
   if(!this.auth.User.hasRegulamento){
    let regulamentoUpdate:Update = {} as Update;
    regulamentoUpdate.titulo = `ATENÇÃO ${this.auth.User.apelido}! `;
    regulamentoUpdate.texto = `Você ainda não deu seu aceite no Regulamento da Campanha`;
    regulamentoUpdate.data = new Date().toLocaleDateString();
    regulamentoUpdate.status = Status['novo'];
    regulamentoUpdate.tipo = Tipo['regulamento'];
    this.updates.push(regulamentoUpdate);
   }
  }

  getUserTransactions(){
    let query:QueryFn = ref => ref.where('authid', '==', this.auth.User.authid).where('usuario', '==', this.auth.User.id).orderBy('timestamp', 'desc'); 
    let sub:Subscription = this.database.queryValues('TRANSACTIONS', query).subscribe(transactions =>{
        // sub.unsubscribe();
        transactions.forEach(item => {
          let transac = item;
          if(transac.valorout > 0){
          if(transac.status === Status['erro']){
            let card:Update = {} as Update;
            card.titulo = ` Resgates `;
            card.texto = `Houve um erro ao processar seu resgate de ${transac.identificador}`;
            card.data = new Date().toLocaleDateString();
            card.status = Status['novo'];
            card.tipo = Tipo['transac-erro'];
            this.updates.push(card);
          }
          if(transac.status === Status['sucesso'] ){
            let card:Update = {} as Update;
            card.titulo = ` Resgates `;
            card.texto = `Seu resgate de ${transac.identificador} foi concluído com sucesso!`;
            card.data = new Date().toLocaleDateString();
            card.status = Status['novo'];
            card.tipo = Tipo['transac-sucesso'];
            this.updates.push(card);
          }
          if(transac.status === Status['processando'] ){
            let card:Update = {} as Update;
            card.titulo = ` Resgates `;
            card.texto = `Seu resgate de ${transac.identificador} entrou em PROCESSAMENTO!`;
            card.data = new Date().toLocaleDateString();
            card.status = Status['novo'];
            card.tipo = Tipo['transac-sucesso'];
            this.updates.push(card);
          }
        }
          if(transac.status === Status['sucesso'] && transac.valorin > 0){
            let card:Update = {} as Update;
            card.titulo = ` Recarga de Pontos `;
            card.texto = `Você recebeu uma nova carga de pontos!!`;
            card.data = new Date().toLocaleDateString();
            card.status = Status['novo'];
            card.tipo = Tipo['transac-recarga'];
            this.updates.push(card);
          }
        });

      })
  }

  getUserHelp(){
    let query3:QueryFn = ref => ref.where('usuario', '==', this.auth.User.id).orderBy('datacreate', 'desc'); 
    let sub3:Subscription = this.database.queryValues('HELPDESK', query3).subscribe(result =>{
        // sub3.unsubscribe();
        result.forEach(item =>{
          let help = item;
          if(help.status == 'RESPONDIDA'){
            let card:Update = {} as Update;
              card.titulo = ` Seu chamado foi respondido `;
              card.texto = "[" + help.assunto + "] " + help.chat[help.chat.length-1].texto;
              card.data = new Date(help.chat[help.chat.length-1].dataenvio).toLocaleDateString();
              card.status = Status['respondido'];
              card.tipo = Tipo['help'];
              this.updates.push(card);
          }
          if(help.status == 'ENCERRADA'){
            let card:Update = {} as Update;
              card.titulo = ` Seu chamado foi encerrado `;
              card.texto = help.assunto;
              card.data = new Date(help.dataclose).toLocaleDateString();
              card.status = Status['novo'];
              card.tipo = Tipo['help'];
              this.updates.push(card);
          }
          if(help.status == 'EM ANALISE'){
            let card:Update = {} as Update;
              card.titulo = ` Seu chamado está em Análise `;
              card.texto = help.assunto;
              card.data = new Date(help.dataupdate).toLocaleDateString();
              card.status = Status['novo'];
              card.tipo = Tipo['help'];
              this.updates.push(card);
          }
        })
        
      })
  }

  getUserHistory(){
    let query2:QueryFn = ref => ref.where('authid', '==', this.auth.User.id).orderBy('timestamp', 'desc'); 
    let sub2:Subscription = this.database.queryValues('HISTORY', query2).subscribe(history =>{
        sub2.unsubscribe();
        history;
      })
  }

  openModal(texto){
    alert(texto)
  }

}
