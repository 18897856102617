import { Component, Input, OnInit } from '@angular/core';
import { AuthService, DatabaseService } from '@ollieestudio/fire-lib';
import { Venda } from 'src/app/services/interfaces/venda';
import { QueryFn } from '@angular/fire/compat/firestore';
import { Premiado } from 'src/app/services/interfaces/premiado';

@Component({
  selector: 'app-sales-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListSalesComponent implements OnInit {
  @Input() extrato:Venda[];
  status: any = 'VERIFICADO';

  constructor(public  database:DatabaseService<Venda>, public auth:AuthService<Premiado>) { }

  ngOnInit(): void {
    this.getExtrato();
  }

  getExtrato() {
    let query: QueryFn;
    query = ref => ref.where('usuario', '==', this.auth.User.id).where('status', '==' , this.status);

    this.database.queryValues("SALES", query).subscribe(result => {
      this.extrato = result;
    });
  }
 

  orderBy(col){
    
  }

  changeStatus(event){
    this.getExtrato();
  }

}
