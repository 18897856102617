<div class="toppad">
    &nbsp;
  </div>
  <div class="container top-5">
    <div class="row" *ngIf="service">
      
      <h3 [style.font-family]="ws.website.font.family"  class="col s12 m11 white-text upper"><i class="material-icons">{{service.icon}}</i>&nbsp;{{service.title}}
      </h3>
    </div>
    <div class="row">
      
      <div class="col s12 m11 instrucoes">
        <h5 [style.font-family]="ws.website.font.family"  class="white-text">INSTRUÇÕES</h5>
        <ul class="browser-default">
          <li *ngFor="let instruction of service.instructions" ><span class="white-text">{{instruction}}</span></li>
        </ul>
      </div>
    </div>

    <div class="row">
        <button class="btn col m3 s12" [ngClass]="{'grey': selectedMethod != 'form'}" [style.backgroundColor]="ws.website?.color1" (click)="selectMethod('form')"><i class="material-icons">storefront</i> cadastrar venda</button>
        <button class="btn col m3 s12" [ngClass]="{'grey': selectedMethod == 'form'}" [style.backgroundColor]="ws.website?.color1" (click)="selectMethod('list')"><i class="material-icons">analytics</i> suas vendas</button>
    </div>

    <div class="row"  [hidden]="selectedMethod != 'form'">
        <app-sales-form></app-sales-form>
    </div>
    <div class="row"  *ngIf="selectedMethod == 'list'">
        <app-sales-list></app-sales-list>
    </div>
  </div>