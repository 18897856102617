import { Component, Input, OnInit } from '@angular/core';
import { WebsiteService } from 'src/app/services/di/website.service';

@Component({
  selector: 'app-total-display',
  templateUrl: './total-display.component.html',
  styleUrls: ['./total-display.component.css']
})
export class TotalDisplayComponent implements OnInit {
@Input() items:any[];
@Input() campovalor:string;

  constructor(public ws:WebsiteService) { }

  ngOnInit(): void {
  }

}
