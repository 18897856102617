import { Component, OnInit, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import { Service } from 'src/app/services/interfaces/service';
import { FormGroup } from '@angular/forms';
import { Card } from 'src/app/services/interfaces/card';
import { InputvalorComponent } from '../../shared/inputvalor/inputvalor.component';
import { InputBase, MaterializeService, FormService, MaskService, AuthService, DatabaseService } from '@ollieestudio/fire-lib';
import { ConfirmService } from 'src/app/services/confirm.service';
import { WebsiteService } from 'src/app/services/di/website.service';
import { Premiado } from 'src/app/services/interfaces/premiado';
import { Transaction } from 'src/app/services/interfaces/transaction';
import { Status } from 'src/app/services/enum/status.enum';
import { Tipo } from 'src/app/services/enum/tipo.enum';
import { Phone } from 'src/app/services/interfaces/phone';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-mobile-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class MobileFormComponent implements OnInit {
  privatedata:any = {};
  @Output() hideformEmitter:EventEmitter<any> = new EventEmitter();
  @Input() service:Service;
  @Input() selectedPhone: Phone;
  public formRecarga:FormGroup;
  public operadoras:any[];
  public selectedMobile: Card;
  @ViewChild(InputvalorComponent, { static: true }) valor:InputvalorComponent;
  
  private fields:InputBase[] = [
    {key:"operadora", required:true},
    {key:"valor", required:true},
    {key:"celular", required:true},
    {key:"confirm", required:true},
    {key:"favorite", required:false},
    {key:"nome", required:false}
  ]
  
  selectedOperadora: any;

  constructor(public analytics:AnalyticsService, public database:DatabaseService<any>, public confirm:ConfirmService, public material:MaterializeService, public forms:FormService,  public ws:WebsiteService, public masks:MaskService, public auth:AuthService<Premiado>) {
    this.formRecarga = this.forms.toFormGroup(this.fields);
   }

  ngOnInit() {
   let sub = this.database.listValues('OPERADORAS').subscribe(result =>{
      sub.unsubscribe();
      this.operadoras = result;
    })
  }
  
  selectOperadora(event){
    this.selectedOperadora = this.operadoras[event.target.selectedIndex];
    this.privatedata.operadora = this.selectOperadora['nome'];
    this.confirm.resultEmitter.subscribe(result =>{
      if(result === true){
        this.material.toast(this.confirm.generateCheckMessage("Sua recarga foi enviada!"), 5000, 'green full');
        let dados = this.formRecarga.getRawValue();
        if(dados.favorite && !this.selectedPhone){
          let mobile:Phone = {} as Phone;
          mobile.apelido = dados.nome || dados.operadora;
          mobile.nome = dados.nome || dados.operadora;
          mobile.numero = dados.celular;
          mobile.operadora = dados.operadora;
          mobile.tipo = Tipo['celular'];
          mobile.usuario = this.auth.User.id;
          mobile.authid = this.auth.User.authid;
          mobile.campanha = this.ws.campanha.id;
          this.database.add(mobile, 'MOBILES').then(result =>{
            this.analytics.logEvent('new_mobile', {userid: this.auth.User.id});
          });
        }
        this.formRecarga.reset();
        this.hideformEmitter.next(true);
      }else{
        this.material.toast(this.confirm.generateErrorMessage("Sua recarga não foi processada!"), 5000, 'red full');
      }
    })
  }
  
  selectValor(event){
    this.privatedata.valor = parseInt(event.target.value);
  }

  selectPhone(phone){
      this.selectedPhone = phone;
      this.privatedata.valor = 0;
      this.selectOperadoraByName(this.selectedPhone.operadora);
      this.formRecarga.controls['nome'].setValue(this.selectedPhone.nome);
      this.formRecarga.controls['celular'].setValue(this.selectedPhone.numero);
      this.formRecarga.controls['confirm'].setValue(this.selectedPhone.numero);
      this.formRecarga.controls['operadora'].setValue(this.selectedPhone.operadora);
      this.formRecarga.controls['favorite'].setValue(true);

  }
  selectOperadoraByName(operadora: any) {
    this.selectedOperadora = this.operadoras.filter(element => element.nome === operadora)[0];
    this.privatedata.operadora = this.selectOperadora['nome'];
    this.confirm.resultEmitter.subscribe(result =>{
      if(result === true){
        this.material.toast(this.confirm.generateCheckMessage("Sua recarga foi enviada!"), 5000, 'green full');
        this.analytics.logEvent('mobile_complete', {userid: this.auth.User.id});
        this.formRecarga.reset();
        this.hideformEmitter.next(true);
      }else{
        this.material.toast(this.confirm.generateErrorMessage("Sua recarga não foi processada!"), 5000, 'red full');
        this.analytics.logEvent('mobile_error', {userid: this.auth.User.id});
      }
    })
  }

  resetTransaction(){
    this.formRecarga.reset();
    this.hideformEmitter.next(true);
  }

  processTransaction(){
    let transac:Transaction = {} as Transaction;
    
    transac.enterprise = this.ws.website.enterprise;
    transac.cliente = this.ws.website.cliente;
    transac.campanha = this.ws.campanha.id;
    // transac.geo = this.geo.location;
    transac.identificador = this.service.title;
    transac.status = Status['novo'];
    transac.tipo = Tipo['mobile'];
    transac.usuario = this.auth.User.id;
    transac.authid = this.auth.User.authid;
    transac.valorout = Math.ceil(this.privatedata.valor) + Math.ceil(this.service.tax);
    
    let dados:any = this.formRecarga.getRawValue();
    this.privatedata.operadora = this.selectedOperadora;
    this.privatedata.celular = dados.celular;
    
    transac.privatedata = this.privatedata;

    this.confirm.openConfirm(transac);

  }

}
