import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UpdatesService {
  @Output() regulamento:EventEmitter<boolean> =  new EventEmitter();
  
  constructor() { }
}
