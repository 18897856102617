import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { PasswordPolicy } from 'src/app/services/interfaces/password-policy';

@Component({
  selector: 'app-password-policy',
  templateUrl: './password-policy.component.html',
  styleUrls: ['./password-policy.component.css'],
})
export class PasswordPolicyComponent implements OnInit, OnDestroy {
  @Input() valueObservable: Observable<string> | any;
  @Input() policies: PasswordPolicy[] | any;
  subscription: Subscription | any;
  value: string | any;

  constructor() {}

  ngOnInit() {
    this.subscription = this.valueObservable.subscribe(
      (value: any) => (this.value = value)
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  validateValue(index: number): any {
    if (this.value) {
      if (this.policies[index].regexValidator) {
        if (this.value.match(this.policies[index].regexValidator)) {
          if (this.policies[index].lengthValidator) {
            return this.value.match(this.policies[index].regexValidator)
              .length >= this.policies[index].lengthValidator
              ? true
              : false;
          } else {
            return this.value.match(this.policies[index].regexValidator)
              ? true
              : false;
          }
        }
      } else {
        return this.value.length >= this.policies[index].lengthValidator
          ? true
          : false;
      }
    } else {
      return false;
    }
  }

  validatePassword(){
    return this.policies.filter((policy: any) => this.validateValue(this.policies.indexOf(policy))).length ? false : true;
  }
}
