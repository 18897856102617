import { Component, OnInit, Output, EventEmitter, Input, KeyValueDiffers, DoCheck, IterableDiffers } from '@angular/core';
import { DatabaseService, MaterializeService, FormService, MaskService, AuthService } from '@ollieestudio/fire-lib';
import { ConfirmService } from 'src/app/services/confirm.service';
import { WebsiteService } from 'src/app/services/di/website.service';
import { Premiado } from 'src/app/services/interfaces/premiado';
import { HttpClient } from '@angular/common/http';
import { Produto } from 'src/app/services/interfaces/produto';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { QueryFn } from '@angular/fire/compat/firestore';
import { Campanha } from 'src/app/services/interfaces/campanha';
import { Observable, Subscription, map, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-shop-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.css']
})
export class ShopCatalogComponent implements OnInit {
  deptos: any[];
  lojas:any[];
  @Output() _selectProduct: EventEmitter<Produto> = new EventEmitter();
  public hideMenuEmitter: EventEmitter<boolean> = new EventEmitter();
  searchStr:string = "";
  items: any[] ;
  currentDepto: any;
  @Input() hideMenu:boolean = true;
  campanha:Campanha;
  currentPage:number = 1;
  totalPages:number[] = []
  differ: any;
  prodPerPage: number = 60;
  currentOrder: any;
  selectedloja:any;
  deptos$: Observable<Object>;
  items$:Observable<Object>;
   
  constructor(public analytics:AnalyticsService, public http:HttpClient, private scrollService: ScrollToService,  public database:DatabaseService<any>, public confirm:ConfirmService, public material:MaterializeService, public forms:FormService,  public ws:WebsiteService, public masks:MaskService, public auth:AuthService<Premiado>, public differs: KeyValueDiffers) {
   //  this.differ = differs.find({}).create();
    let sub = this.ws.catalogEmitter.subscribe(ready =>{
      sub.unsubscribe();
      this.getLojas();
    })
  }
  
  getLojas() {
    this.database.listValues('CATALOGO').subscribe(result =>{
      this.lojas = result.reverse();
      this.getLoja(this.lojas[0]);
    })
  }

  getLoja(loja:any){
    this.selectedloja = loja;
    this.getVitrine(loja.id);
    this.getDeptos(loja.id);
  }
  
  ngOnInit() {
    this.items = [];
    this.material.initCollapsible();
    this.campanha = this.ws.campanha;
    this.getLojas();
    }
    
   getDeptos(id:any) {
    this.deptos = [];
    if(id === 'MAGAZINE'){
    var url = environment.rooturl + "/getDeptos";
    this.analytics.logEvent('list_deptos', {userid: this.auth.User.id, loja: id});
    this.deptos$ = this.http.get(url)
    }else{
      this.database.listValues('CATALOGO/' + id + '/DEPTOS').subscribe(result =>{
        this.deptos = result as [];
      })
    }
  }


  getProdutos(depto:any, page?:any) {
    this.currentDepto = depto;
    if(page){
      this.currentPage = page
    }else{
      this.currentPage = 1
    };

    if(this.selectedloja.id === 'MAGAZINE'){
    var url = environment.rooturl + "/getProdutos?depto=" + depto.id;
    if(page) url += '&page=' + page;

    this.analytics.logEvent('list_product', {userid: this.auth.User.id, loja: this.selectedloja.id, depto:depto.nome, page:0});
    this.hideMenu = true;
    this.items$ = this.http.get(url).pipe(
      tap((produtos:any[]) =>{
        return produtos.map( produto =>{ if(produto.availability === 'ESGOTADO') {
          produto.displayvalor = 'ESGOTADO';
        }else{
          produto.pontos = Math.ceil(produto.price) * this.campanha.conversion;
          produto.displayvalor = produto.pontos;
        }
      })
    }))
  }else{

   let query: QueryFn = ref => ref.where('depto', '==', depto.codigo).where('ativo', '==', 'SIM');
    
      this.database.queryValues('CATALOGO/' + this.selectedloja.id + '/PRODUTOS', query).subscribe(result => {
        this.items = result;
      })
  }
  }

  buscaProdutos() {
    this.items = [];
    if(this.selectedloja.id === 'MAGAZINE'){
      var url = environment.rooturl + "/buscaProdutos?id=" + this.searchStr +'_magazineluiza';
      this.analytics.logEvent('search_product', {userid: this.auth.User.id, search:this.searchStr});
      this.hideMenu = true;
      this.http.get(url).subscribe(result =>{
        this.items = [result];
        // this.setPrices();
      });
    }else{

      let query: QueryFn = ref => ref.where('nome', '==', this.searchStr).where('ativo', '==', 'SIM');
       
         this.database.queryValues('CATALOGO/' + this.selectedloja.id + '/PRODUTOS', query).subscribe(result => {
           this.items = result;
         })
     }
  }
    
 /*  getPage(page:number) {
    if(this.selectedloja.id === 'MAGAZINE'){
    this.items = [];
    var url = environment.rooturl + "/getProdutos?depto=" + this.currentDepto + '&page=' + page;
    this.analytics.logEvent('list_product', {userid: this.auth.User.id, depto:this.currentDepto, page:page});
    this.currentPage = page;
    this.http.get(url).subscribe(result =>{
      this.items = result as [];
      this.setPrices();
      });
    }
  } */

    getVitrine(id:any){
      this.items = [];
      if(id === 'MAGAZINE'){
        this.getProdutos({id:'UD'})
        // var url = environment.rooturl + "/displayVitrine";
        // this.http.get(url).subscribe(result =>{
        //   this.items = result as [];
         
        // });
      }else{
        this.database.listValues('CATALOGO/' + id + '/VITRINE').subscribe(result =>{
          this.items = result as [];
         
        })
      }
    }

    selectCategoria(depto){
      this.hideMenu = true;
      this.hideMenuEmitter.next(this.hideMenu);
      let query:QueryFn = ref => ref.where('depto', '==', depto).orderBy('valor')
     let sub:Subscription = this.database.queryValues('CATALOGO/MAGAZINE/PRODUTOS', query).subscribe(produtos =>{
      sub.unsubscribe();
       this.items = produtos;
       this.hideMenu = true;
       this.hideMenuEmitter.next(this.hideMenu);
       this.getPages();
       this.items.forEach(produto =>{
         if(produto.valor && !isNaN(parseFloat(produto.valor))){
           produto.pontos = Math.ceil(produto.valor) * this.campanha.conversion;
           produto.displayvalor = produto.pontos;
          }else{
            //this.getDetail(produto.codigo);
          }
      })
       window.scrollTo(0,0);
      })
    }

  getDetail(produto){
    
  }

  collapsibleClose() {
   //this.material.closeCollapsible();
   this.hideMenu = true;
   this.scrollService.scrollTo({target:'#top'});
  }

  selectProduct(produto:Produto){
    produto.parceiro = this.selectedloja.id;
    this._selectProduct.next(produto);
   }

  getPages(){
    this.totalPages = [];
    let total = 0;

    if(this.searchStr){
      total = parseInt(document.getElementById('totalfiltered').innerText);
    }else{
      total = 3000
    }

    for (let index = 1; index <= total / this.prodPerPage; index++) {
      this.totalPages.push(index);
    }
  }

  changeOrder(event){
    let order = event.target.value;
    this.currentOrder = order;
  }
}
