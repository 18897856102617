<div id="cards-favorites" class="col s12" [hidden]="!mycards?.length" >
  <h4 [style.font-family]="ws.website.font.family"  
  [style.font-family]="ws.website.font.family"  class="white-text left-align ">SEUS CARTÕES</h4>
  <div class="row">
    <ul>
      <li *ngFor="let card of mycards" [hidden]="selectedCard">
        <div (click)="selectCard(card)"  class="range-field col m4 s12 cards">
          <label for="" class="white-text">
            <img src="https://firebasestorage.googleapis.com/v0/b/smartpontos-prod.appspot.com/o/img%2Fa8cdb8c9-2c9f-4975-9eda-f63303f11974.jpg?alt=media&token=e8a19fbe-5d70-46e9-973a-f7394510626a" class="responsive-img" />
          </label>
          <div class="left col s12 white-text card-text">{{card.numero}}</div>
        </div>
      </li>
    </ul>
  </div>
  
  <form name="formRecargaCartao"  id="formRecargaCartao" [formGroup]="formRecargaCartao" action="" class="col s12" *ngIf="selectedCard" >
    <div class="row" *ngIf="selectedCard" >
      <p class="left col s12 m8 white-text "><span class="bold">Portador: </span>{{selectedCard.portador}}<br/>
      <span class="bold">Documento: </span>{{selectedCard.documento}}<br/>
     <span class="bold">Cartão: </span>{{selectedCard.numero}}</p>
      <div class="col s12 m4">
        <app-inputvalor [floatingtax]="0.01" [isemissao]="false"  [editable]="true" [(valor)]="valor" [service]="service" > </app-inputvalor>
           </div>
    </div>
    <div class="row">
      <div class="col s12">
        <button (click)="processTransaction()" class="waves-effect waves-light btn col m3 s12 right" [style.backgroundColor]="ws.website?.color1" id="bt-recarga-cartao"  >enviar recarga</button>
        <a class="btn-flat col m3 s12 right" id="bt-cancel-card" (click)="resetTransaction()">limpar</a>
      </div>
    </div>
  </form>
  </div>