<nav class=" main valign-wrapper" [style.backgroundColor]="website?.color1" role="navigation" >
  <div class="nav-wrapper  valign container">
    <a id="logo-container" class="brand-logo">
      <img src="{{website.logo}}" alt="{{website.title}}">
    </a>
    <ul class="right hide-on-med-and-down" *ngIf="website" >
      <li *ngFor="let section of website.sections "><a class="link_"  [style.font-family]="website.font.family" [ngxScrollTo]="'#' + section.id">{{section.title | uppercase}}</a></li>
      <li><a class="link_"  [ngxScrollTo]="'#register'"  [style.font-family]="website.font.family">CADASTRE-SE</a></li>
      <li><a class="link_"  [ngxScrollTo]="'#contato'"  [style.font-family]="website.font.family">CONTATO</a></li>
    </ul>

    <ul id="nav-mobile" class="sidenav sidenav-fixed grey lighten-3 hide-on-large-only"  >
      <li *ngFor="let section of website.sections "><a class="link btn-flat sidenav-close" [ngxScrollTo]="'#' + section.id">{{section.title | uppercase}}</a></li>
      <li><a class="link btn-flat sidenav-close"  [ngxScrollTo]="'#register'">CADASTRE-SE</a></li>
      <li><a class="link btn-flat sidenav-close"  [ngxScrollTo]="'#contato'">CONTATO</a></li>
    </ul>

    <div class=""  ><a data-target="nav-mobile" class="top-nav sidenav-trigger waves-effect waves-light circle link hide-on-large-only"><i class="material-icons ">menu</i></a></div>
    <!-- <a  data-target="nav-mobile" class="sidenav-trigger hide-on-large-only"><i class="material-icons">menu</i></a> -->
    </div>
</nav>