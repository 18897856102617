<div id="modalprodutos" class="modal webstore">
  <div class="modal-content" style="display: contents;">
    <div class="row center" style="padding-top: 20px;">
      <div class="col s12 m6 push-m3">
        <img *ngIf="produto.parceiro != 'MAGAZINE'" class="responsive-img center"  src="{{produto.imagem}}" alt="">
        <div *ngIf="produto.parceiro === 'MAGAZINE'" class="carousel">
          <a class="carousel-item" *ngFor="let foto of produto.medias"><img src="{{foto | imageUrl:300:180}}"></a>
        </div>     
      </div>
      <div class="col s12 m12">
        <div class=" row valign-wrapper">
          <h5 [style.font-family]="ws.website.font.family"  class="black-text col s9 m9 valign">{{produto.nome || produto.title | shorten:60:'...'}}</h5>
          <h4 [style.font-family]="ws.website.font.family"  *ngIf="displayvalor" class="black-text col s8  m3 right valign">{{displayvalor}}<span
              style="font-size:0.85rem; margin-left:8px; vertical-align:super">{{campanha.moeda}}</span></h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col s12">
        <ul class="tabs tabs-fixed-width">
          <li class="tab col s6"><a href="#tab1" [style.color]="ws.website?.color1"
              class="col s12 active">Descrição</a></li>
          <li class="tab col s6"><a href="#tab2" [style.color]="ws.website?.color1" class="col s12">Ficha Técnica</a>
          </li>
        </ul>
      </div>
      <div id="tab1" class="col s12 tab-content">
      </div>
      <div id="tab2" class="col s12 tab-content">
        <app-jsontable [data]="fichatecnica" ></app-jsontable>
      </div>
    </div>
  </div>
  <div class="modal-footer ">
    <div class="row">
      <a (click)="close()" class="btn-flat col m4 s4 center">cancelar</a>
      <app-spinner class="col m4 s5 right push-m1" *ngIf="!ready" ></app-spinner>
      <button *ngIf="ready" [disabled]="checkSaldo()" (click)="addItem()"
        [style.backgroundColor]="ws.website?.color1"
        class="white-text modal-action modal-close col m4 s6 btn center right pull-m1 ">
        <i class="material-icons ">add</i> <span class="">adicionar</span> </button>
    </div>
  </div>
</div>
