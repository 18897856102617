import { Input, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AuthService, MaterializeService } from '@ollieestudio/fire-lib';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ConfirmService } from 'src/app/services/confirm.service';
import { WebsiteService } from 'src/app/services/di/website.service';
import { Status } from 'src/app/services/enum/status.enum';
import { Premiado } from 'src/app/services/interfaces/premiado';
import { Service } from 'src/app/services/interfaces/service';
import { Transaction } from 'src/app/services/interfaces/transaction';
import { InputvalorComponent } from '../../shared/inputvalor/inputvalor.component';

@Component({
  selector: 'app-getpointsform',
  templateUrl: './getpointsform.component.html',
  styleUrls: ['./getpointsform.component.css']
})
export class GetpointsformComponent implements OnInit {
valor:number;
@Input() service:Service;
@ViewChild(InputvalorComponent, { static: true }) valorinput:InputvalorComponent;
  transac: Transaction;


  constructor(public ws:WebsiteService, public analytics:AnalyticsService, public material:MaterializeService, public auth:AuthService<Premiado>, public confirm:ConfirmService) { }

  ngOnInit(): void {
  }

  processTransaction(){
    let transac:Transaction = {} as Transaction;
    transac.enterprise = this.ws.website.enterprise;
    transac.cliente = this.ws.website.cliente;
    transac.campanha = this.ws.campanha.id;

    // transac.geo = this.geo.location;
    transac.identificador = this.service.title + this.auth.User.documento;
    transac.status = Status['novo'];
    transac.tipo = this.service.type;
    transac.usuario = this.auth.User.id;
    transac.authid = this.auth.User.authid;
    transac.valorin = Math.ceil(this.valorinput.valor);
    transac.privatedata = {};
    transac.privatedata['valor'] = this.valorinput.valor;
    transac.privatedata['taxa'] = this.valorinput.taxmodel;
    transac.privatedata['documento'] = this.auth.User.documento;
    transac.privatedata['nomecliente'] = this.auth.User.nomecliente;
    
    this.confirm.openConfirm(transac);

    this.confirm.resultEmitter.subscribe(result =>{
      if(result === true){
        this.material.toast(this.confirm.generateCheckMessage('Sua recarga foi solicitada, prossiga para o pagamento!'), 5000, 'green full');
        this.transac = this.confirm.transac;
        this.analytics.logEvent('getpoints_init', {userid: this.auth.User.id});
      }else{
        this.material.toast(this.confirm.generateErrorMessage("Sua recarga não foi processada!"), 5000, 'red full');
        this.analytics.logEvent('getpoints_error', {userid: this.auth.User.id});
      }
    })
  }

  resetTransaction(){
    this.valorinput.reset();
    this.transac = null;
  }
}
