import { Component, OnInit, Input } from '@angular/core';
import { Transaction } from 'src/app/services/interfaces/transaction';
import { ChartDataset, ChartOptions } from 'chart.js';
// import { Color, Label } from 'ng2-charts';
import { WebsiteService } from 'src/app/services/di/website.service';

@Component({
  selector: 'app-performance-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.css']
})
export class ChartComponent implements OnInit {
  @Input() extrato:Transaction[];
  lineChartData: ChartDataset[] = [];

  lineChartLabels: string[] = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN','JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'];

  lineChartOptions = {
    responsive: true,
    legend:{labels:{fontColor:'white'}},
    scales: {
      yAxes: [{
          ticks: {
              fontColor: "white",
              fontSize: 10,
              stepSize: 1,
              beginAtZero: true
          }
      }],
      xAxes: [{
          ticks: {
              fontColor: "white",
              fontSize: 10,
              stepSize: 1,
              beginAtZero: true
          }
      }]
  }
  };

  lineChartColors: any[] = [
    {
      borderColor: 'black',
      backgroundColor: this.ws?.website?.color1,
      borderWidth:1
    },
    {
      borderColor: 'black',
      backgroundColor: 'white',
      borderWidth:1
    },
  ];

  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType = 'line';
  
  constructor(public ws:WebsiteService) { }

  ngOnInit() {
    this.getDataSets();
  }
  getDataSets() {
    let cargas:any[];
    let resgates:any[];

    cargas = [0,0,0,0,0,0,0,0,0,0,0,0];
    resgates = [0,0,0,0,0,0,0,0,0,0,0,0];

    this.extrato.forEach(item =>{
      let mes:number = new Date(item.datacriacao).getMonth(); 
      let year:number = new Date(item.datacriacao).getFullYear(); 
      let today = new Date();
      if(item.valorout > 0 && year === today.getFullYear()){
        resgates[mes] += item.valorout;
      }
      if(item.valorin > 0 && year === today.getFullYear()){
        cargas[mes] += item.valorin;
      }
    })

    this.lineChartData = [
      { data:cargas, label: 'Cargas de Pontos' },
      { data:resgates, label: 'Resgates' }
    ]

  }

}
