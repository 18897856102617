<div class="toppad">
    &nbsp;
  </div>
  <div class="container top-5">
    <div class="row" *ngIf="service">
      
      <h3 [style.font-family]="ws.website.font.family"  class="col s12 m11 white-text upper"><i class="material-icons">{{service.icon}}</i>&nbsp;{{service.title}}
      </h3>
    </div>
    <div class="row">
      
      <div class="col s12 m11 instrucoes">
        <h5 [style.font-family]="ws.website.font.family"  class="white-text">INSTRUÇÕES</h5>
            <ul class="browser-default">
                <li><span class="white-text">São aceitos pagamentos PIX para todos os bancos. </span></li>
                <li><span class="white-text">Pagamentos para CPF são limitados a R$ 100,00 </span></li>
                <li><span class="white-text">Prazo para efetivação do PIX: De 2 minutos a 1 dia útil. </span></li>
                <li><span class="white-text">A Taxa de Serviço será descontada do valor total enviado. </span></li>
                <li><span class="white-text">Limite por solicitação: {{service.limite*ws.campanha.conversion}} {{ws.campanha.moeda}}</span></li>
                <li *ngIf="service"><span class="white-text bold">Taxa de Serviço: {{service.tax*ws.campanha.conversion}} {{ws.campanha.moeda}}  </span></li> 
            </ul>
      </div>
    </div>
    <div class="row">
     
        <a class="btn col m3 s12"  
        [style.backgroundColor]="ws.website?.color1"(click)="handle(action, action.isStart ? 'stop' : 'start')">
        <i class="material-icons">camera</i> {{action.isStart ? 'parar' : 'escanear'}}</a>
        <a class="btn col m3 s12"  
        [style.backgroundColor]="ws.website?.color1"(click)="qrcode = 'colar'">
        <i class="material-icons">content_copy</i> copiar e colar</a>
   
    </div>
  
    <div class="col s12" [hidden]="!action.isStart || qrcode">
      <ngx-scanner-qrcode #action="scanner" [constraints]="constraints" (event)="qrCodeReady($event)" ></ngx-scanner-qrcode>
    </div>
  
    <div class="row" *ngIf="showspinner">
      <app-spinner class="col s2 push-s5"   ></app-spinner>
    </div>
    
    <div class="row" [hidden]="!qrcode">
      <form id="formPayment" name="formQRPay" [formGroup]="formQRPay" action="" class="col s12">
    
        <div class="row">
          <div class="input-field col s12" >
            <input id="qrcode" name="qrcode" type="text" class="validate white-text" 
              [formControlName]="'qrcode'" (change)="qrCodePaste($event)" />
            <label for="doccedente" class="active white-text text-lighten-1">Código</label>
          </div>
        </div>
        <div class="row" [hidden]="!privatedata">
          <div class="input-field col s12" >
            <input id="beneficiado" name="beneficiado" type="text" class="validate white-text" [readOnly]="true"
              [formControlName]="'beneficiado'" />
            <label for="beneficiado" class="active white-text text-lighten-1">Beneficiado</label>
          </div>
        </div>
        <div class="row" [hidden]="!privatedata">
          <div class="input-field col s12" >
            <input id="doccedente" name="doccedente" type="text" class="validate white-text" [readOnly]="true"
              [formControlName]="'doccedente'" />
            <label for="doccedente" class="active white-text text-lighten-1">CPF/CNPJ Beneficiado</label>
          </div>
        </div>

        <div class="row" [hidden]="!privatedata">
          <app-inputvalor id="valorinput" #valorinput [isemissao]="false" [editable]="true" (model)="valor" [service]="service"> </app-inputvalor>
        </div>
        
        <div class="col s12" >
          <button class="waves-effect waves-light btn col m3 s12 right"  [style.backgroundColor]="ws.website?.color1"
          [disabled]="formQRPay.invalid" id="bt-payment"
            (click)="processTransaction()">enviar</button>
          <a class="btn-flat col m3 s12 right" id="bt-cancel-payment" (click)="resetTransaction()">cancelar</a>
        </div>
      </form>
    </div>
  </div>