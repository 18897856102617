<div id="phoneselect" class="row top-10"></div>
<div id="" class="row top-10"></div>
<div id="formNovaRecargaContainer" class="editcontainer col s12 m5" >
  <form id="formRecarga" name="formRecarga" [formGroup]="formRecarga" action="" class="col s12">
    <h4 [style.font-family]="ws.website.font.family"  class="white-text">NOVA RECARGA</h4>
    <div class="row s12">
      <p class="col s12 white-text">Selecione a operadora</p>

      <div class="input-field col s12 m6">
        <select class="browser-default black-text" name="operadora" [formControlName]="'operadora'"
          (change)="selectOperadora($event)">
          <option *ngFor="let operadora of operadoras" value="{{operadora.nome}}">{{operadora.nome}} </option>
        </select>
      </div>

      <div class="input-field col s12 m6" *ngIf="selectedOperadora">
        <select class="browser-default" name="valor" [formControlName]="'valor'" (change)="selectValor($event)">
          <option *ngFor="let valor of selectedOperadora.valores.valorDisponivel" value="{{valor.valor/100}}">{{valor.valor/100 | currency}} </option>
        </select>
      </div>
    </div>

    <div class="row" *ngIf="privatedata.valor || selectedPhone">
      <div class="input-field col s12">
        <input id="nome" type="text" class="validate white-text"
          [formControlName]="'nome'" />
        <label for="nome" class="white-text active">Nome (opcional)</label>
      </div>
    </div>

    <div class="row" *ngIf="privatedata.valor || selectedPhone">
      <div class="input-field col s12 m6">
        <input id="celular" [textMask]="{mask:masks.maskCelular, guide:false}" type="tel" class="validate white-text"
          [formControlName]="'celular'" />
        <label for="celular" class="white-text active">DDD + Celular</label>
      </div>
      <div class="input-field col s12 m6">
        <input id="confirm" name="confirm" [textMask]="{mask:masks.maskCelular, guide:false}" type="tel"
          class="validate white-text"
          [formControlName]="'confirm'" />
        <label for="confirm" class="white-text active">DDD + Celular (confirmar)</label>
        <!-- <p class="tiny black-text text-lighten-2"
          ng-show="!formRecarga.$pristine && formRecarga.confirm.$error.validCel">
          <i class="material-icons yellow-text">report_problem</i>
          Números não conferem</p> -->
        <!-- <p class="tiny black-text text-lighten-2"
          ng-show="!formRecarga.$pristine && formRecarga.confirm.$error.validValor">
          <i class="material-icons yellow-text">report_problem</i>
          Valor não permitido</p> -->
      </div>
    </div>
    <div class="row"  *ngIf="privatedata.valor || selectedPhone">
     <p class="right-align">
        <label>
          <input type="checkbox" class="filled-in white" checked="checked" [formControlName]="'favorite'"  />
          <span class="white-text">Salvar em favoritos</span>
        </label>
      </p>
    </div>

    <div class="row" *ngIf="service">
      <h6 class="white-text text-lighten-3">Taxa de serviço: <span
          class="white-text bold">{{service.tax}}</span></h6>
    </div>

    <div class="row">

      <div class="col s12 m6">
        <button class="waves-effect waves-light btn col m5 s12 right" [style.backgroundColor]="ws.website?.color1"
        [disabled]="!formRecarga.valid || formRecarga.controls['celular'].value != formRecarga.controls['confirm'].value
        || (formRecarga.controls['favorite'].value == true && formRecarga.controls['nome'].value == '') " id="bt-send-mobile"
          (click)="processTransaction()">enviar</button>
        <a class="btn-flat col m3 s12 right" id="bt-cancel-mobile" (click)="resetTransaction()">limpar</a>
      </div>
    </div>
  </form>