import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AuthService, FormService, InputBase, MaterializeService } from '@ollieestudio/fire-lib';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ConfirmService } from 'src/app/services/confirm.service';
import { WebsiteService } from 'src/app/services/di/website.service';
import { Tipo } from 'src/app/services/enum/tipo.enum';
import { Premiado } from 'src/app/services/interfaces/premiado';
import { Service } from 'src/app/services/interfaces/service';
import { FormGroup } from '@angular/forms';
import { Transaction } from 'src/app/services/interfaces/transaction';
import { Status } from 'src/app/services/enum/status.enum';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { InputvalorComponent } from '../shared/inputvalor/inputvalor.component';
import { NgxScannerQrcodeComponent } from 'ngx-scanner-qrcode';
import { Router } from '@angular/router';

@Component({
  selector: 'app-qrcodepay',
  templateUrl: './qrcodepay.component.html',
  styleUrls: ['./qrcodepay.component.css']
})
export class QrcodepayComponent implements OnInit, AfterViewInit {
//@ViewChild(CardsFavoritesComponent) favorites:CardsFavoritesComponent;
  //private selectedCard:Card;
  servicetype: string =  Tipo['qrcodepay'];
  service:Service;
  showForm:boolean = true;
  formQRPay:FormGroup;
  showspinner:boolean = false;

  qrcode: any;
  @ViewChild(InputvalorComponent, { static: false }) valor:InputvalorComponent;
  @ViewChild(NgxScannerQrcodeComponent, { static: true }) scanner:NgxScannerQrcodeComponent;
  
  public  constraints = {audio:false,video:true, facingMode:'environment'};

  private fields:InputBase[] = [
    {key:"qrcode", required:true},
    // {key:"valor", required:true},
    {key:"doccedente", required:true},
    {key:"beneficiado", required:true},
  ]
  privatedata: any;
  
  constructor(public router:Router, public material:MaterializeService, public http:HttpClient, public analytics:AnalyticsService, private formServ:FormService, public ws:WebsiteService,  public confirm:ConfirmService, public auth:AuthService<Premiado>) { 
    let sub = this.ws.websiteEmitter.subscribe(website =>{
      sub.unsubscribe();
      this.service = {...this.ws.campanha.services[this.servicetype], ...this.ws.services.filter(service => service.type === this.servicetype)[0]};
    })
    this.formQRPay = this.formServ.toFormGroup(this.fields)
  }
  
  ngAfterViewInit(): void {
    this.scanner.isReady.subscribe((res: any) => {
      //  this.handle(this.scanner, 'start');
    });
  }

  public handle(action: any, fn: string): void {
    const playDeviceFacingBack = (devices: any[]) => {
      // front camera or back camera check here!
      const device = devices.find(f => (/back|rear|environment/gi.test(f.label))); // Default Back Facing Camera
      action.playDevice(device ? device.deviceId : devices[0].deviceId);
    }

    if (fn === 'start') {
      action[fn](playDeviceFacingBack).subscribe((r: any) => console.log(fn, r), alert);
    } else {
      action[fn]().subscribe((r: any) => console.log(fn, r), alert);
      this.resetTransaction();
      this.router.navigateByUrl('home/start')
    }
  }

   ngOnInit() {
    this.service = {...this.ws.campanha.services[this.servicetype], ...this.ws.services.filter(service => service.type === this.servicetype)[0]};
    this.confirm.resultEmitter.subscribe(result =>{
      if(result === true){
        this.material.toast(this.confirm.generateCheckMessage("Seu pagamento foi incluído com sucesso!"), 5000, 'green full');
        this.analytics.logEvent('qrcode_complete', {userid: this.auth.User.id});
        this.resetTransaction();
      }else{
        this.material.toast(this.confirm.generateErrorMessage("Seu pagamento não foi incluído!"), 5000, 'red full');
        this.analytics.logEvent('qrcode_error', {userid: this.auth.User.id});
        this.resetTransaction();
      }
    })
   }

  qrCodeReady($event){
    this.analytics.logEvent('qrcode_scan', {userid: this.auth.User.id});
    this.qrcode = $event[0].value;
    this.scanner.stop();
    this.getPaymentPreview(this.qrcode);
  }

  qrCodePaste($event){
    this.analytics.logEvent('qrcode_paste', {userid: this.auth.User.id});
    this.qrcode = $event.currentTarget.value;
    this.getPaymentPreview(this.qrcode);
  }

  async getPaymentPreview(qrcode:string){
    this.showspinner = true;
    let url = environment.rooturl + "/getPaymentPreview?code=" + qrcode;
     this.http.get(url).subscribe((result:any) =>{
    this.showspinner = false;
       if(!result.erro){
        if(this.checkExposedNumbersMatch(result.taxId, this.auth.User.documento)){
          this.material.toast("Não é permitido pagamento para o próprio CPF/CNPJ", 5000, 'red full');
          this.router.navigateByUrl('home/start');
        }else{
        if(result.taxId.length <= 14 && result.amount/100 > 100){
          this.material.toast("Pagamentos para CPF são limitados a R$ 100", 5000, 'red full');
        }else{
            this.formQRPay.patchValue({qrcode: qrcode, valor: result.amount, beneficiado: result.name, doccedente: result.taxId});
            this.privatedata = {qrcode: this.qrcode, paymentdata: result};
            if(result.amount) {
              this.valor.valor = result.amount/100;
              this.valor.checkAmount();
              // this.valor.editable = false;
            }else{
              this.valor.editable = true;
              if(result.taxId.length <= 14 ){
                // this.material.toast("Pagamentos para CPF são limitados a R$ 100", 5000, 'red full');
                this.valor.max = 100;
              }
            }
          }
        }
      }
     }, (error) =>{
      this.material.toast("Houve um erro ao ler o QRCode", 5000, 'red full');
      this.resetTransaction();
      this.router.navigateByUrl('home/start')
     });
   }

   processTransaction(){
    let transac:Transaction = {} as Transaction;
    let formData:any = this.formQRPay.getRawValue();

    transac.enterprise = this.ws.website.enterprise;
    transac.cliente = this.ws.website.cliente;
    transac.identificador = this.service.title;
    transac.status = Status['novo'];
    transac.tipo = Tipo['qrcodepay'];
    transac.usuario = this.auth.User.id;
    transac.authid = this.auth.User.authid;
    transac.valorout = this.valor.valor + Math.ceil(this.valor.taxmodel);
    transac.privatedata = this.privatedata;
    transac.privatedata['beneficiado'] = formData.beneficiado;
    transac.privatedata['pjpf'] = this.auth.User.pjpf;
    transac.privatedata['documento'] = this.auth.User.documento.replace(/\D/g,'');
    transac.privatedata['nome'] = this.auth.User.nome;
    transac.privatedata['doccedente'] = formData.doccedente;
    transac.privatedata['tipoconta'] = 'qrcode';
    transac.privatedata['pjpf_cedente'] = 'J';
    transac.privatedata['valor'] = this.valor.valor;

    

    this.confirm.openConfirm(transac);
  }

  resetTransaction(){
    this.formQRPay.reset();
    this.privatedata = {};
    this.scanner.data = null;
    this.qrcode = null;
    this.router.navigateByUrl('home/start')
  }

  getConstraints($event){
    console.log($event)
  }

  checkExposedNumbersMatch(exposedString, targetString) {
    // Remove ".", "/", and "-" from exposedString
    exposedString = exposedString.replace(/[./-]/g, '');
  
    // Create a regular expression pattern to match "*"
    const asteriskPattern = /\*/g;

    // Replace characters from targetString that match "*" character in exposedString by "*"
    targetString = targetString.replace(/./g, (match, index) => {
      return exposedString[index] === '*' ? '*' : match;
    });
  
    // Return if resulting strings are the same
    return exposedString === targetString;
  }
}
