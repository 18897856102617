import { Component, OnInit, ViewChild } from '@angular/core';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { WebsiteService } from 'src/app/services/di/website.service';
import { ConfirmService } from 'src/app/services/confirm.service';
import { AuthService } from '@ollieestudio/fire-lib';
import { Premiado } from 'src/app/services/interfaces/premiado';
import { Tipo } from 'src/app/services/enum/tipo.enum';
import { Service } from 'src/app/services/interfaces/service';
import { MobileFavoritesComponent } from './favorites/favorites.component';
import { Phone } from 'src/app/services/interfaces/phone';
import { MobileFormComponent } from './form/form.component';

@Component({
  selector: 'app-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.css']
})
export class MobileComponent implements OnInit {
  @ViewChild(MobileFavoritesComponent, { static: true }) favorites:MobileFavoritesComponent;
  @ViewChild(MobileFormComponent, { static: true }) form:MobileFormComponent;
  servicetype: string =  Tipo['mobile'];
  service:Service;
  showForm:boolean = true;
  addressForm: any;

  constructor(public scrollService: ScrollToService, public ws:WebsiteService,  public confirm:ConfirmService, public auth:AuthService<Premiado>)
   { 
    let sub = this.ws.websiteEmitter.subscribe(website =>{
      sub.unsubscribe();
      this.service = {...this.ws.campanha.services[this.servicetype], ...this.ws.services.filter(service => service.type === this.servicetype)[0]};
    })
   }

   ngOnInit() {
      window.scrollTo(0,0);
      this.service = {...this.ws.campanha.services[this.servicetype], ...this.ws.services.filter(service => service.type === this.servicetype)[0]};
      this.confirm.resultEmitter.subscribe(result =>{
      if(result === true){
       this.showForm = true;
       }
    })
    this.favorites.phoneEmitter.subscribe(phone =>{
      this.form.selectPhone(phone);
      this.enablePhoneForm();
    })
  }
  
  enablePhoneForm(){
    this.showForm = false;
    this.scrollService.scrollTo({target:'#formNovaRecargaContainer'});
    this.form.hideformEmitter.subscribe(hide => {
      this.showForm = true;
      this.scrollService.scrollTo({target:'#topmobile'});
    });
  }

}
