<div id="bank-favorites" class="col s12" style="z-index:1001">
  <h4 [style.font-family]="ws.website.font.family" [style.font-family]="ws.website.font.family"
    *ngIf="serviceType === 'online_transf' && myaccounts?.length" class="col s12 white-text left-align">SUAS CONTAS BANCÁRIAS</h4>
  <h4 [style.font-family]="ws.website.font.family" [style.font-family]="ws.website.font.family"
    *ngIf="serviceType === 'digital_transf'  && myaccounts?.length" class="col s12 white-text left-align">SUAS CONTAS DIGITAIS</h4>
  <h4 [style.font-family]="ws.website.font.family" [style.font-family]="ws.website.font.family"
    *ngIf="serviceType === 'online_pix'  && myaccounts?.length" class="col s12 white-text left-align">SUAS CONTAS PIX</h4>
  <div class="row">
    <div (click)="selectAccount(account)" [hidden]="(selectedAccount != null && selectedAccount != account) || account.status == 'NOVO'"
      [ngStyle]="{'background-color': (serviceType === 'digital_transf')? ws.website?.color1 : account.bgcolor || ws.website?.color1 }"
      [ngClass]="{'round black-text bold': serviceType === 'digital_transf', 'white-text': serviceType === 'online_transf', 'inativa': account.status === 'INVALIDO'}"
      class="col favorite z-depth-1" *ngFor="let account of myaccounts">
      <i (click)="deleteAccount(account)"  class="material-icons delicon black-text">close</i>
      <h3 [style.font-family]="ws.website.font.family" class="center bold">{{account?.apelido?.substr(0,2)}}</h3>
      <p class="center">{{serviceType === 'online_transf'?account?.conta : account?.chavepix | shorten:8}}</p>
    </div>
    <div class="col s8 grey-text text-lighten-2 small-text" style="padding-top: 10px;" *ngIf="selectedAccount">
      <span class="col s4 bold account">Nome:</span>
      <span class="col s8 account">{{selectedAccount.nome || selectedAccount.apelido | shorten:14}}</span>
      <span  class="col s4 bold account">CPF/CNPJ:</span>
      <span  class="col s8 account">{{selectedAccount.documento}}</span>
      <span  class="col s3 bold account">Banco:</span>
      <span  class="col s3 account">{{selectedAccount.banco | shorten:8}}</span>
      <span  class="col s3 bold account">Ag.:</span>
      <span  class="col s3 account">{{selectedAccount.agencia}}</span>
      <span  class="col s3 bold account">Tipo:</span>
      <span  class="col s3 account">{{selectedAccount.tipoconta}}</span>
      <span  class="col s3 bold account">Conta:</span>
      <span  class="col s3 account">{{selectedAccount.conta}}</span>
      <span *ngIf="selectedAccount.tipo === 'online_pix'" class="col s4 bold account">Chave:</span>
      <span *ngIf="selectedAccount.tipo === 'online_pix'" class="col s8 account">{{selectedAccount.chavepix}}</span>
    </div>
  </div>
  <div class="row" *ngIf="selectedAccount">
    <div class="col s12">
      <app-inputvalor #inputvalor [floatingtax]="false" [taxmodel]="taxmodel" [isemissao]="false" [editable]="true" (model)="valor" [service]="service">
      </app-inputvalor>
    </div>
    <div class="col s12">
      <button (click)="processTransaction()" [disabled]="inputvalor.valor <= 0" class="waves-effect waves-light btn col m3 s12 right"
        [style.backgroundColor]="ws.website?.color1" [style.font-family]="ws.website.font.family"
        id="bt-bank-transf">transferir</button>
        
      <a class="btn-flat col m3 s12 right" id="bt-cancel-bank" (click)="resetTransaction()">limpar</a>
    </div>
  </div>
</div>