import { Component, OnInit, Input } from '@angular/core';
import { Transaction } from 'src/app/services/interfaces/transaction';
import { WebsiteService } from 'src/app/services/di/website.service';
import { PrintService } from 'src/app/services/print.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-extrato-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {
@Input() extrato:Transaction[];
@Input() searchStr:string;
@Input() hidetransacs:boolean;

  constructor(public ws:WebsiteService, public http:HttpClient, public print:PrintService) { }

  ngOnInit() {
  }

  orderBy(col){
    
  }

  downloadReceipt(transaction){
    this.print.printReceipt(transaction);
  }

  checkStatus(transaction){
    let url = environment.rooturl + "/checkStatus?id=" + transaction.id;
    this.http.get(url).subscribe((result:any) =>{
    })
  }
}
