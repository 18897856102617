import { Component, ElementRef, OnInit, Sanitizer, ViewChild } from '@angular/core';
import { WebsiteService } from 'src/app/services/di/website.service';
import { Campanha } from 'src/app/services/interfaces/campanha';
import { Enterprise } from 'src/app/services/interfaces/enterprise';
import { Cliente } from 'src/app/services/interfaces/cliente';
import { DatabaseService, AuthService, MaterializeService } from '@ollieestudio/fire-lib';
import { Premiado } from 'src/app/services/interfaces/premiado';
import { ConfirmService } from 'src/app/services/confirm.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { UpdatesService } from '../updates.service';

declare var regulamentoVar: any

@Component({
  selector: 'app-regulamento',
  templateUrl: './regulamento.component.html',
  styleUrls: ['./regulamento.component.css']
})
export class RegulamentoComponent implements OnInit {
  enterprise:Enterprise;
  campanha: Campanha;
  cliente: Cliente;
  regulamentoFinal: string;
  premiado: Premiado;
  videofinished: boolean = false;
  videostart: boolean;

  constructor(public analytics:AnalyticsService, public service:UpdatesService, public material:MaterializeService, public confirm:ConfirmService, public ws:WebsiteService, public database:DatabaseService<any>, public auth:AuthService<Premiado>, public sce:Sanitizer) { }

  ngOnInit() {
    this.campanha = this.ws.campanha;
    this.premiado = this.auth.User;
    let sub = this.database.get(this.campanha.enterprise, "ENTERPRISE", "default").subscribe(enterprise =>{
       sub.unsubscribe();
       this.enterprise = enterprise.data().Dados as Enterprise;
       let sub2 = this.database.get(this.campanha.cliente, "CLIENTES", "default").subscribe(cliente =>{
           sub2.unsubscribe();
           this.cliente = cliente.data() as Cliente;
           this.geraRegulamento();
          })
      })
      if(!this.auth.User.hasRegulamento){
      window.setTimeout(async () =>{
        this.videostart = true;
        await this.material.delay(1000);
        document.getElementById('lgpdvideo')['muted'] = true;
        // this.muteVideo();
        window.setTimeout(() =>{
          document.getElementById('lgpdvideo').addEventListener('ended',this.videoHandler,false);
          this.videofinished = true;
        }, 45000);
        }, 8000)
      }
    }

  videoHandler(event) {
    document.getElementById('lgpdvideo').style.display = "none";
  }

  muteVideo(event) {
    event.currentTarget['muted'] = true;
  }

  geraRegulamento() {
    this.analytics.logEvent('get_regulamento', {userid: this.auth.User.id});
    regulamentoVar = regulamentoVar.split('%%ENTERPRISE.NOME%%').join(this.enterprise.nomeFantasia.toUpperCase());
    regulamentoVar = regulamentoVar.split('%%ENTERPRISE.RAZAO%%').join( this.enterprise.razaoSocial.toUpperCase());
    regulamentoVar = regulamentoVar.split('%%ENTERPRISE.CNPJ%%').join( this.enterprise.cnpj.toUpperCase());
    regulamentoVar = regulamentoVar.split('%%ENTERPRISE.ENDERECO%%').join( this.formatAddress(this.enterprise.endereco).toUpperCase());
    regulamentoVar = regulamentoVar.split('%%CLIENTE.NOME%%').join( this.cliente.nomeFantasia.toUpperCase());
    regulamentoVar = regulamentoVar.split('%%CLIENTE.RAZAO%%').join( this.cliente.razaoSocial.toUpperCase());
    regulamentoVar = regulamentoVar.split('%%CLIENTE.CNPJ%%').join( this.cliente.cnpj.toUpperCase());
    regulamentoVar = regulamentoVar.split('%%CLIENTE.ENDERECO%%').join( this.formatAddress(this.cliente.Endereco[0]).toUpperCase());
    regulamentoVar = regulamentoVar.split('%%MOEDA%%').join( this.campanha.moeda.toUpperCase());
    regulamentoVar = regulamentoVar.split('%%DOMAIN%%').join( this.campanha.url.toLowerCase());
    this.regulamentoFinal = regulamentoVar;

    if (this.campanha.regulamento) {
      this.regulamentoFinal += this.campanha.regulamento;
    }

    this.regulamentoFinal = this.regulamentoFinal.split('%%PREMIADO.NOME%%').join( this.premiado.nome);
    var txtcpf = "";
    if (this.premiado.pjpf === 'Pessoa Física') {
      txtcpf = " sob o CPF " + this.premiado.documento;// + " residente à " + this.formatAddress(this.premiado.Enderecos[0]);
    } else {
      txtcpf = " sob o CNPJ " + this.premiado.documento; //+ " com sede à " + this.formatAddress(this.premiado.Enderecos[0]);
    }

    var date = new Date();
    var options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };

    this.regulamentoFinal = this.regulamentoFinal.split('%%PREMIADO.NOME%%').join( this.premiado.nome);
    this.regulamentoFinal = this.regulamentoFinal.split('%%PREMIADO.DADOS%%').join( txtcpf);
    this.regulamentoFinal = this.regulamentoFinal.split('%%DATA%%').join( date.toLocaleDateString('pt-BR'));
    document.getElementById("regulamentoContainer").innerHTML = this.regulamentoFinal;
    // this.regulamentoFinal = this.sce.sanitize(, regulamentoFinal);
    this.enterprise = null;
    this.cliente = null;
  }
  
  formatAddress(endereco: any):string {
    return "";
  }

    acceptRegulamento() {
    this.premiado.hasRegulamento = true;
    this.database.update(this.premiado, "PREMIADOS").then(function (result) {
    });
    this.material.toast(this.confirm.generateCheckMessage("Obrigado por aceitar o Regulamento!"), 5000, 'green full');
    this.analytics.logEvent('regulamento_complete', {userid: this.auth.User.id});
    this.material.closeModal('#modalregulamento');
  };

}
