<div class="slider row s12 top-5" slider >
    <ul class="slides" *ngIf="website">
      <li *ngFor="let section of website.sections">
        <img src="{{section.background}}" class="banner-img">
        <!-- random image -->
        <div class="caption center-align" *ngIf="section.type == 'header'">
          <h2 [style.font-family]="website.font.family" class="center white-text text-darken-3">{{section.heading}}</h2>
          <h5 [style.font-family]="ws.website.font.family"  [style.font-family]="website.font.family" class="light white-text text-darken-3">{{section.slogan}}</h5>
        </div>
        <div class="caption right-align" *ngIf="section.type != 'header'">
          <h2 [style.font-family]="website.font.family" class="white-text text-darken-3">{{section.heading}}</h2>
          <h5 [style.font-family]="ws.website.font.family"  [style.font-family]="website.font.family" class="light white-text text-darken-3">{{section.slogan}}</h5>
        </div>
      </li>
      <li *ngFor="let banner of bannerscrm">
        <img src="{{banner.imagem}}" class="banner-img">
        <!-- random image -->
        <div class="caption center-align">
          <h2 [style.font-family]="website.font.family" class="center white-text text-darken-3">{{banner.assunto}}</h2>
        </div>
      </li>
    </ul>
  </div>
  