import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { Campanha } from 'src/app/services/interfaces/campanha';
import { Service } from 'src/app/services/interfaces/service';
import { ConfirmService } from 'src/app/services/confirm.service';
import { MaterializeService, AuthService, CookieService } from '@ollieestudio/fire-lib';
import { Premiado } from 'src/app/services/interfaces/premiado';
import { Transaction } from 'src/app/services/interfaces/transaction';
import { Status } from 'src/app/services/enum/status.enum';
import { Tipo } from 'src/app/services/enum/tipo.enum';
import { AddressformComponent } from '../../dados/addressform/addressform.component';
import { WebsiteService } from 'src/app/services/di/website.service';
import { Produto } from 'src/app/services/interfaces/produto';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { Endereco } from 'src/app/services/interfaces/endereco';

@Component({
  selector: 'app-shop-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class ShopCheckoutComponent implements OnInit {
@ViewChild(AddressformComponent, { static: true }) address:AddressformComponent;
@Output() resetCheckout:EventEmitter<string> = new EventEmitter();
@Input() campanha:Campanha;
@Input() service: Service;
cartList: any[];
privatedata:any = {retido:0, produtos:[], frete:null, endereco:{}};
displaytax: number;
cookieCart: CookieService < Produto[] > = new CookieService();
// endereco:Endereco;

constructor(public analytics:AnalyticsService, public ws:WebsiteService, public http:HttpClient,  public confirm:ConfirmService, public material:MaterializeService, public auth:AuthService<Premiado>) { }

ngOnInit() {
 let sub = this.address.addressEmitter.subscribe(address => {
   if(!this.privatedata.endereco || this.privatedata.endereco.cep != address.cep){
     this.privatedata.endereco = address;
     this.calculaFrete(address)
   }
  })
  this.address.numEmitter.subscribe(num => {
      this.privatedata.endereco = this.address.formAddress.getRawValue();
  })
}

 removeProd(produto, index){
    this.analytics.logEvent('shop_remove_product', {userid: this.auth.User.id, product:this.cartList[index]});
    this.cartList.splice(index, 1);
    if(this.cartList.length == 0) {
      this.resetFrete();
      this.resetTransaction();
    }else{
      if(this.privatedata.endereco) this.calculaTotal();
    }
    this.cookieCart.set(this.cartList,this.ws.hostname+'_cart');
  }

 resetFrete(){
     this.privatedata.frete = null;
     this.privatedata.endereco = null;
    //  this.endereco = null;
     this.address.reset();
   }

  calculaFrete(endereco:Endereco){
     if(endereco.cep){
      this.analytics.logEvent('checkout_process', {userid: this.auth.User.id});
       this.cartList.forEach(async element => {
        await this.getFrete(element, endereco);
      });
    }
}

 async getFrete(element:Produto, endereco:Endereco){
   // if(element.parceiro === 'MAGAZINE'){
    // let url = environment.rooturl + "/calculaFrete?produto=085902400&cep=08090284&seller=" + element.seller_id;
    let url = environment.rooturl + "/calculaFrete?produto=" + element.codigo + "&cep=" + endereco.cep.replace('-','') + "&seller=" + element.seller_id;
    this.http.get(url).subscribe((result:any) =>{
      if(!result.package){
        element.frete = 0;
        element.quantidade = 0;
        element.error = true;
        alert('O item ' + element.nome + ' não está disponível para entrega.')
        this.calculaTotal();
      }else{
        element.frete = Math.ceil(result.package.delivery_types[0]['price']);
        if( element.frete == 0)  element.frete = 6;
        this.calculaTotal();
      }
    });
   // }else{
   //   if( element.frete == 0 || !element.frete)  element.frete = 8;
   // }
  }

  calculaTotal(){
    this.privatedata.retido = 0
    this.privatedata.frete = 0
    this.privatedata.produtos = this.cartList.filter(item => !item.error);
    
    this.privatedata.produtos.forEach(element => {
      this.privatedata.retido += element.pontos*element.quantidade;
      this.privatedata.frete += element.frete;
    });
  }

  checkOut(){
   
    if(this.privatedata.endereco) this.calculaFrete(this.privatedata.endereco);
   
      this.displaytax = this.service.tax;
      this.cookieCart.set(this.privatedata.produtos,this.ws.hostname+'_cart');
  }

  continueShopping(){
    this.resetCheckout.next('reset');
  }

  resetTransaction(){
    this.cartList = null;
    this.privatedata =  {retido:0, produtos:[], frete:null, endereco:{}};
    this.address.reset();
    this.resetCheckout.next('reset');
  }

  processTransaction(){
    this.confirm.resultEmitter.subscribe(result =>{
      if(result === true){
        this.material.toast(this.confirm.generateCheckMessage("Seu resgate foi solicitado com sucesso!"), 5000, 'green full');
        this.analytics.logEvent('shop_complete', {userid: this.auth.User.id, produtos:this.cartList});
        this.resetTransaction();
      }else{
        this.material.toast(this.confirm.generateErrorMessage("Seu resgate não foi processado!"), 5000, 'red full');
        this.analytics.logEvent('shop_error', {userid: this.auth.User.id});
      }
    })
    let transac:Transaction = {} as Transaction;
    transac.enterprise = this.campanha.enterprise;
    transac.cliente = this.campanha.cliente;
    // transac.geo = this.geo.location;
    transac.identificador = this.service.title;
    transac.status = Status['novo'];
    transac.tipo = Tipo['shop'];
    transac.usuario = this.auth.User.id;
    transac.authid = this.auth.User.authid;
    transac.valorout =  Math.ceil(this.privatedata.retido) + Math.ceil(this.service.tax || 0) + Math.ceil(this.privatedata.frete);
    this.privatedata.produtos = this.cartList.filter(item => !item.error);
    this.privatedata.celular = this.auth.User.celular;
    this.privatedata.documento = this.auth.User.documento;
    this.privatedata.email = this.auth.User.email;
    this.privatedata.nome = this.auth.User.nome;
    transac.privatedata = this.privatedata;

    if(transac.valorout <= this.auth.User.saldo ){
      this.confirm.openConfirm(transac);
    } else{
      //abre modal complemento de pagamento
    }
    this.resetTransaction();
  }
  }


