import { Component, OnInit } from '@angular/core';
import { WebsiteService } from 'src/app/services/di/website.service';
import { Website } from 'src/app/services/interfaces/website';
import { DatabaseService, MaterializeService } from '@ollieestudio/fire-lib';
import { QueryFn } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.css']
})
export class BannersComponent implements OnInit {
  public website:Website;
  public bannerscrm:any[] = [];

  constructor(public ws:WebsiteService, public materialize:MaterializeService, public database:DatabaseService<any>) { }

  ngOnInit() {
    this.website = this.ws.website;
    this.getBanners();
  }

  getBanners() {
    let query: QueryFn = ref => ref.where('campanha', '==', this.website.campanha).where('tipo', '==', 'banner').where('ativo', '==', true);
    
    let subs = this.database.queryValues('CRM', query).subscribe(result => {
      this.bannerscrm = result;
      // subs.unsubscribe();
      this.materialize.initSlider('.slider', {height:'40vh' });
    })
  }

}
