import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Produto } from 'src/app/services/interfaces/produto';
import { MaterializeService, AuthService } from '@ollieestudio/fire-lib';
import { WebsiteService } from 'src/app/services/di/website.service';
import { Campanha } from 'src/app/services/interfaces/campanha';
import { HttpClient } from '@angular/common/http';
import { Premiado } from 'src/app/services/interfaces/premiado';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-detail-shop',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DetailShopComponent implements OnInit {
  @Output() productEmitter: EventEmitter<Produto> = new EventEmitter();
  @Input() produto:any = {};
  campanha:Campanha;
  displayvalor: number = 0;
  saldo:number = 0;
  valor:number = 0;
  descricao: any;
  fichatecnica: any[];
  ready: boolean;
  showjson: boolean;
  fieldlist:string[] = ['peso', 'cores', 'medidas', 'estoque']
  
  constructor(public material:MaterializeService, public ws:WebsiteService, public http:HttpClient, public auth:AuthService<Premiado>) {
    this.auth.stateObservable.subscribe(change => {
      if(this.auth.User) this.saldo = this.auth.User.saldo
    })
   }

  ngOnInit() {
    this.material.initModal('#modalprodutos', {dismissible:true});
    this.material.initTabs('.tabs');
    
    this.campanha = this.ws.campanha;
    if(this.auth.User) this.saldo = this.auth.User.saldo
  }

  checkSaldo():boolean{
    if(Number.isNaN(this.displayvalor)) return true;
    if(!this.produto.parceiro) return true;
    let total:number = this.produto.quantidade*this.displayvalor;
   return total > this.saldo;
  }

  async displayDetail(produto:any){
    this.produto = produto;
    this.showjson = false;
    if(this.produto.parceiro === 'MAGAZINE'){
      document.getElementById('tab1').innerHTML = "";
      const produto_atual = await this.getDetail(produto);
      this.produto.price = produto_atual.price;
      this.produto.pontos = Math.ceil(this.produto.price) * this.campanha.conversion;
      this.produto.codigo = this.produto.sku;
      this.produto.nome = this.produto.title;
      this.displayvalor = this.produto.pontos;
      this.produto.quantidade = 1;
      this.ready = true;
      document.getElementById('tab1').innerHTML = this.produto.description;
      this.getFichaTecnica();
      this.material.delay(3000)
      this.showjson = true;
      this.material.initCarousel('.carousel', {indicators:true});
    }else{
      this.produto = produto;
      this.displayvalor = this.produto.pontos;
      this.ready = true;
      this.showjson = true;
      this.produto.pontos = Math.ceil(this.produto.valor) * this.campanha.conversion;
      this.produto.quantidade = 1;
      
      document.getElementById('tab1').innerHTML = this.produto.descricao;
      this.fichatecnica = this.produto;
    }
    this.material.openModal('#modalprodutos');
  }
 
  private getFichaTecnica() {
    this.fichatecnica = [];
    try {
      let data = this.produto.factsheet?.filter(el => { return el.display_name === 'Ficha-Técnica'; })[0]?.elements;
      Object.keys(data).map(i => {
        let obj = {label:data?.[i].key_name, value:data?.[i].elements?.[0].value};
        this.fichatecnica.push(obj);
      });
    } catch (error) {
      
    }
  }

  async getDetail(produto){
    var url = environment.rooturl + "/buscaProdutos?id=" + produto.sku + '_' + produto.seller_id;
     return new Promise<any>((resolve, reject) => {
      this.http.get(url).subscribe(
          result => {
            resolve(result[0]);
          },
          error => {
            reject(error);
          }
        );
      }); 
  }

  changeVal(ivynt:Event){
    this.produto.quantidade = parseInt(ivynt.target['value']);
  }

  addItem(){
    this.productEmitter.emit(this.produto);
  }

  close(){
    this.material.closeModal('#modalprodutos'); 
    document.body.style.overflow = '';
  }

}
