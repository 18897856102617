import { Component, OnInit } from '@angular/core';
import { WebsiteService } from 'src/app/services/di/website.service';

@Component({
  selector: 'app-help-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {

  constructor(public ws:WebsiteService) { }

  ngOnInit() {
  }

}
