export enum Status {
    'novo'='NOVO',
    'erro'='ERRO',
    'sucesso'='CONCLUIDO',
    'ativo'='ATIVO',
    'pendente'='PENDENTE',
    'processando'='PROCESSANDO',
    'respondido'='RESPONDIDA',
    'update'='UPDATE',
    'cancelado'='CANCELADO',
    'enviado' = 'ENVIADO'
}
