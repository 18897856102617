import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { AuthService, MaterializeService } from '@ollieestudio/fire-lib';
import { Premiado } from 'src/app/services/interfaces/premiado';
import { WebsiteService } from 'src/app/services/di/website.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {
  hideBot:boolean = true;
  showRegulamento: boolean;
  videostart: boolean = false;
  
  constructor(public material:MaterializeService, public ws:WebsiteService, public analytics:AnalyticsService, public auth:AuthService<Premiado>) { }

  ngOnInit() {
  }

  showBot(){
    this.hideBot = false;
    this.analytics.logEvent('help_chatbot_show', {userid: this.auth.User.id});
  }
  
  hideBot_(){
    this.hideBot = true;
    this.analytics.logEvent('help_chatbot_hide', {userid: this.auth.User.id});
  }

  mostraRegulamento(){
    this.showRegulamento = true;
    this.material.initModal('#modalregulamento');
    this.material.openModal('#modalregulamento');
  }

  mostraVideo(){
    this.videostart = true;
  }
}
