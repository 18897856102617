import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { WebsiteService } from 'src/app/services/di/website.service';
import { CookieService } from '@ollieestudio/fire-lib';
import { Produto } from 'src/app/services/interfaces/produto';

@Component({
  selector: 'app-shop-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class ShopCartComponent implements OnInit {
  cartList: any[] = [];
  totalproduct:number;
  totalvalue:number;
  @Output() checkoutEmitter = new EventEmitter<any>();
  cookieCart: CookieService < any > = new CookieService();

  constructor(public scrollService:ScrollToService, public ws:WebsiteService) { }
  
  ngOnInit() {
    this.checkCookies();
  }

  checkCookies() {
    this.cookieCart.get(this.ws.hostname+'_cart');
    if(this.cookieCart?.cookie?.produtos?.length){
      this.cookieCart.cookie.produtos.forEach((produto) => {
        this.cartList.push(produto);
      })
      this.updateTotal();
    }
  }
  
  addProduct(addprod: any) {
    if(this.cartList.filter(element => element.codigo === addprod.codigo).length > 0){
      this.cartList.filter(element => element.codigo === addprod.codigo)[0]['quantidade']++;
    }else{
      this.cartList.push(addprod);
    }
    this.updateTotal();
    this.scrollService.scrollTo({target:"#top"});
    // this.gotoCheckout();
  }

  updateTotal() {
    this.totalvalue = 0;
    this.totalproduct = 0;
    this.cartList.forEach(element => {
      this.totalproduct += element.quantidade;
      this.totalvalue += element.pontos*element.quantidade;
    });
  }

  gotoCheckout(){
    this.checkoutEmitter.next(true);
  }
}
