// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  staging:false,
  firebaseAuth: {
    apiKey: "AIzaSyAOhYM4h4O0SjYI_GQZ7hzvepUALTFGgbM",
    authDomain: "smartpontos-develop.firebaseapp.com",
    databaseURL: "https://smartpontos-develop.firebaseio.com",
    projectId: "smartpontos-develop",
    storageBucket: "smartpontos-develop.appspot.com",
    messagingSenderId: "1007071776107",
    appId: "1:1007071776107:web:78f59134ad1aa2ed4ad02e",
    measurementId: "G-FZJLFL5NET"
  },
  //rooturl: 'http://localhost:5001/smartpontos-develop/us-central1'
   rooturl: 'https://us-central1-smartpontos-develop.cloudfunctions.net'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
