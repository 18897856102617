import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Service } from 'src/app/services/interfaces/service';
import { FormGroup } from '@angular/forms';
import { Card } from 'src/app/services/interfaces/card';
import { AddressformComponent } from '../../dados/addressform/addressform.component';
import { InputvalorComponent } from '../../shared/inputvalor/inputvalor.component';
import { InputBase, MaterializeService, FormService, MaskService, AuthService } from '@ollieestudio/fire-lib';
import { ConfirmService } from 'src/app/services/confirm.service';
import { WebsiteService } from 'src/app/services/di/website.service';
import { Premiado } from 'src/app/services/interfaces/premiado';
import { DatePipe } from '@angular/common';
import { Transaction } from 'src/app/services/interfaces/transaction';
import { Status } from 'src/app/services/enum/status.enum';
import { Tipo } from 'src/app/services/enum/tipo.enum';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-payment-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class PaymentFormComponent implements OnInit {

  @Input() showform:any;
  @Input() service:Service;
  public formPayment:FormGroup;
  @ViewChild(InputvalorComponent, { static: false }) valor:InputvalorComponent;
  erro_c:string = "";
  privatedata:any = {};
  vl_editable:boolean = false;
  alert:string = "";

  private fields:InputBase[] = [
    {key:"valor", required:true},
    {key:"barcode", required:true},
    {key:"codigo", required:true},
    {key:"beneficiado", required:true},
    {key:"doccedente", required:true},
    {key:"tipoconta", required:true},
    {key:"datavencto", required:true},
    {key:"datapagto", required:true}
  ]
  barcode: string;

  constructor(public analytics:AnalyticsService, public datepipe: DatePipe, public confirm:ConfirmService, public material:MaterializeService, public forms:FormService,  public ws:WebsiteService, public masks:MaskService, public auth:AuthService<Premiado>) {
    this.formPayment = this.forms.toFormGroup(this.fields)
   }

  ngOnInit() {
    this.confirm.resultEmitter.subscribe(result =>{
      if(result === true){
        this.material.toast(this.confirm.generateCheckMessage("Seu pagamento foi incluído com sucesso!"), 5000, 'green full');
        this.analytics.logEvent('payment_complete', {userid: this.auth.User.id});
        this.showform = null;
        this.resetTransaction();
      }else{
        this.material.toast(this.confirm.generateErrorMessage("Seu pagamento não foi incluído!"), 5000, 'red full');
        this.analytics.logEvent('payment_error', {userid: this.auth.User.id});
        this.resetTransaction();
      }
    })
  }

  processTransaction(){
    let transac:Transaction = {} as Transaction;
    let formData:any = this.formPayment.getRawValue();

    transac.enterprise = this.ws.website.enterprise;
    transac.cliente = this.ws.website.cliente;
    // transac.geo = this.geo.location;
    transac.identificador = this.service.title;
    transac.status = Status['novo'];
    transac.tipo = Tipo['payment'];
    transac.usuario = this.auth.User.id;
    transac.authid = this.auth.User.authid;
    transac.valorout = (this.valor.valor) + Math.ceil(this.service.tax);
    transac.privatedata = this.privatedata;
    transac.privatedata['beneficiado'] = formData.beneficiado;
    
    transac.privatedata['pjpf'] = this.auth.User.pjpf;
    transac.privatedata['documento'] = this.auth.User.documento.replace(/\D/g,'');
    transac.privatedata['nome'] = this.auth.User.nome;
    transac.privatedata['doccedente'] = formData.doccedente.replace(/\D/g,'');

    if( transac.privatedata['doccedente'].length === 14){
      transac.privatedata['pjpf_cedente'] = 'J';
    }else{
      transac.privatedata['pjpf_cedente'] = 'F';
    }

    this.confirm.openConfirm(transac);
  }

  resetTransaction(){
    this.formPayment.reset();
    this.privatedata = {};
    this.showform = null;
  }  

  getCameraResult(code){
    // let code = event.target.value;
    let barcode = "";
    
      if (code.length === 44) {
        var cb = code; 
        var cp1_c = cb.substr(0, 11);
        //var cp2_c = cb.substr(11, 1);
        var cp3_c = cb.substr(11, 11);
        // var cp4_c = cb.substr(23, 1);
        var cp5_c = cb.substr(22, 11);
        //var cp6_c = cb.substr(35, 1);
        var cp7_c = cb.substr(33, 11);
        //var cp8_c = cb.substr(47, 1);
        barcode = cp1_c + "0" + cp3_c + "0" + cp5_c + "0" + cp7_c + "0";
        this.checkBarcode(barcode);
      }
      
      if (code.length === 43) {
        var cb = code;
        var cp1_b = cb.substr(0, 5);
        var cp2_b = cb.substr(5, 5);
        var cp3_b = cb.substr(10, 5);
        var cp4_b = cb.substr(15, 6);
        var cp5_b = cb.substr(21, 5);
        var cp6_b = cb.substr(26, 6);
        var cp7_b = cb.substr(32, 1);
        var cp8_b = cb.substr(33, 14);
        barcode = cp1_b + "0" + cp3_b + "0" + cp5_b + "0" + cp7_b + "0";
        this.checkBarcode(barcode);
      }
    }
    
    getKeyUp(event){
      let code = event.target.value;
      if (code.length >= 47 && !this.privatedata.barcode) {
        this.privatedata.barcode = code;
      this.checkBarcode(code);
      }
      if(code.length == 0){
        this.resetTransaction();
      }
    }

    checkBarcode(barcode){
        var regEx = /[\W_]/g;
        var cb = barcode.match(/\d+/g).join('');
        var isvalid:boolean = false;

        this.privatedata.codigo = "";
        this.privatedata.doccedente = "";
        this.privatedata.codigo = cb;

        if (cb.length == 48) {
          this.privatedata.tipoconta = 'consumo';
          this.privatedata.doccedente = '000000000000';
          isvalid = this.calculaConta(cb);
        }

        if (cb.length == 47) {
          this.privatedata.tipoconta = 'boleto';
          isvalid = this.calculaBoleto(cb);
        }
        
        if(isvalid) this.formPayment.setValue(this.privatedata);
        
      };
      
      calculaConta = function(cb) {
        var cp1_c = cb.substr(0, 11);
        var cp2_c = cb.substr(11, 1);
        var cp3_c = cb.substr(12, 11);
        var cp4_c = cb.substr(23, 1);
        var cp5_c = cb.substr(24, 11);
        var cp6_c = cb.substr(35, 1);
        var cp7_c = cb.substr(36, 11);
        var cp8_c = cb.substr(47, 1);
        
         var tx = cp1_c.slice(4, 11) + cp3_c.slice(0, 4);
        var len = tx.length;
        var hoje = new Date();
        var val = 0;

        if (hoje.getHours() >= 16) {
          hoje.setDate(hoje.getDate() + 1);
        }
        if (hoje.getDay() == 6) {
          hoje.setDate(hoje.getDate() + 2);
        }
        if (hoje.getDay() == 0) {
          hoje.setDate(hoje.getDate() + 1);
        }

        this.privatedata.datapagto = this.datepipe.transform(hoje, 'dd/MM/yyyy');
        this.privatedata.datavencto = this.datepipe.transform(hoje, 'dd/MM/yyyy');

        if (len == 11) {
          var tx2 = "";
          tx2 = tx.slice(0, 9) + '.' + tx.slice(9, 11);
          val = parseFloat(tx2);
          this.privatedata.valor = val;

          var idConcess = parseInt(cp1_c.slice(1, 2));
          switch (idConcess) {
            case 1:
              this.privatedata.beneficiado = 'PREFEITURA';
              break;
            case 2:
              this.privatedata.beneficiado = 'SANEAMENTO';
              break;
            case 3:
              this.privatedata.beneficiado = 'ELETRICIDADE';
              break;
            case 4:
              this.privatedata.beneficiado = 'TELEFONIA';
              break;
          }
        }

        val = this.privatedata.valor;
        var dataPagto = this.privatedata.datapagto;
        var result:any = false;

        if (val > this.auth.User.saldo) {
          this.alert = 'saldo insuficiente';
        } else if (val < 1) {
          this.alertvv = 'valor nulo não permitido.';
        } else if (this.checkDV_MOD11(cp1_c + cp3_c + cp5_c + cp7_c) == false && this.checkDV_MOD10(cp1_c + cp3_c + cp5_c + cp7_c) == false) {
          this.alert = "dígito verificador não confere!";
        } else {
          this.alert = "";
          result = true;
          // this.privatedata.fatorvencto = cp8_c.slice(0, 4);
          // this.privatedata.valorcodbar =  cp8_c.slice(4, 14);
          // this.privatedata.dvcodbar = parseInt(cp7_c);
        }
        return result;
      };

      calculaBoleto = function(cb) {
        var result:any = false;
        var cp1_b = cb.substr(0, 5);
        var cp2_b = cb.substr(5, 5);
        var cp3_b = cb.substr(10, 5);
        var cp4_b = cb.substr(15, 6);
        var cp5_b = cb.substr(21, 5);
        var cp6_b = cb.substr(26, 6);
        var cp7_b = cb.substr(32, 1);
        var cp8_b = cb.substr(33, 14);

        var tx = cp8_b;
        var len = tx.length;
        var fator = parseInt(tx.slice(0, 4)) - 1000;
        var millisecondsPerDay = 1000 * 60 * 60 * 24;
        var hoje = new Date();
        var dataPagto;

        if (len < 14) {
          //					erro = "Campo incompleto, se necessário preencha com 0(zero)."
          this.privatedata.valor = '0.00';
        } else if (len == 14) {
          //preenche a data
          if (fator == -1000) {
            if (hoje.getHours() >= 16) {
              hoje.setDate(hoje.getDate() + 1);
            }
            if (hoje.getDay() == 6) {
              hoje.setDate(hoje.getDate() + 2);
            }
            if (hoje.getDay() == 0) {
              hoje.setDate(hoje.getDate() + 1);
            }

            this.privatedata.datapagto = this.datepipe.transform(hoje, 'dd/MM/yyyy');
            this.privatedata.datavencto = this.datepipe.transform(hoje, 'dd/MM/yyyy');

          } else {
            var dataBase = new Date(2000, 6, 3, 0, 0, 0, 0);
            dataPagto = new Date(dataBase.getTime() + (fator * millisecondsPerDay));
            this.privatedata.datapagto = this.datepipe.transform(hoje, 'dd/MM/yyyy');
            this.privatedata.datavencto =this.datepipe.transform(dataPagto, 'dd/MM/yyyy');
          }

          //preenche o valor
          var tx2 = "";
          tx = tx.slice(4, 14);
          tx = tx.slice(0, 8) + '.' + tx.slice(8, 10);
          var val = parseFloat(tx);
          if (val == 0) {
            this.privatedata.valor = 0.00;
            this.vl_editable = true;
          } else {
            this.privatedata.valor = val;
            this.vl_editable = false;
          }
        }

        var max = this.auth.User.saldo;
         dataPagto.setHours(16);

        if (dataPagto < hoje && fator !== 0 && this.checaFDS(dataPagto) == false) {
          this.alert = "pagamento vencido. Os pagamentos são aceitos até as 16:00h da data de vencimento. Caso o vencimento seja durante o fim de semana, será aceito até as 16:00h da segunda-feira seguinte.";
        } else if (val > max) {
          this.alert = 'saldo insuficiente';
        } else if (val < 1) {
          this.alert = 'valor nulo não permitido.';
        } else if (this.checkDV(cp1_b, cp2_b, cp3_b, cp4_b, cp5_b, cp6_b, cp7_b, cp8_b) == false) {
          this.alert = "dígito verificador não confere!";
        } else {
          this.privatedata.fatorvencto = cp8_b.slice(0, 4);
          this.privatedata.valorcodbar =  cp8_b.slice(4, 14);
          this.privatedata.dvcodbar = parseInt(cp7_b);
          result = true;
          this.alert = "";
        }

        this.privatedata.beneficiado = "";
        return result;
      };


      checkDV_MOD11(dig):boolean {
        var digDV = 0;
        var dv = 0;
        var cb = "";
        var numArr = [];
        var fatores = [];
        var soma = 0;
        var i = 0;
      
        //cb = dig.substr(0, 3) + dig.substr(4, 40);
        numArr = dig.split("");
        digDV = numArr[3];
        fatores = [2, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5, 6, 7, 8, 0, 9, 2, 3, 4];
      
        numArr = numArr.reverse();
      
        for (i = 0; i < numArr.length; i++) {
          if (i != 40) {
            soma = soma + (numArr[i] * fatores[i]);
          }
        }
      
        dv = soma % 11;
        dv = 11 - dv;
      
        if (dv == 0 || dv == 10 || dv == 11) {
          dv = 0;
        }

        return Boolean(dv == digDV);
      };
      
      
      checkDV_MOD10(dig):boolean {
        var digDV = 0;
        var dv = 0;
        var cb = "";
        var numArr = [];
        var fatores = [];
        var soma = 0;
        var i = 0;
        var tmp = 0;
      
        numArr = dig.split("");
        digDV = numArr[3];
        fatores = [2, 1, 2, 0, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2];
      
        for (i = 0; i < numArr.length; i++) {
          if (i != 3) {
            tmp = numArr[i] * fatores[i];
            if (tmp < 10) {
              soma += tmp;
            } else {
              soma += 1 + (tmp - 10);
            }
          }
        }
      
        dv = soma % 10;
        dv = 10 - dv;
      
        if (dv == 10 || dv == 11) {
          dv = 0;
        }
      
        return Boolean(dv == digDV);
      };
      
      checaFDS(venc):boolean {
        var result = false;
        var hoje = new Date();
        var diff = hoje.getTime() - venc.getTime();
      
        if (venc.getDay() == 6 && (hoje.getDay() == 1 || hoje.getDay() == 6 || hoje.getDay() == 0) && diff < 172800000) {
          result = true;
        }
      
        if (venc.getDay() == 0 && (hoje.getDay() == 1 || hoje.getDay() == 0) && diff < 86400000) {
          result = true;
        }
      
        return result;
      };
      
      checkDV(cp1_b, cp2_b, cp3_b, cp4_b, cp5_b, cp6_b, cp7_b, cp8_b):boolean {
        var digDV = 0;
        var dv = 0;
        var dig = "";
        var cb = "";
        var numArr = [];
        var fatores = [];
        var soma = 0;
        var i = 0;
      
        dig = cp1_b + cp2_b + cp3_b + cp4_b + cp5_b + cp6_b + cp7_b + cp8_b;
        cb = dig.slice(0, 4) + /* cp7_b + */ cp8_b + dig.slice(4, 9) + dig.slice(10, 20) + dig.slice(21, 31);
        digDV = parseInt(cp7_b);
        numArr = cb.split("");
        fatores = [2, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5];
      
        numArr = numArr.reverse();
      
        for (i = 0; i < numArr.length; i++) {
          soma = soma + (numArr[i] * fatores[i]);
        }
        dv = soma % 11;
        dv = 11 - dv;
        if (dv == 0 || dv == 10 || dv == 11) {
          dv = 1;
        }
      
        return Boolean(dv == digDV);
      };
    }

