import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import * as firebase from 'firebase/compat';
import 'firebase/functions'

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  constructor(public http:HttpClient) { }

  goCheckOut(products) {
    // const CheckOut = firebase.functions().httpsCallable('checkout');
    const url = "https://us-central1-smartpontos-develop.cloudfunctions.net/checkout?preference=" + JSON.stringify(products)
    return this.http.get(url);
  }
  
}