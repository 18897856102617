<div class="col s12 m4 input-field" [hidden]="address">
  <input id="cepbusca" type="text" [textMask]="{mask:maskCEP, guide:false}" class="validate white-text"
    (keyup)="cep.cepKeyUp($event)" />
  <label for="cepbusca" class="active">Informe o CEP</label>
</div>

<app-spinner class="col s2"  *ngIf="!address" ></app-spinner>

<form id="formAddress" name="formAddress" [formGroup]="formAddress" [hidden]="!address">
  <div class="row">
    <div class="col s12 input-field">
      <input id="responsavel" type="text" class="validate white-text" [formControlName]="'responsavel'" />
      <label for="responsavel" class="active">Responsável</label>
    </div>
  </div>
  <div class="row s12">
    <div class="col s12">
      <div class="col s10 input-field">
        <input id="ender" type="text" class="validate white-text" [formControlName]="'logradouro'" />
        <label for="ender" class="active">Endereço</label>
      </div>
      <div class="col s2 input-field">
        <input id="num" type="text" class="validate white-text" [formControlName]="'numero'"
          (keyup)="numeroKeyUp($event)" />
        <label for="num" class="active">Nº</label>
      </div>
    </div>

    <div class="col s12">
      <div class="col s6 input-field">
        <input id="complemento" type="text" class="validate white-text" [formControlName]="'complemento'" />
        <label for="complemento" class="active">complemento</label>
      </div>
      <div class="col s6 input-field">
        <input id="cep" type="text" [textMask]="{mask:masks.maskCEP, guide:false}" class="validate white-text"
          [formControlName]="'cep'" />
        <label for="cep" class="active">CEP</label>
      </div>
    </div>

    <div class="col s12">
      <div class="col s5 input-field">
        <input id="bairro" type="text" class="validate white-text" [formControlName]="'bairro'" />
        <label for="bairro" class="active">bairro</label>
      </div>
      <div class="col s5 input-field">
        <input id="cidade" type="text" class="validate white-text" [formControlName]="'localidade'" />
        <label for="cidade" class="active">cidade</label>
      </div>
      <div class="col s2 input-field">
        <input id="uf" type="text" class="validate white-text" [formControlName]="'uf'" />
        <label for="uf" class="active">uf</label>
      </div>
      <!-- <div class="col s5 input-field">
        <input id="pais"  type="text" class="validate white-text" [formControlName]="'pais'"/>
        <label for="pais" class="active">país</label>
      </div> -->
    </div>
  </div>
</form>