<div id="cart" class="row" *ngIf="cartList.length">
  <div class="col m8 s0"></div>
  <div class="col s3 padding  m1">
    <i class="material-icons white-text">local_offer</i><span class="white-text">{{totalproduct}}</span> 
  </div>
  <div class="col s4  padding m1">
    <i class="material-icons white-text">attach_money</i><span class="white-text"> {{totalvalue}}</span>
  </div>
  <div class="col s4  m1">
    <div class="btn left"  [style.backgroundColor]="ws.website?.color1" (click)="gotoCheckout()">
      <i class="material-icons">
        shopping_cart
      </i>
    </div>
  </div>
</div>