
<style>

    @-moz-keyframes blink {0%{opacity:1;} 50%{opacity:0;} 100%{opacity:1;}} /* Firefox */
    @-webkit-keyframes blink {0%{opacity:1;} 50%{opacity:0;} 100%{opacity:1;}} /* Webkit */
    @-ms-keyframes blink {0%{opacity:1;} 50%{opacity:0;} 100%{opacity:1;}} /* IE */
    @keyframes blink {0%{opacity:1;} 50%{opacity:0;} 100%{opacity:1;}} /* Opera and prob css3 final iteration */

    #preloader {
        background: #fff;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 999999;
        opacity: 1;
        filter: alpha(opacity=100);
        -webkit-transition: opacity 500ms ease;
        transition: opacity 500ms ease;
        display:none;
    }

    #preloader .logo {
        display: block;
        width: 109px;
        height: 39px;
        background-repeat: no-repeat;
        background-image: url('https://console.dialogflow.com/api-client/assets/img/logo@2x-black.png');
        background-size: contain;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -20px 0 0 -55px;
        -moz-transition:all 1s ease-in-out;
        -webkit-transition:all 1s ease-in-out;
        -o-transition:all 1s ease-in-out;
        -ms-transition:all 1s ease-in-out;
        transition:all 1s ease-in-out;
        /* order: name, direction, duration, iteration-count, timing-function */
        -moz-animation:blink normal 2s infinite ease-in-out; /* Firefox */
        -webkit-animation:blink normal 2s infinite ease-in-out; /* Webkit */
        -ms-animation:blink normal 2s infinite ease-in-out; /* IE */
        animation:blink normal 2s infinite ease-in-out; /* Opera and prob css3 final iteration */
    }

    noscript h1 {
        padding: 20px;
    }
</style>
<!--[if lte IE 7]>
<script src="https://assets.dialogflow.com/prod/2755_20180530150204/js/agentDemoApp/promise.min.js"></script>
<![endif]-->

<div id="preloader">
<noscript>
    <h1>This application does'not work without javascript</h1>
</noscript>
<div class="logo"></div>
</div>

<div class="b-agent-demo">
  <div class="b-agent-demo_header">
    <div class="b-agent-demo_header-icon">
      <div class="b-agent-demo_header-icon-align-helper">
        <img id="agent-avatar" src="https://storage.googleapis.com/cloudprod-apiai/d6fffb2a-78f8-42bc-a6d9-f4cb2d0519db_s.png" srcset="https://storage.googleapis.com/cloudprod-apiai/d6fffb2a-78f8-42bc-a6d9-f4cb2d0519db_s.png 2x, https://storage.googleapis.com/cloudprod-apiai/d6fffb2a-78f8-42bc-a6d9-f4cb2d0519db_xs.png 1x" />
      </div>
    </div>
    <div class="b-agent-demo_header-wrapper">
      <div class="b-agent-demo_header-agent-name left">Agente de Helpdesk</div>
        <i (click)="close();" class="grey-text material-icons right link">close</i>
    </div>
  </div>
  <div class="b-agent-demo_result" id="resultWrapper">
    <table class="b-agent-demo_result-table"><tr><td  id="result"></td></tr></table>
</div>
<div class="clearfix"></div>
<div class="b-agent-demo_input">
  <input type="hidden" id="welcomevars" name="welcomevars" value="{{welcomeVars}}" />
    <form id="agentDemoForm">
        <input type="text" name="q" id="query" placeholder="Diga alguma coisa..." />
        <i class="b-agent-demo_input-microphone material-icons-extended" id="mic">mic</i>

        <!--div class="b-agent-demo_input-microphone material-icons-extended mic-black" id="mic"></div-->
    </form>
</div>
</div>

<noscript id="deferred-styles">
<link href='https://fonts.googleapis.com/css?family=Roboto:400,300&subset=latin,cyrillic' rel='stylesheet' type='text/css'>
<link href="https://fonts.googleapis.com/icon?family=Material+Icons+Extended" rel="stylesheet">
<link href='https://cdn.jsdelivr.net/fontawesome/4.6.3/css/font-awesome.min.css' rel='stylesheet' type='text/css'>
</noscript>
<script>
AGENT_LANGUAGE = "pt-br";
AGENT_AVATAR_ID = "d6fffb2a-78f8-42bc-a6d9-f4cb2d0519db_x.png";
SERVICE_BASE_URL = "https://console.dialogflow.com/api-client/";
ACCESS_TOKEN = "b951c0e8c55b44e8a313b5e18853d198";
// non-blocking CSS delivery

var loadDeferredStyles = function() {
    var addStylesNode = document.getElementById("deferred-styles");
    var replacement = document.createElement("div");
    replacement.innerHTML = addStylesNode.textContent;
    document.body.appendChild(replacement)
    addStylesNode.parentElement.removeChild(addStylesNode);
};

var raf = window.requestAnimationFrame || window.mozRequestAnimationFrame ||
        window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;

if (raf) {
    raf(function() { window.setTimeout(loadDeferredStyles, 0); });
} else {
    window.addEventListener('load', loadDeferredStyles);
}
</script>

