import { Component, Input, OnInit } from '@angular/core';
import { InputBase, FormService, AuthService, DatabaseService, Errors, MaterializeService, CookieService } from '@ollieestudio/fire-lib';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Premiado } from 'src/app/services/interfaces/premiado';
import { WebsiteService } from 'src/app/services/di/website.service';
import { Router } from '@angular/router';
import { QueryFn } from '@angular/fire/compat/firestore';
import { Datatype } from 'src/app/services/enum/datatype.enum';
import { Status } from 'src/app/services/enum/status.enum';
import { Subject, Subscription } from 'rxjs';
import { Tipo } from 'src/app/services/enum/tipo.enum';
import { Permissao } from 'src/app/services/enum/permissao.enum';
import { environment } from 'src/environments/environment';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { PasswordPolicy } from 'src/app/services/interfaces/password-policy';
import { HttpClient } from '@angular/common/http';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  @Input()  captchaResolved: boolean;
  public formSignup:FormGroup;
  public maskCPF = [/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/];
  public maskCNPJ = [/[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/];
  public maskCelular = [/[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/,  ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public mypass:string;
  policies: PasswordPolicy[];
  passwordValueSub = new Subject<string>();
  isfocused = false;
  public showPassword:boolean = false;
  public showspinner:boolean = false;
  public tempUser:any;
  public verificationCode:string;
  
  private fields:InputBase[] = [
    {key:"email", required:true},
    {key:"password", required:true},
    {key:"nome", required:true},
    {key:"apelido", required:true},
    {key:"celular", required:true},
    {key:"pjpf", required:true},
    {key:"documento", required:true},
    {key:"password2", required:true},
    {key:"codigointerno", required:false}
  ]

  passwordweak: boolean;
    tempdocument: any;
    codeVerified: boolean;
    tempId: string;


  constructor(public cookies:CookieService<any>,public scrollService: ScrollToService, public http:HttpClient, public analytics:AnalyticsService, public material:MaterializeService, public router:Router, public formserv:FormService, public auth:AuthService<Premiado>, public ws:WebsiteService, public database:DatabaseService<Premiado>) {
      this.formSignup = this.formserv.toFormGroup(this.fields);
      
  this.policies = [
    { label: 'Pelo menos 8 caracteres.', lengthValidator: 8 },
    {
      label: 'Pelo menos 1 número.',
      regexValidator: /[0-9]/g,
      lengthValidator: 1,
    },
    {
      label: 'Pelo menos 1 letra minúscula.',
      regexValidator: /[a-z]/g,
      lengthValidator: 1,
    },
    {
      label: 'Pelo menos 1 letra maiúscula.',
      regexValidator: /[A-Z]/g,
      lengthValidator: 1,
    },
    {
      label: 'Pelo menos 1 símbolo (@ # $ % & *).',
      regexValidator: /[!@#$%&*]/g,
      lengthValidator: 1,
    },
  ];

   }

  ngOnInit() {
   
  }

  getTempUser(event){
    let doc = event.currentTarget.value.toString().match(/\d+/g).join('');
    let tipo = this.formSignup.controls['pjpf'].value;
    if(!this.ws.campanha.valid_doc){
      if((doc.length == 11 && tipo == 'Pessoa Física') || (doc.length == 14 && tipo == 'Pessoa Jurídica')){
      this.checkDocumento(doc);
    }
      return
    }
    
    if((doc.length == 11 && tipo == 'Pessoa Física') || (doc.length == 14 && tipo == 'Pessoa Jurídica')){
      if(this.codeVerified){
        this.tempdocument = doc;
        return
      }
      this.showspinner = true;
     //chama function buscaCPF
     var url = environment.rooturl + "/validaDoc?q=" + doc + "&t=" + this.ws.website.cliente;
    this.http.get(url).subscribe(result =>{
     let data:any = result;
     this.tempdocument = doc;
     this.tempUser = {id:data.d, email:data.g, celular:data.k, status:data.l, nome:data.j};
     this.showspinner = false;
    //  this.formSignup.controls['nome'].setValue(this.tempUser.nome);
    }, (error) =>{
        this.material.toast(`Seu pré-cadastro não foi localizado, entre em contato com a empresa solicitante.`, 5000, 'red full');
       });
    // 
     //se receber dados mostrar form (codigo enviado)
     // 
    }
  }
  
  checkDocumento(doc: any) {
    let query:QueryFn;
    query = ref => ref.where('cliente', '==', this.ws.website.cliente).where('documento', '==', doc).where("status", "in",  ['NOVO', 'ATIVO', 'BLOQUEADO']).limit(1); 
  
    let subs:Subscription = this.database.queryValues('PREMIADOS', query).subscribe(premiados =>{
      subs.unsubscribe();
      if(premiados.length){
        this.material.toast(`Já existe uma conta para essa campanha com esse documento.`, 5000, 'red full');

      }else{
        this.material.toast(`Por favor preencha os campos abaixo para concluir seu cadastro.`, 5000, 'blue full');
        this.codeVerified = true;
      }
    })
  }

  checkCodigoInterno(codigo: any) {
    let query:QueryFn;
    query = ref => ref.where('cliente', '==', this.ws.website.cliente).where('codigointerno', '==', codigo).limit(1); 
  
    let subs:Subscription = this.database.queryValues('PREMIADOS', query).subscribe(premiados =>{
      subs.unsubscribe();
      if(premiados.length){
        let premiado = premiados[0];
        if(premiado.status ===  'ATIVO'){
          this.material.toast(`Já existe uma conta para essa campanha com esse ${this.ws.campanha.customfield}.`, 5000, 'red full');
        }else{
          this.tempId = premiado.id;
          this.material.toast(`${this.ws.campanha.customfield} verificado.`, 5000, 'blue full');
          this.codeVerified = true;
        }
        return;
      }else{
        this.material.toast(`Por favor preencha os campos abaixo para concluir seu cadastro.`, 5000, 'blue full');
        this.codeVerified = true;
        return;
      }
    })
  }

  checkVerificationCode(){
    this.showspinner = true;
    let url = environment.rooturl + "/updateConfirm?id=" + this.tempdocument + "&code=" + this.verificationCode;
    this.http.get(url).subscribe(result =>{
        this.showspinner = false;
        this.codeVerified = true;
        this.tempId = result['snap'];
    },(error) =>{
        this.showspinner = false;
        this.material.toast(`Hove um erro em sua solicitação!`, 5000, 'red full');
     })
  }

  newPasswordValue(password: string) {
    this.passwordValueSub.next(password);
  }

  passwordStrengthValidator(control: AbstractControl): { number: boolean; upper: boolean; lower: boolean } | null {
    let validators = { number: false, upper: false, lower: false };
    if (!control.value.match(/[0-9]/g)) validators.number = true;
    if (!control.value.match(/[a-z]/g)) validators.lower = true;
    if (!control.value.match(/[A-Z]/g)) validators.upper = true;
    if (validators.number || validators.lower || validators.upper)
      return validators;
    else return null;
  }
  
  async signup(){
    let data = this.formSignup.getRawValue();
    data.email = data.email.toLowerCase();
    if(data.documento && !this.tempdocument) this.tempdocument = data.documento.toString().match(/\d+/g).join('');
    
    if(data.codigointerno) await this.checkCodigoInterno(data.codigointerno);

    this.auth.signUpWithEmail(data.email, data.password).then(result =>{
      delete data.password;
      delete data.password2;

      this.auth.User = {...data} as Premiado;
        this.auth.User.authid = result.user.uid;
        this.auth.User.enterprise = this.ws.website.enterprise;
        this.auth.User.cliente = this.ws.website.cliente;
        this.auth.User.documento = this.tempdocument;
        this.auth.User.status = Status['ativo'];
        this.auth.User.permissao = Permissao['premiado'];
        this.auth.User.isDemo =  this.ws.checkDemo();
        this.auth.User.nomecampanha = this.ws.campanha.identificador;
        this.auth.User.campanha = this.ws.campanha.id;
        this.auth.User.dominio = this.ws.campanha.url;
        this.auth.User.nomecliente = this.ws.campanha.nome_cliente;
        this.auth.User.id = this.tempId || (result.user.uid+this.ws.website.cliente);
        this.checkCookies();
        this.tempId? this.updateUser() : this.createUser();
      this.analytics.logEvent('sign_up', {authid: result.user.uid });
    }).catch(error => {
      if(error.code === 'auth/email-already-in-use'){
        if(this.auth.firebaseUser){
          delete data.password;
          delete data.password2;
          this.auth.User = {...data} as Premiado;
            this.auth.User.authid = this.auth.firebaseUser.uid;
            this.auth.User.enterprise = this.ws.website.enterprise;
            this.auth.User.cliente = this.ws.website.cliente;
            this.auth.User.documento = this.tempdocument;
            this.auth.User.status = Status['ativo'];
            this.auth.User.permissao = Permissao['premiado'];
            this.auth.User.isDemo =  this.ws.checkDemo();
            this.auth.User.nomecampanha = this.ws.campanha.identificador;
            this.auth.User.campanha = this.ws.campanha.id;
            this.auth.User.dominio = this.ws.campanha.url;
            this.auth.User.nomecliente = this.ws.campanha.nome_cliente;
            this.auth.User.id = this.tempId || (this.auth.firebaseUser.uid+this.ws.website.cliente);
            this.checkCookies();
            this.tempId? this.updateUser() : this.createUser();
        }else{
          this.material.toast(`O e-mail ${data.email} já possui um acesso E+ Wallet. Primeiro faça login e depois se cadastre.`, 20000, 'blue')
          this.scrollService.scrollTo({target:"#start"});
        }
      }
    })
  }
  createUser() {
    this.database.set(this.auth.User, 'PREMIADOS').then(result =>{
      this.material.toast(`Parabéns  ${this.auth.User.nome }, você se cadastrou com sucesso!`, 5000, 'green full');
      this.router.navigateByUrl('/home');
  }).catch( error => {
      this.material.toast(`Seu cadastro não foi concluído, tente novamente ou entre em contato com a empresa solicitante.`, 5000, 'red full');
      })
  }

  updateUser(){
     this.database.update(this.auth.User, 'PREMIADOS').then(result =>{
        this.material.toast(`Parabéns  ${this.auth.User.nome }, você se cadastrou com sucesso!`, 5000, 'green full');
        this.router.navigateByUrl('/home');
    }).catch( error => {
        this.material.toast(`Seu cadastro não foi concluído, tente novamente ou entre em contato com a empresa solicitante.`, 5000, 'red full');
        })
  }

  checkCookies() {
    let cookie = this.cookies.get('prospectinfo');
    if(cookie){
    this.auth.User.prospectid = cookie.prospectid;
    this.auth.User.messageid = cookie.messageid;
    this.analytics.setUserProperty({prospectid:cookie.prospectid, messageid:cookie.messageid});
    this.analytics.logEvent('prospect_register',{prospectid:cookie.prospectid, messageid:cookie.messageid});
    }
  }

  passwordMismatch():boolean {
    let result = this.formSignup.get('password2').dirty && this.formSignup.get('password').dirty && this.formSignup.get('password').value != this.formSignup.get('password2').value;
    return result;  
  }

  onPasswordChanged(event):void{
    this.passwordweak = event < 2;
  }

  checkUser() {
    let queryVerify:QueryFn;
    queryVerify = ref => ref.where('obj', '==', this.auth.User.documento).where('code', '==', this.auth.User.code).limit(1);
    let subVerify:Subscription = this.database.queryValues('VERIFY', queryVerify).subscribe(verifyResult =>{
        subVerify.unsubscribe();
        if(verifyResult.length){
            let query:QueryFn;
            if(this.ws.campanha.valid_email){
            query = ref => ref.where('cliente', '==', this.ws.website.cliente).where('email', '==', this.auth.User.email).where('documento', '==', this.auth.User.documento).where("status", "==",  'NOVO').limit(1); 
            }else{
            query = ref => ref.where('cliente', '==', this.ws.website.cliente).where('documento', '==', this.auth.User.documento).where("status", "==",  'NOVO').limit(1); 
            }
            let subs:Subscription = this.database.queryValues('PREMIADOS', query).subscribe(premiados =>{
                subs.unsubscribe();
                if(premiados.length){
                let item = premiados[0];
                    let userFromDb:Premiado = item as unknown as Premiado;
                        this.auth.User = {...userFromDb, ...this.auth.User} as Premiado;
                        this.database.update(this.auth.User,'PREMIADOS').then(result =>{
                            this.material.toast(`Parabéns  ${this.auth.User.nome }, você se cadastrou com sucesso!`, 5000, 'green full');
                            this.router.navigateByUrl('/home');
                        }).catch( error => {
                            this.material.toast(`Seu cadastro não foi concluído, tente novamente ou entre em contato com a empresa solicitante.`, 5000, 'red full');
                            })
                }else{
                        if(this.ws.campanha.valid_doc == true){
                        this.material.toast(`Seu pré-cadastro não foi localizado, entre em contato com a empresa solicitante.`, 5000, 'red full');
                        this.tempUser = undefined;
                        this.auth.User = undefined;
                        this.formSignup.reset();
                        }else{
                        this.auth.User.id = this.auth.User.authid;
                        this.database.set(this.auth.User,'PREMIADOS').then(result =>{
                            this.material.toast(`Parabéns  ${this.auth.User.nome }, você se cadastrou com sucesso!`, 5000, 'green full');
                            this.ws.getEnterprise();
                        this.router.navigateByUrl('/home');
                            }).catch( error =>{})
                        }
                    }
                })
            }
        })
  }

}
