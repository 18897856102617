import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService, DatabaseService, Errors, FormService, InputBase, MaterializeService, CookieService } from '@ollieestudio/fire-lib';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { Premiado } from 'src/app/services/interfaces/premiado';
import { Logininfo } from 'src/app/services/interfaces/logininfo';
import * as firebase from 'firebase/compat/app';
import { Datatype } from 'src/app/services/enum/datatype.enum';
import { WebsiteService } from 'src/app/services/di/website.service';
import { ConfirmService } from 'src/app/services/confirm.service';
import { QueryFn } from '@angular/fire/compat/firestore';
import { Subscription } from 'rxjs';
import { AnalyticsService } from 'src/app/services/analytics.service';

var grecaptcha: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @Output() public captchaEmitter:EventEmitter<boolean> = new EventEmitter();
  public formLogin: FormGroup;
  public logininfo:Logininfo = {}  as Logininfo;
  public showPassword:boolean = false;

  private fields: InputBase[] = [{
      key: "username",
      required: true
    },
    {
      key: "password",
      required: true
    },
  ]
  public captchaResolved:boolean = false;


  constructor(public analytics:AnalyticsService, public confirm:ConfirmService, public ws:WebsiteService, public cookies:CookieService<Logininfo>, public material:MaterializeService, public scrollService: ScrollToService, public forms: FormService, public auth: AuthService<Premiado>, public database: DatabaseService < any > , public router: Router) {
    this.formLogin = this.forms.toFormGroup(this.fields);
  }

  ngOnInit() {
    this.auth.stateGuard();
    this.logininfo.cookiename = 'smartuserinfo';
    // this.checkCookies();
    this.captcha();
    
   }

   captcha(){
    grecaptcha = new firebase.default.auth.RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        this.captchaResolved = true;
        this.captchaEmitter.next(this.captchaResolved);
      },
      'expired-callback': () => {
        // Response expired. Ask user to solve reCAPTCHA again.
        this.captchaResolved = false;
        this.captchaEmitter.next(this.captchaResolved)
        
       grecaptcha.verify();

      }
    });
    grecaptcha.render().then(()=> grecaptcha.verify());
   }

  checkCookies() {
    let cookie = this.cookies.get(this.logininfo.cookiename);
    if(cookie && cookie.keepconn && cookie.token && this.auth.isLoggedIn()){
     this.getUserData(cookie.id)
    }
  }

  login() {
    let tmp = this.formLogin.getRawValue();
    this.auth.afAuth.setPersistence(firebase.default.auth.Auth.Persistence.SESSION)
      .then(() => {
        this.auth.loginWithEmail(tmp.username, tmp.password).then(result => {
          this.auth.firebaseUser = result.user;
          this.setCookies(result);
          this.getUserData(result.user.uid);
          this.analytics.logEvent('login', {authid: result.user.uid });
        }).catch(error => {
          // console.error(error);
          if (error.code == "auth/user-not-found") {
             this.material.toast(this.confirm.generateErrorMessage("O e-mail informado não tem uma conta cadastrada."), 3000, 'red full overcomb');
            this.scrollService.scrollTo({target:"#register"});
          } else {
            if (error.code == "auth/wrong-password") {
              this.material.toast(this.confirm.generateErrorMessage("Senha incorreta."), 3000, 'red full overcomb ');
            } else {
              this.material.toast(Errors[error.code], 3000, 'red full overcomb ');
            }
          }
        })
      })
  }

  getUserData(uid: string) {
    let query:QueryFn = ref => ref.where('authid', '==', uid).where('cliente', '==', this.ws.website.cliente).where('status', 'in', ['NOVO', 'ATIVO']).limit(1);
    let subs:Subscription = this.database.queryValues('PREMIADOS', query).subscribe(result =>{
      subs.unsubscribe();
      if (result.length > 0) {
        this.auth.User = result[0] as Premiado;
        this.logininfo.status = this.auth.User.status;
        this.logininfo.permissao = this.auth.User.permissao;
        this.logininfo.FCMtoken = this.auth.User.FCMtoken;
        this.material.toast(this.confirm.generateHelloMessage(`Olá ${this.auth.User.apelido }!`), 3000, 'green full');

        this.analytics.setUserId(this.auth.User.id);

        this.analytics.setUserProperty({
          campanha: this.ws.campanha.identificador, 
          dominio:this.ws.campanha.url, 
          cliente:this.ws.campanha.nome_cliente,
          isDemo:this.auth.User.isDemo === true,
          pjpf:this.auth.User.pjpf,
          permissao:this.auth.User.permissao,
          authid:this.auth.User.authid,
          status:this.auth.User.status,
          versao: '2.0.5'
        })

        this.ws.getEnterprise();
        this.router.navigateByUrl('/home');
      }else {
        this.material.toast(this.confirm.generateAlertMessage("Por favor complete seu cadastro e ative sua conta."), 15000, 'blue full overcomb');
        this.scrollService.scrollTo({target:"#register"});
      }
    }, error =>{
      this.auth.logOut();
      this.router.navigateByUrl('/institucional');
    })
  }

  async setCookies(authresponse:firebase.default.auth.UserCredential) {
    
    const idtoken = await authresponse.user.getIdTokenResult(true);
    this.logininfo.lastlogin = new Date().getTime();
    this.logininfo.login = authresponse.user.email;
    this.logininfo.token = authresponse.user.refreshToken;
    this.logininfo.id = authresponse.user.uid;
    this.logininfo.keepconn = true;
    this.logininfo.idtoken = idtoken.token;

    this.cookies.set(this.logininfo, this.logininfo.cookiename);
  }

}