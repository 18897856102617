<div id="formQuantidadePontos" class="editcontainer col s12 m9">
  <h5 [style.font-family]="ws.website.font.family" class="white-text">SELECIONE A QUANTIDADE DE PONTOS</h5>
  <app-inputvalor id="valorinput" #valorinput [floatingtax]="false" [isemissao]="false" [editable]="true" (model)="valor"
    [service]="service"> </app-inputvalor>
</div>

<div class="col s12 m9">

  <button *ngIf="!transac" class="waves-effect waves-light btn col m3 s12 right" [style.backgroundColor]="ws.website?.color1"
  id="bt-cp-card" (click)="processTransaction()" [disabled]="!valorinput.taxmodel || 50 > valorinput.valor "> prosseguir</button>
  
  
  <a class="btn-flat col m3 s12 right" id="bt-cancel-cardtransf" (click)="resetTransaction()">cancelar</a>
</div>


<app-confirmpanel source="transaction" visible="showConfirmForm"></app-confirmpanel>

