import { Component, OnInit } from '@angular/core';
import { WebsiteService } from 'src/app/services/di/website.service';

@Component({
  selector: 'app-bank-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class BankFormComponent implements OnInit {
  contaselecionada:boolean;
  
  constructor( public ws:WebsiteService) { }

  ngOnInit() {
  }

}
