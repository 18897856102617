<div id="topbank" class="toppad">
  &nbsp;
</div>
<div class="container top-5">
  <div class="row" *ngIf="service">
    
    <h3 [style.font-family]="ws.website.font.family"  class="col s12 m11 white-text upper"><i
        class="material-icons">{{service.icon}}</i>&nbsp;{{service.title}}
    </h3>
  </div>
  <div class="row">
    
    <div class="col s12 m11 instrucoes">
      <h5 [style.font-family]="ws.website.font.family"  class="white-text">INSTRUÇÕES</h5>
      <ul class="browser-default">
        <li><span class="white-text">São aceitas transferências/PIX para todos os bancos. </span></li>
        <li><span class="white-text">São aceitas transferências/PIX apenas para o TITULAR da conta {{ws.campanha.identificador}}. </span></li>
        <li><span class="white-text">Prazo para efetivação da transferência/PIX: De 2 minutos a 1 dia útil. </span></li>
        <li><span class="white-text">A Taxa de Serviço será descontada do valor total enviado. </span></li>
        <li><span class="white-text">Limite por solicitação: {{service.limite*ws.campanha.conversion}} {{ws.campanha.moeda}}</span></li>
        <!-- <li *ngIf="service"><span class="white-text bold">Taxa de Serviço: {{service.tax*ws.campanha.conversion}} {{ws.campanha.moeda}}  </span></li> -->
      </ul>
    </div>
  </div>
  <div class="row">

    <app-bank-favorites #digital [serviceType]="'digital_transf'" [hidden]="!showForm"></app-bank-favorites>
    <app-bank-favorites #bank [serviceType]="'online_transf'" [hidden]="!showForm"></app-bank-favorites>
    <app-bank-favorites #pix [serviceType]="'online_pix'" [hidden]="!showForm"></app-bank-favorites>
    
    <div class="row">
      <a  class="waves-effect waves-light btn col m4 s12 customcolor1 center"  [style.backgroundColor]="ws.website?.color1"
      (click)="enableAccountForm()" [ngxScrollTo]="'#accountselect'"  *ngIf="showForm && !favoritesDigital.selectedAccount && !favoritesBank.selectedAccount && !favoritesPix.selectedAccount">CADASTRAR NOVA CONTA</a>
    </div>
    
    <app-newaccount [hidden]="showForm" [service]="service" ></app-newaccount>
   

  </div>


</div>


<app-confirmpanel source="transaction" visible="showConfirmForm"></app-confirmpanel>