import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { InputBase, FormService, AuthService, DatabaseService, MaterializeService } from '@ollieestudio/fire-lib';
import { Card } from 'src/app/services/interfaces/card';
import { Transaction } from 'src/app/services/interfaces/transaction';
import { WebsiteService } from 'src/app/services/di/website.service';
import { Premiado } from 'src/app/services/interfaces/premiado';
import { InputvalorComponent } from '../../shared/inputvalor/inputvalor.component';
import { QueryFn } from '@angular/fire/compat/firestore';
import { Datatype } from 'src/app/services/enum/datatype.enum';
import { Tipo } from 'src/app/services/enum/tipo.enum';
import { Service } from 'src/app/services/interfaces/service';
import { ConfirmService } from 'src/app/services/confirm.service';
import { Status } from 'src/app/services/enum/status.enum';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-cards-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.css']
})
export class CardsFavoritesComponent implements OnInit {
  @ViewChild(InputvalorComponent, { static: false }) public valorinput:InputvalorComponent;
  @Input() service:Service;

  public formRecargaCartao:FormGroup;
  public selectedCard:Card;
  public valor:number = 0;

  servicetype: string =  Tipo['card-transf'];

  private fields:InputBase[] = [
    {key:"numcard", required:true},
    {key:"valor", required:true},
  ]

  public mycards: any[];
  showform: boolean;
 
  constructor(public analytics:AnalyticsService, private scrollService: ScrollToService, public confirm:ConfirmService, public material:MaterializeService, public database:DatabaseService<Card>, public forms:FormService, public auth:AuthService<Premiado>, public ws:WebsiteService) {
    this.formRecargaCartao = this.forms.toFormGroup(this.fields);
       
    let sub2 = this.ws.websiteEmitter.subscribe(website =>{
      sub2.unsubscribe();
      this.service = {...this.ws.campanha.services[this.servicetype], ...this.ws.services.filter(service => service.type === this.servicetype)[0]};
     })
  }
  
  ngOnInit(): void {
      if(this.auth.User && !this.mycards){
        this.getCards(this.auth.User.id)
      }
    }
    
    getCards(id){
      let query:QueryFn = ref => ref.where('usuario', '==', id).where('status', 'in', ['EMITIDO', 'ATIVO']); 
      let sub = this.database.queryValues('CARDS', query).subscribe(cards =>{
        this.showform = true;
        this.mycards = cards;
        window.scrollTo(0,0);
      })
    }
    
    selectCard(card){
      this.selectedCard = card;
      this.scrollService.scrollTo({target:'#cards-favorites'});
      this.confirm.resultEmitter.subscribe(result =>{
          if(result === true){
            this.material.toast(this.confirm.generateCheckMessage("Sua recarga foi solicitada com sucesso!"), 5000, 'green full');
            this.analytics.logEvent('cardtransfer_complete', {userid: this.auth.User.id});
          }else{
            this.material.toast(this.confirm.generateErrorMessage("Sua recarga não foi processada!"), 5000, 'red full');
            this.analytics.logEvent('cardtransfer_error', {userid: this.auth.User.id});
          }
          this.selectedCard = null;
        })
  }

  resetTransaction(){
    this.showform = true;
    this.selectedCard = null;
    this.scrollService.scrollTo({target:'#topcards'});
  }

  processTransaction(){
    let transac:Transaction = {} as Transaction;
    transac.enterprise = this.ws.website.enterprise;
    transac.cliente = this.ws.website.cliente;
    transac.campanha = this.ws.campanha.id;

    // transac.geo = this.geo.location;
    transac.identificador = this.service.title;
    transac.status = Status['novo'];
    transac.tipo = Tipo['card-transf'];
    transac.usuario = this.auth.User.id;
    transac.authid = this.auth.User.authid;
    transac.valorout = Math.ceil(this.valorinput.valor) + Math.ceil(this.service.tax);
    transac.privatedata = {};
    transac.privatedata['card'] = this.selectedCard;
    transac['valor'] = Math.ceil(this.valorinput.valor);
    this.confirm.openConfirm(transac);

  }

}
