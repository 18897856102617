import {
    FormGroup,
    AbstractControl
  } from '@angular/forms';
  
  export class Validation {
  
    static ValidaCep(controle: AbstractControl) {
      const cep = controle.value.replace('-', '');
      const regex = new RegExp('[0-9]{8}');
  
      if (regex.test(cep)) return null;
  
      return {
        cepInvalido: true
      };
    }
  
    static MatchPassword(group: AbstractControl) {
      if (!group.pristine) {
        let pass = group.parent.controls['password'].value;
        let confirmPass = group.value;
  
        return pass === confirmPass ? null : {
          notSame: true
        }
      }
    }
  
    static ValidaCpf(controle: AbstractControl) {
      const cpfval = controle.value.replace(/[-\/\\^$*+?.()/_/|[\]{}]/g, '');
      let cpf = cpfval.toString();
  
      let soma: number = 0;
      let resto: number;
      let valido: boolean;
  
      const regex = new RegExp('[0-9]{11}');
  
      if (
        cpf == '00000000000' ||
        cpf == '11111111111' ||
        cpf == '22222222222' ||
        cpf == '33333333333' ||
        cpf == '44444444444' ||
        cpf == '55555555555' ||
        cpf == '66666666666' ||
        cpf == '77777777777' ||
        cpf == '88888888888' ||
        cpf == '99999999999' ||
        !regex.test(cpf)
      )
        valido = false;
      else {
        let numero: number = 0;
        let caracter: string = '';
        let numeros: string = '0123456789';
        let j: number = 10;
        let somatorio: number = 0;
        let resto: number = 0;
        let digito1: number = 0;
        let digito2: number = 0;
        let cpfAux: string = '';
        
        cpfAux = cpf.substring(0, 9);
        for (let i: number = 0; i < 9; i++) {
          caracter = cpfAux.charAt(i);
          if (numeros.search(caracter) == -1) {
            return false;
          }
          numero = Number(caracter);
          somatorio = somatorio + (numero * j);
          j--;
        }
        resto = somatorio % 11;
        digito1 = 11 - resto;
        if (digito1 > 9) {
          digito1 = 0;
        }
        j = 11;
        somatorio = 0;
        cpfAux = cpfAux + digito1;
        for (let i: number = 0; i < 10; i++) {
          caracter = cpfAux.charAt(i);
          numero = Number(caracter);
          somatorio = somatorio + (numero * j);
          j--;
        }
        resto = somatorio % 11;
        digito2 = 11 - resto;
        if (digito2 > 9) {
          digito2 = 0;
        }
        cpfAux = cpfAux + digito2;
        if (cpf != cpfAux) {
          valido = false;
        } else {
          valido = true;
        }
      }
  
  
      if (valido) {
        return null;
      }
  
      return {
        cpfInvalido: true
      };
  
    }
  
    static validaCPF(s): boolean {
      var c = s.substr(0, 9);
      var dv = s.substr(9, 2);
      var d1 = 0;
      for (var i = 0; i < 9; i++) {
        d1 += c.charAt(i) * (10 - i);
      }
      if (d1 == 0) return false;
      d1 = 11 - (d1 % 11);
      if (d1 > 9) d1 = 0;
      if (dv.charAt(0) != d1) {
        return false;
      }
      d1 *= 2;
      for (var i = 0; i < 9; i++) {
        d1 += c.charAt(i) * (11 - i);
      }
      d1 = 11 - (d1 % 11);
      if (d1 > 9) d1 = 0;
      if (dv.charAt(1) != d1) {
        return false;
      }
      return true;
    }
  }
  