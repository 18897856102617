import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Endereco } from 'src/app/services/interfaces/endereco';
import { MaskService, CepService, InputBase, FormService } from '@ollieestudio/fire-lib';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-addressform',
  templateUrl: './addressform.component.html',
  styleUrls: ['./addressform.component.css']
})
export class AddressformComponent implements OnInit {
  @Input() address:Endereco;
  @Output() addressEmitter:EventEmitter<Endereco> = new EventEmitter();
  @Output() numEmitter:EventEmitter<any> = new EventEmitter();
  public maskCEP = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/,  /[0-9]/];
  
  private fields:InputBase[] = [
    {key:"responsavel", required:true},
    {key:"logradouro", required:true},
    {key:"numero", required:true},
    {key:"complemento", required:false},
    {key:"bairro", required:true},
    {key:"cep", required:true},
    {key:"localidade", required:true},
    {key:"uf", required:true},
    {key:"pais", required:false}
  ]

  public formAddress: FormGroup;


  constructor(public forms:FormService, public masks:MaskService, public cep:CepService) {
    this.formAddress = this.forms.toFormGroup(this.fields)
   }

  ngOnInit() {
    this.cep.cepEmitter.subscribe(endereco => {
      this.address = endereco as unknown as Endereco;
      this.formAddress.patchValue(endereco);
      this.addressEmitter.next(this.address);
    });
  }

  reset(){
    let input = document.getElementById('cepbusca') as HTMLElement;
    input['value'] = '';
    this.address = null;
    this.formAddress.reset();
  }

  numeroKeyUp(event){
    this.numEmitter.next(event.currentTarget.value);
  }
}
